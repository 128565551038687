/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import Square from '../../square/Square';
import { Color } from '../../../constant/Color';
import { SelectedType, SelectedTab } from '../data/dataPicture';
import { cropStartArr, cropEndArr } from '../data/dataVideo';

const headerModel = {
  headerVisible: false,
  selectedItem: {}
};

export function GridContent(props: any) {
  const { selectedTab, dataList, ContentHeader, headerControlBySelf, groupFlag, selects } = props;
  const [selectedItem, setSelectedItem] = useState(headerModel.selectedItem || {});
  const [headerVisible, setHeaderVisible] = useState(headerModel.headerVisible || false);
  const [clearImg, cancelSettings] = useState('img/tab/clear.png');
  let selectedGroup = dataList.find((item: any) => item.selected === true) || {};
  function handleGroupTitleClick(group: any) {
    return () => {
      if (group.selected === true) return;

      const newDataList = dataList.map((group: any) => ({ ...group, selected: false }));
      const targetGroup = newDataList.find((g: any) => g.title === group.title);
      targetGroup.selected = true;

      props.onGroupTitleClick && props.onGroupTitleClick(selectedTab, targetGroup);
      props.updateData && props.updateData(newDataList);
    };
  }

  function handleItemClick(item: any) {
    return () => {
      const newGroupDataList = selectedGroup.list.map((item: any) => ({ ...item, selected: false }));
      const targetItem = newGroupDataList.find((it: any) => (it.id || it.title) === (item.id || item.title));
      targetItem.selected = true;
      selectedGroup.list = newGroupDataList;

      headerControlBySelf && (headerModel.headerVisible = true);
      headerControlBySelf && setHeaderVisible(true);
      headerModel.selectedItem = targetItem;
      setSelectedItem(targetItem);
      props.onItemClick && props.onItemClick(targetItem, selectedTab);
      const newDataList = dataList.map((it: any) => it.title === selectedGroup.title ? selectedGroup : it);
      console.log(newDataList);
      props.updateData && props.updateData(newDataList);
    }
  }
  function handleConfirm() {
    props.onConfirm && props.onConfirm(selectedItem, selectedTab);

    headerControlBySelf && (headerModel.headerVisible = false);
    headerControlBySelf && setHeaderVisible(false);
  }
  function handleCancel() {
    props.onCancel && props.onCancel(selectedTab);
    headerModel.selectedItem = {};
    setSelectedItem({});
    headerControlBySelf && (headerModel.headerVisible = false);
    headerControlBySelf && setHeaderVisible(false);
  }

  // 视频点击动效多选方法
  function handleItemClicks(item: any) {
    return () => {
      const { targetItem } = transformDataLists(dataList, selectedGroup, item);
      (props.onItemClick) && props.onItemClick(targetItem, selectedTab);
      props.updateData && props.updateData(dataList);
      cancelSettings('img/tab/clear.png');
    };
  }
  /**
   * @param dataList 
   * @param selectedGroup 当前选中GroupList
   * @param selectedItem 当前选中特效item {title: "摄像机", image: "img/filter/group0/clarendon.jpeg", selected: true, value: "set_vcr(true);"}
   * @returns 返回当前设置select状态的GroupList
  */
  function transformDataLists(dataList: any[], selectedGroup: any, selectedItem: any) {
    // 当前选中的selectedItem selected设置成true
    selectedItem.selected = !selectedItem.selected;
    const targetItem = selectedItem;
    if (cropStartArr.includes(selectedItem.id)) {
      const newGroupDataList = selectedGroup.list.map((item: any) => {
        if(selectedItem.id !== item.id && cropStartArr.includes(item.id)) {
          item.selected = false;
        }
        return item;
      })
      selectedGroup.list = newGroupDataList;
    }
    if (cropEndArr.includes(selectedItem.id)) {
      const newGroupDataList = selectedGroup.list.map((item: any) => {
        if(selectedItem.id !== item.id && cropEndArr.includes(item.id)) {
          item.selected = false;
        }
        return item;
      })
      selectedGroup.list = newGroupDataList;
    }
    if(Number(selectedItem.id) > 199) {
      const newDataList = dataList.map((element: any) => {
       return element.list.map((item: any) => {
          if(selectedItem.id !== item.id && Number(item.id) > 199) {
            item.selected = false;
          }
          return item;
        })
      })
      dataList = newDataList;
    }
    console.log({ targetItem, dataList })
    return { targetItem, dataList };
  }

  function cancel() {
    if (clearImg === 'img/tab/clear-select.png') return;
    return () => {
      cancelSettings('img/tab/clear-select.png');
      const data = reset(dataList);
      (props.onItemClick) && props.onItemClick("", selectedTab);
      props.updateData && props.updateData(data);
    }
  }

  // 还原选中设置
  function reset(dataList: any) {
    return dataList.map((group: any) => {
      return {
        ...group,
        list: group.list.map((item: any) => {
          return {...item, selected: false};
        }),
      };
    })
  }

  return (
    <div style={styles.container}>
      {
        headerControlBySelf && headerVisible ?
          <ContentHeader
            selectedItem={selectedItem}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
          :
          <>
            {/* 分组 */}
            <div style={!groupFlag ? styles.titleGroup : styles.titleNone}>
              <div style={styles.restoreSettings} onClick={cancel()}>
                <img src={clearImg} alt="" style={styles.imageClear} />
              </div>
              <div style={styles.overFlow}>
                {dataList.map((data: any, index: number) => {
                  const combinedStyle = { ...styles.unSelectedTitle,  ...data.selected ? styles.title : {} };
                  const style = index === 0 ? { ...styles.titleContainer, ...styles.titleMargin, } : { ...styles.titleContainer, ...styles.titleMargin, };
                  return (
                    <div key={data.title} style={style}>
                      <div style={combinedStyle} onClick={handleGroupTitleClick(data)}>{data.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* 内容 */}
            <div style={styles.contentContainer}>
              {
                selectedGroup.list && selectedGroup.list.map((item: any) => {
                  // 选中效果: 添加边框
                  const combinedStyle = { ...styles.image, ...item.selected ? styles.selectedItem : {} };
                  return (
                    <Square
                      key={item.id || item.title}
                      image={item.image}
                      title={item.title}
                      style={styles.margin}
                      imageStyle={combinedStyle}
                      onClick={!selects ? handleItemClick(item) : handleItemClicks(item)}
                    />
                  );
                })
              }
            </div>
          </>
      }
    </div>
  );
}

const styles: any = {
  container: {
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    height: 115,
    overflow: 'auto',
    flexWrap: 'wrap',
    marginTop: 5,
    marginBottom: 10,
    paddingLeft: 20,
  },
  titleNone: {
    display: "none"
  },
  titleGroup: {
    display: 'flex',
    paddingTop: 5,
    minHeight: 27,
  },
  overFlow: {
    display: 'flex',
    overflow: 'auto',
    marginLeft: 15,
  },
  titleContainer: {
    display: 'flex',
    // marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
  },
  title: {
    color: Color.orange,
    fontSize: 15,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  unSelectedTitle: {
    color: Color.fontColor,
    fontSize: 15,
    whiteSpace: 'nowrap',
  },
  titleMargin: {
    marginRight: 10,
  },
  margin: {
    marginTop: 8,
  },
  selectedItem: {
    border: `3px solid ${Color.orange}`,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 6,
  },
  imageClear: {
    width: 16,
    height: 16,
  },
  restoreSettings: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 25,
  },
};

export default GridContent;
