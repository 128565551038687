/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';

export function Radio(props: any) {
  const style = props.style || styles.icon;
  const selected = props.selected || false;
  const icon = selected ? props.highlightIcon : props.icon;

  function handleClick() {
    props.onSelectedChange && props.onSelectedChange(props.tag);
  }
  return (
    <div style={styles.radioBox} onClick={handleClick}>
      <img src={icon} alt="" style={style} />
      <p style={styles.text}>{props.text}</p>
    </div>
  );
}

export function RadioShot(props: any) {
  function handleClick() {
    props.onClick && props.onClick()
  }
  return (
    <div>
      <img src={props.icon} alt="" style={styles.radioShot} onClick={handleClick} />
      <div style={styles.shotText}>重<br />拍</div>
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  radioBox: {
    marginBottom: 5,
    textAlign: 'center',
  },
  icon: {
    width: 38,
    height: 38,
    filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3))',
  },
  iconSmallplanet: {
    width: 38,
    height: 38,
  },
  text: {
    margin: 0,
    color: "#fff",
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: "center",
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
  },
  radioShot: {
    width: '100%',
    height: 39,
    marginBottom: 18,
  },
  shotText: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    height: 39,
    right: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
};

export default Radio;
