/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 小程序 "玩图" Footer
 */
import React from 'react';
import TabFooter from './widgets/TabFooter';
import TabFooterVideo from './widgets/TabFooterVideo';
import AcceptPhotoButton from '../button/AcceptPhotoButton';
import BottomButton from '../button/BottomButton';
import { panoPicture, panoVideo, footerLayout, getQueryString } from '../../utils/functional';
import FooterButton from '../button/FooterButton';
import ImageDetailFooter from "./ImageDetailFooter";
import ImageDetailFooterAPP3 from "./ImageDetailFooterAPP3";
import Footer from "./Footer";

export function ButtonFooter(props: any) {
  const { setMenuHeight, setBGMItem, tipControl, panoConfig, showButton, showNavigationButton, showAcceptPhotoButton, showQuitCleanButton, onQuitClick, onClick, onBGMItemClick, onNavigationButtonClick, onAcceptPhotoButtonClick, orderData, showShotCause, hideCause, showContribution, handleContribution } = props;
  const code = getQueryString("deviceAppCode");

  const deviceAppCode = code.indexOf('app_code_3');
  if (showQuitCleanButton)
    return (
      <BottomButton
        title="退出清屏模式"
        image="img/icon/ic-quit.png"
        onClick={onQuitClick}
      />
    );
  if (showAcceptPhotoButton) {
    return (
      <Footer>
        <AcceptPhotoButton
          title={`接收${panoPicture() ? '照片' : '视频'}`}
          invalidTitle={`${panoPicture() ? '照片' : '视频'}失效`}
          onClick={onAcceptPhotoButtonClick}
        // footerStyle={panoVideo()}
        />
      </Footer>
    );
  }
  if (showNavigationButton)
    return (
      <Footer>
        <FooterButton
          title='进入"小7全景"'
          image="img/icon/ic-play.png"
          onClick={onNavigationButtonClick}
        />
      </Footer>
    );
  // 精选内容 - 模板
  if (footerLayout() === "default") {
    return (
      <Footer />
    )
  };
  if (showButton) {

    if (deviceAppCode > -1 && panoPicture()) {
      //三代设备界面
      return <ImageDetailFooterAPP3
        tipControl={tipControl}
        orderData={orderData}
        onClick={onClick}
        showShotCause={showShotCause}
        hideCause={hideCause}
        handleContribution={handleContribution}
        showContribution={showContribution}
      />
    } else {
      // 二代设备界面
      return (
        <ImageDetailFooter
          tipControl={tipControl}
          orderData={orderData}
          onClick={onClick}
          showShotCause={showShotCause}
          hideCause={hideCause}
          handleContribution={handleContribution}
          showContribution={showContribution}
        />
      );
    }

  }
  return (
    <>
      {
        panoPicture() ?
          <TabFooter
            tipControl={tipControl}
            panoConfig={panoConfig}
            onBGMItemClick={onBGMItemClick}
            setBGMItem={setBGMItem}
            setMenuHeight={setMenuHeight}
          /> :
          <TabFooterVideo
            tipControl={tipControl}
            panoConfig={panoConfig}
            onBGMItemClick={onBGMItemClick}
            setBGMItem={setBGMItem}
            setMenuHeight={setMenuHeight}
          />
      }
    </>
  );
}

export default ButtonFooter;
