/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { SelectedTab } from '../../data/dataPicture';
import { Strategy } from '../Strategy';
import { DefaultStrategy } from './DefaultStrategy';
import { BGMStrategy } from './BGMStrategy';
import { DynamicStrategy } from './DynamicStrategy';
import { SkyStrategy } from './SkyStrategy';
import { FrameStrategy } from './FrameStrategy';
import { StickerStrategy } from './StickerStrategy';
import { TemplateStrategy } from './TemplateStrategy';

const strategies: any = {
  [SelectedTab.配乐]: new BGMStrategy(),
  [SelectedTab.动效]: new DynamicStrategy(),
  [SelectedTab.换天]: new SkyStrategy(),
  [SelectedTab.边框]: new FrameStrategy(),
  [SelectedTab.贴纸]: new StickerStrategy(),
  [SelectedTab.模板]: new TemplateStrategy(),
};

const defaultStrategy = new DefaultStrategy();

/* 通过网络请求获取素材的谓词 */
export const remoteResourcePredicate = [
  SelectedTab.配乐,
  SelectedTab.动效,
  SelectedTab.换天,
  SelectedTab.边框,
  SelectedTab.贴纸,
  SelectedTab.模板,
];

export function createStrategy(selectedTab: SelectedTab): Strategy<SelectedTab> {
  return strategies[selectedTab] || defaultStrategy;
}
