/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { get, post } from '../utils/network';
import {
  officialPicture,
} from '../utils/functional';
export async function fetchTitle() {
  const [error, result] = await get('/system/config/query/photo_message_title');


  if (error) {
    return [error, null];
  }
  return [null, result];
}

export async function fetchDescription() {
  if (officialPicture()) {
    return fetchDescriptionPicture()
  } else {
    return fetchDescriptionVideo();
  }
}

export async function fetchDescriptionPicture() {
  const [error, result] = await get('/system/config/query/photo_share_desc');

  if (error) {
    return [error, null];
  }
  return [null, result];
}

export async function fetchDescriptionVideo() {
  const [error, result] = await get('/system/config/query/video_share_desc');

  if (error) {
    return [error, null];
  }
  return [null, result];
}

export async function fetchImagePath(orderNo: string) {

  const [error, result] = await get(`/photo/thumbnail-url/${orderNo}`);

  if (error) {
    return [error, null];
  }
  return [null, result];
}

export async function fetchSharedBasicInfo() {
  const [error, result] = await post('/wechat/share-info', {
    url: encodeURIComponent(window.location.href.split("#")[0])
  });

  if (error) {
    return [error, null];
  }
  return [null, result];
}
