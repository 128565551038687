import { useState } from 'react';
import { Base64 } from 'js-base64';
import { fetchRepeat, fetchOrderRefundReason, savePicture, fetchModalUsedUpdate, fetchMaterialUsageUpdate, fetchCheckRefund, fetchContribution } from '../../../api/NetworkApi';
import { executePanoAction, getDataFromPano } from '../../panoramic/PanoramicView';
import { getPanoData } from '../../panoramic/data';
import {
  delay,
  getQueryString,
  getTitle,
  replaceUrl,
} from '../../../utils/functional';
import { ACTION_SCREENSHOT, getScreenshotURL } from '../../panoramic/actions';
import { convertBase64UrlToBlob, getPanoCroppedFile } from '../../../utils/snapshot';

export function useController(params?: any) {
  console.log(params, 'useController');

  const { tipControl } = params;
  const [curPage, setCurPage] = useState('ready'); // 投稿
  // 校验是否可以退款
  async function checkRefund(item: any) {
    const params = {
      "user_product_id": item.user_product_id,
    }
    // alert(`${JSON.stringify(params)}校验退款`)
    const [error, result] = await fetchCheckRefund(params);
    if (error) {
      tipControl.showToast(error);
      return false;
    }
    return true;
  }

  // 退款原因
  async function orderRefundReason() {
    const [error, result] = await fetchOrderRefundReason();
    if (error) return tipControl.showToast(error);
    const list = result.filter((item: any) => {
      return Number(item.code) > 5;
    });
    return list;
  }

  // 重拍
  async function onPhotograph(obj: any) {

    const params = {
      user_product_id: getQueryString('orderId'),
      reason_code: obj.code,
    }
    tipControl.showTextLoading('重拍申请中', 'img/tips/loading.png');
    const [error, result] = await fetchRepeat(params);
    tipControl.hideTextLoading();
    if (error) return error;
    // 跳转拍摄准备页面
    (window as any).wx.miniProgram.reLaunch({
      url: `/pagesA/readyShot/index?typeCode=${obj.type_code}`,
    });
  }

  // 立即投稿
  async function handleContributionConfirm(obj: any) {
    const params = [{
      user_product_id: obj.user_product_id,
      order_id: obj.order_id,
    }];
    tipControl.showLoading();
    const [error, result] = await fetchContribution(params);
    tipControl.dismissLoading();
    if (error) {
      tipControl.showToast(error);
      return false;
    }
    setCurPage('success');
  }

  async function handleSave(reLaunchType: String = "") {
    // console.log(getResetData(), 'getResetData()----')
    console.log(getPanoData(), 'getPanoData()-----');
    const id = getQueryString('orderId');
    const typeCode = getQueryString('typeCode');
    const userWorksId = getQueryString('userWorksId');
    const title = getTitle();

    const panoData = typeCode === '01' ? getPanoData() : getPanoData().jsonData;
    executePanoAction(ACTION_SCREENSHOT);
    await delay();
    const imageBase64Data = getDataFromPano(getScreenshotURL());
    const blob = await convertBase64UrlToBlob(imageBase64Data, 'image/jpeg');
    const thumbnailFile = await getPanoCroppedFile(blob);
    let params: any = {
      userProductId: id,
      userWorksName: title,
      typeCode: typeCode, // 01 全景图，02 普通图，03 视频 04延时视频
      userWorksId,
      data: Base64.encode(JSON.stringify(panoData)),
      thumbnailFile,
    };

    if (typeCode === '01') {
      params = {
        ...params,
        photoUrl: getPanoData().scene[0].sphereurl || undefined,
        originalFlag: getPanoData().scene[0].sky === "sky-reset", // 后台重置换天url
      }
    }
    tipControl.showLoading();
    const [error, result] = await savePicture(params);
    tipControl.dismissLoading();
    if (error) {
      // 推送照片转发给其他用户，不报错直接进入小七全景
      if (result && result.ret_code === 'B105000001004' && reLaunchType === 'save') {
        // 跳转到原片页面
        (window as any).wx.miniProgram.reLaunch({
          url: `/pages/index/index?page=page2`,
        });
        return;
      } else {
        return tipControl.showToast(error);
      }
    }

    const thumbnailUrl = (result && result.thumbnail_url) || '';
    const url = replaceUrl('data', encodeURIComponent(JSON.stringify(panoData)));
    const path = getQueryString('initvars.mypath');
    const stringifyUrl = JSON.stringify(`${url}&mypath=${path}&thumbnailUrl=${thumbnailUrl}`);

    // 模板使用量
    const templateId = panoData.template.value;
    if (templateId) {
      fetchModalUsedUpdate(templateId);
    }

    //更新素材使用量
    tipControl.showLoading();
    await fetchMaterialUsageUpdate(panoData);
    tipControl.dismissLoading();
    if (reLaunchType === 'save') {
      // 跳转到原片页面
      (window as any).wx.miniProgram.reLaunch({
        url: `/pages/index/index?page=page2`,
      });
    } else {
      // 跳转到保存成功界面
      (window as any).wx.miniProgram.reLaunch({
        url: `/pagesA/meitu/save?type=${typeCode}&url=${stringifyUrl}`,
      });
    }
  };

  return {
    curPage,

    handleSave,
    onPhotograph,
    orderRefundReason,
    checkRefund,
    handleContributionConfirm,
  };

}
