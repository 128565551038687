/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 动效策略
 */
import { SelectedVideoTab, SelectedType } from '../../data/dataVideo';
import { Strategy } from '../Strategy';
import { fetchMaterialList } from '../../../../api/NetworkApi';
import { processURL } from '../../../../utils/data';

export class DynamicStrategy extends Strategy<SelectedVideoTab> {
  protected selectedTab: SelectedVideoTab = SelectedVideoTab.动效;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    tipControl.showLoading();
    // 获取类目内容
    const [error, data] = await fetchMaterialList({type: '01', file_type: '02'});
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    const snowData =  data.map((item: any, index: number) => {
      const list = item.material_sorts.map((element: any) => {
        // 新增动效
        let actionItem: any = {
          id: element.id,
          imageurl: processURL(element.file_url),
          framerate: element.data || 0,
        }
        return {
          id: element.id,
          title: element.title,
          image: processURL(element.thumbnail_url),
          selected: false,
          value: actionItem,
          type: 'topimg',
        }
      });
      return {
        title: item.material_group_name,
        selected: false,
        selectedType: SelectedType.border,
        list,
      };
    });
    return snowData;
  }

  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl } = params;
    console.log(`== fetchDataWithGroup ===>>>> ${JSON.stringify(group)}`);
  }
}
