/**
 * 小程序 "全景影像详情（原片/作品/素材库）" Footer
 */
import { useState, useEffect } from 'react'
import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { panoSize } from '../panoramic/data'
import { Color } from '../../constant/Color';
import CauseFooter from "./widgets/CauseFooter";
import ModalFeedBack from "./widgets/ModalFeedBack";
import ModalContribution from "./widgets/ModalContribution";
import { templateAuthority, isUserWorks, getQueryString } from "../../utils/functional";
import { Dialog } from "../modal/Dialog";
import { useController as footerViewController } from "./controller/FooterViewController";
import { EmptyUserRewards } from '../../api/NetworkApi'
export function ImageDetailFooter(props: any) {
  const { tipControl, orderData, onClick, showShotCause, hideCause, handleContribution, showContribution } = props;
  const { onPhotograph, checkRefund } = footerViewController(props);
  const [more, setMore] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showRefundCause, setShowRefundCause] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const miniProgram = (window as any).wx.miniProgram;
  const objectFlag = getQueryString("objectFlag");
  Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.imageButton);
  // 模板
  function onTemplate() {
    // 关闭页面并返回消息
    miniProgram.navigateBack();
    // miniProgram.reLaunch({ url: '/pages/index/index?page=page2' });
    miniProgram.postMessage({
      data: [
        { type: 'template' }
      ],
    });
  }
  // 海报
  function onPoster() {
    if (objectFlag === "works") {
      miniProgram.navigateBack();
    } else {
      miniProgram.reLaunch({ url: '/pages/index/index?page=page2' });
    }
    // 关闭页面并返回消息
    miniProgram.postMessage({
      data: [
        { type: 'poster' }
      ],
    });
  }
  // 退款
  function onRefund(item: any) {
    // 关闭页面并返回消息
    // miniProgram.navigateBack();
    miniProgram.reLaunch({ url: '/pages/index/index?page=page2' });
    miniProgram.postMessage({
      data: [
        { type: 'refund', code: item.code }
      ],
    });
  }
  // 删除作品/原片
  function handleConfirm() {
    if (objectFlag === "works") {
      miniProgram.navigateBack();
    } else {
      miniProgram.reLaunch({ url: '/pages/index/index?page=page2' });
    }
    // 关闭页面并返回消息
    miniProgram.postMessage({
      data: [
        { type: 'delete' }
      ],
    });
  }
  // 跳转3D渲染页面
  function render3D() {
    const newOrderId = getQueryString('newOrderId')
    // 用户点击3D渲染那图片之后 清空用户之前累计拍照数量
    EmptyUserRewards(newOrderId)
    const orderId = getQueryString('orderId')
    // 跳转3D渲染页面
    miniProgram.navigateTo({ url: `/pagesA/render3D/index?userProductId=${orderId}&type=orderEdit` });
  }
  // 跳转AI渲染页面
  function renderAI() {
    const newOrderId = getQueryString('newOrderId')
    // 用户点击AI渲染那图片之后 清空用户之前累计拍照数量
    // EmptyUserRewards(newOrderId)
    const orderId = getQueryString('orderId')
    // 跳转AI渲染页面
    miniProgram.navigateTo({ url: `/pagesA/renderAI/index?userProductId=${orderId}&type=orderEdit&typeFlag=photo` });
  }
  function getQueryString(name: string) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }
  // 更多
  function onMore() {
    setMore(!more);
  }
  // 删除
  function onDelete() {
    setShowDialog(true);
    setMore(!more);
  }
  // 删除取消
  function handleCancel() {
    setShowDialog(false);
  }
  // 退款原因
  async function onRefundCause() {
    setShowFeedback(false); // 意见弹框
    const res = await checkRefund(orderData); // 校验是否可以退款
    if (res) {
      setShowRefundCause(true);
      setMore(false);
    }
  }
  // 取消退款
  function hideRefundCause() {
    setShowRefundCause(false);
  }
  // 打开对影像不满意弹窗
  function onModalFeedBack() {
    setShowFeedback(true);
  }
  // 关闭影像不满意弹窗
  function hideModalFeedBack() {
    setShowFeedback(false);
  }
  // 原片-更多气泡内按钮
  function returnOrderButton() {
    return (
      <>
        {
          objectFlag === "order" &&
          <>
            <Button
              title="立即退款"
              hideImage
              style={styles.popupButton}
              onClick={onRefundCause}
            />
            <Divider />
          </>
        }
        <Button
          title="删除原片"
          hideImage
          style={styles.popupButtonFooter}
          onClick={onDelete}
        />
      </>
    );
  }
  // 作品-更多气泡内按钮
  function retrunWorksButton() {
    return (
      <>
        {
          templateAuthority() &&
          <>
            <Button
              title="发布模板"
              hideImage
              style={styles.popupButton}
              onClick={onTemplate}
            />
            <Divider />
          </>
        }
        <Button
          title="删除作品"
          hideImage
          style={styles.popupButtonFooter}
          onClick={onDelete}
        />
      </>
    );
  }

  console.log('orderData.repeat_flag :', orderData.repeat_flag);
  // 原片底部按钮
  function orderFooterButtom() {
    return (
      <>
        {
          orderData.repeat_flag &&
          <Button
            title="对影像不满意？"
            hideImage
            style={styles.button1}
            onClick={onModalFeedBack}
          />
        }
        <Button
          title="修图"
          image="img/icon/picture-icon.png"
          styleImage={styles.imagePengYou}
          style={orderData.repeat_flag ? styles.button2 : styles.button4}
          onClick={onClick}
        />
      </>
    );
  }
  // 作品底部按钮
  function worksFooterButtom() {
    return (<>
      <Button
        title="更多"
        hideImage
        style={styles.button0}
        onClick={onMore}
      />
      <Button
        title="分享朋友圈"
        image="img/icon/poster-icon.png"
        styleImage={styles.imagePengYou}
        style={styles.button0}
        onClick={onPoster}
      />
      <Button
        title="修片"
        image="img/icon/picture-icon.png"
        onClick={onClick}
      />
    </>);
  }
  // 模版素材库底部按钮
  function templateFooterButtom() {
    return (
      <Button
        title="修片"
        image="img/icon/picture-icon.png"
        onClick={onClick}
      />
    );
  }
  return (
    <div>
      {/* 更多 */}
      {
        more &&
        <div style={styles.mask} onClick={onMore}>
          <div style={styles.popup}>
            <img src='img/icon/popup.png' alt="" style={styles.popupImage} />
            {
              isUserWorks() ? retrunWorksButton() : returnOrderButton()
            }
          </div>
        </div>
      }
      {/* 底部 */}
      <div style={{ ...styles.container, ...objectFlag !== "template" && styles.buttonsBox }}>
        {
          // template素材库 works作品 order/share 原片
          objectFlag === "template" ? templateFooterButtom() :
            objectFlag === "works" ? worksFooterButtom() : orderFooterButtom()
        }
      </div>
      {/* 对影像不满意弹窗 */}
      <ModalFeedBack
        show={showFeedback}
        onCancel={hideModalFeedBack}
        onRefundCause={onRefundCause}
        handleShot={hideCause}
      />
      {/* 删除作品/原片（删除原片屏蔽）弹窗 */}
      <Dialog
        show={showDialog}
        title={`确认删除该${objectFlag === "works" ? "作品" : "原片"}吗？`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      {
        // 投稿
        showContribution &&
        <ModalContribution
          orderData={orderData}
          tipControl={tipControl}
          onCancel={handleContribution}
        />
      }
      {
        showRefundCause &&
        <CauseFooter
          title="退款原因"
          tipControl={tipControl}
          cancel={hideRefundCause}
          onClick={onRefund}
        />
      }
      {
        showShotCause &&
        <CauseFooter
          title="重拍原因"
          tips="对拍摄效果不满意，10分钟内可选择重拍"
          orderData={orderData}
          tipControl={tipControl}
          cancel={hideCause}
          onClick={onPhotograph}
        />
      }
    </div>
  );
}

export function Button(props: any) {
  function handleClick() {
    props.onClick && props.onClick()
  }
  return (
    <div style={props.style || styles.button} onClick={handleClick}>
      {
        !props.hideImage &&
        <img src={props.image} alt="" style={props.styleImage || styles.image} />
      }
      <div style={styles.text}>{props.title}</div>
    </div>
  );
}

// 分割线 
export function Divider(props: any) {
  return (
    <div style={props.style || styles.divider}></div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    height: 45,
    width: '100%',
    backgroundColor: '#000',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 20,
    position: 'fixed',
    bottom: 0,
    zIndex: 1009,
    overflow: 'hidden',
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: Color.orange,
    height: 45,
    paddingLeft: 40,
    paddingRight: 40,
  },
  button0: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    paddingLeft: 25,
    paddingRight: 10,
  },
  button1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    paddingLeft: 25,
  },
  button2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
  },
  button3: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 45,
    backgroundColor: Color.orange,
  },
  button4: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 45,
  },
  image: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  imagePengYou: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  text: {
    fontWeight: "500",
    color: "#FFFFFFFF",
    fontSize: 15,
  },
  // 气泡
  mask: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 1010,
    // overflow: 'hidden',
  },
  popup: {
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    left: 15,
    bottom: 75,
    zIndex: 1009,
    overflow: 'hidden',
    paddingBottom: 5,
  },
  popupButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "10px 25px",
  },
  popupButtonFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
  },
  popupImage: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: '-1',
  },
  divider: {
    height: 1,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: '#454749',
  }
};

export default ImageDetailFooter;
