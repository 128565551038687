/**
 * 重拍组合按钮
 */
import { useState, useEffect } from "react";
import { useController } from "../controller/FooterViewController";
import Modal from "../../modal/Modal";
import { Color } from "../../../constant/Color";


export function ModalContribution(props: any) {
  const { tipControl, orderData } = props;
  const { curPage, handleContributionConfirm } = useController(props);
  const miniProgram = (window as any).wx.miniProgram;

  // 退出
  function handleQuit() {
    props.onCancel && props.onCancel();
  }

  // 知道了-投稿成功
  function handleSuccess() {
    props.onCancel && props.onCancel('success');
  }

  // 活动详情
  function onActivityDetail() {
    // 跳转活动详情页面
    miniProgram.navigateTo({ url: '/pagesA/contributeDetail/index' });
  }

  return (
    <div>
      <Modal>
        {
          curPage === 'ready' ?
            <div style={styles.container}>
              <div style={styles.contentContainer}>
                <img style={styles.imgReady} src="https://file.runbo360field.com/ads/images/contribution-ready.png" alt=""/>
                <div style={styles.buttonBox}>
                  <img style={styles.buttonBox.img1} src="img/icon/icon-activity-detail.png" alt="" onClick={onActivityDetail} />
                  <img style={styles.buttonBox.img2} src="img/icon/icon-contribution.png" alt="" onClick={() => handleContributionConfirm(orderData)} />
                </div>
              </div>

              <div onClick={handleQuit}>
                <img style={styles.imgQuit} src="img/icon/ic-shut.png" alt="" />
              </div>
            </div> :
            <div style={styles.container}>
              <div style={styles.contentContainer}>
                <img style={styles.imgSuccess} src="https://file.runbo360field.com/ads/images/contribution-success.png" alt="" />
                <img style={styles.buttonImg} src="img/icon/icon-tg-success.png" alt="" onClick={handleSuccess} />
              </div>
            </div>
        }
      </Modal>
    </div>
  );
}


const styles: any = {
  // 右边按钮
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: 200,
    right: 5,
    width: 50,
    zIndex: 1000,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(2px)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1009,
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: 300,
    height: 500,
  },
  imgReady: {
    width: '100%',
    height: '100%',
  },
  imgSuccess: {
    width: '100%',
    height: '100%',
  },
  imgQuit: {
    width: 27,
    height: 27,
    paddingTop: 20,
  },
  buttonBox: {
    position: 'absolute',
    bottom: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    img1: {
      width: 106,
      height: 45,
      marginRight: 10,
    },
    img2: {
      width: 138,
      height: 45,
    }
  },
  buttonImg: {
    position: 'absolute',
    bottom: 33,
    width: 195,
    height: 60,
  }
};

export default ModalContribution;

