/**
 * Created by zhang.zhongdong
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import Modal from "../../modal/Modal";
import { Color } from '../../../constant/Color';

export function ModalFeedBack(props: any) {
  console.log(props)
  function handleClick() {
    props.onCancel && props.onCancel()
  }
  function handleShot() {
    props.onCancel && props.handleShot()
  }
  function onRefundCause() {
    props.onCancel && props.onRefundCause()
  }
  return (
    props.show &&
    <Modal>
      <div style={styles.container}>
        <div style={styles.contentContainer}>
          <img style={styles.imgShot} src="img/icon/opinion-bg.png" alt="" />
          <div style={styles.text}>对不起，没有让您满意～</div>
          <div style={styles.text1}>98%的人重拍后都能得到满意的影像</div>
          <img style={styles.imgBtn} src="img/icon/ic-btn.png" alt="" onClick={handleShot} />
          <div style={styles.text2} onClick={onRefundCause}>不拍了，退款吧</div>
        </div>

        <div onClick={handleClick}>
          <img style={styles.imgQuit} src="img/icon/ic-shut.png" alt="" />
        </div>
      </div>
    </Modal>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(2px)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1009,
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundColor: Color.moduleBgColor,
    width: 300,
    height: 450,
    borderRadius: 10,
  },
  text: {
    position: 'absolute',
    top: 50,
    color: '#EDEEF2FF',
    fontSize: 20,
    fontWeight: 500,
  },
  text1: {
    position: 'absolute',
    top: 90,
    color: '#FF9100FF',
    fontSize: 12,
  },
  text2: {
    position: 'absolute',
    bottom: 25,
    color: '#FFFFFFFF',
    fontSize: 12,
  },
  imgShot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  imgBtn: {
    position: 'absolute',
    bottom: 55,
    // width: 252,
    height: 62,
  },
  imgQuit: {
    width: 25,
    height: 25,
    paddingTop: 20,
  }
};

export default ModalFeedBack;
