/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 小程序 "玩图" Tab Footer
 */
import ContentAdapterVideo from '../../tab/ContentAdapterVideo';
import TabContainer from '../../tab/TabContainer';
import { Color } from '../../../constant/Color';
import { dataTreeVideo } from '../../tab/data/dataVideo';
import { getQueryString } from '../../../utils/functional';
import { tabMapVideo } from '../../tab/data/dataVideo';
import { useController as footerViewController } from '../controller/FooterViewController';
import { useController } from '../controller/TabFooterVideo';
import { videoTabs } from '../../tab/data/dataVideo';
import { Wormhole, Destination } from '../../../vendor/wormhole/index';
import { panoSize } from '../../panoramic/data';
import ConfirmSpecialEffects from '../../confirm/ConfirmSpecialEffects';

export function TabFooter(props: any) {
  const editType = getQueryString('editType');
  const initSelectedTab = tabMapVideo[editType];

  const {
    panoConfig,
    tipControl,
    selectedTab,
    handleTabClick,
    handleClick,
    handleGroupTitleClick,
    handleItemClick,
    // handleSave,
    onConfirm,
    onCancel,
  } = useController(props, { initSelectedTab });

  const { handleSave } = footerViewController(props);

  if (selectedTab === 'OTHERS') {
    // 设置 pano
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.tabButton);
    // props.setMenuHeight(panoSize.tabButton)
  } else if (selectedTab === '动效') {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.effect);
    // props.setMenuHeight(panoSize.effect)
  } else {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.content);
    // props.setMenuHeight(panoSize.content)
  }

  return (
    <div style={styles.container}>
      {
        selectedTab !== 'OTHERS' ?
          <>
            <ConfirmSpecialEffects 
              title={selectedTab}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
            <div style={styles.line}></div>
            <ContentAdapterVideo
              dataTree={dataTreeVideo}
              tipControl={tipControl}
              panoConfig={panoConfig}
              selectedTab={selectedTab}
              onClick={handleClick}
              onGroupTitleClick={handleGroupTitleClick}
              onItemClick={handleItemClick}
            />
          </>
          : 
            <TabContainer
              selectedTab={selectedTab}
              tabs={videoTabs}
              onTabClick={handleTabClick}
              onSave={handleSave}
              style={styles}
            />
      }
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    backgroundColor: '#0C0D0FFF',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
  line: {
    height: 1,
    backgroundColor: Color.line,
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    overflow: 'auto',
    marginRight: 10,
    width: '100%',
  },
  // text: {
  //   fontSize: 10,
  //   marginTop: 5,
  // },
  // unSelectedText: {
  //   fontSize: 10,
  //   marginTop: 5,
  //   color: Color.grayFont,
  // },
};

export default TabFooter;
