/**
 * description:
 * 重拍 "退款原因" Footer
 */

import { useState, useEffect } from 'react';
import { useController } from '../controller/FooterViewController';
import Tips from '../../toast/Tips';

export function CauseFooter(props: any) {

  const { orderRefundReason } = useController(props);
  //  const { list } = props;
  const [causeList, setCauseList] = useState([]);
  const [title, setTitle] = useState("");
  const [showTips, setShowTips] = useState(false);

  useEffect(() => {
    orderRefundReason().then((res: any) => {
      setCauseList(res)
    })
  }, []);

  async function handleClick(code: string) {
    const params = {
      ...props.orderData,
      code,
    }
    const error = await props.onClick(params);
    if (error) {
      setTitle(error);
      setShowTips(true);
    }
  }

  function cancel() {
    props.cancel && props.cancel()
  }

  function onHideTips() {
    setShowTips(false);
  }

  return (
    <>
      <div style={styles.container}>
        <div style={styles.footer}>
          <div style={styles.title}>
            <div style={styles.title.p1}>{props.title}</div>
            <div style={styles.title.p2}>{props.tips}</div>
          </div>
          {
            causeList.map((item: any) => (
              <div style={styles.content} key={item.code} onClick={() => handleClick(item.code)}>{item.value}</div>
            ))
          }
          <div style={styles.cancel} onClick={cancel}>取消</div>
        </div>
      </div>
      {
        showTips && 
        <Tips title={title} image='img/tips/busy.png' onClick={onHideTips}/>
      }
    </>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    // backgroundColor: 'rgba(56, 56, 56, 0.4)',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(2px)',
    position: 'fixed',
    zIndex: 2000,
    // overflow: 'hidden',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    bottom: 20,
    width: '94%',
    background: '#181B1FFF',
    borderRadius: '15px'
  },
  title: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #4A4B4DFF',
    marginBottom: 2,
    padding: '12px 0',
    p1: {
      fontSize: 14,
      fontWeight: '600',
      color: '#FFF'
    },
    p2: {
      fontSize: 12,
      color: '#74767AFF',
    }
  },
  content: {
    width: '100%',
    textAlign: 'center',
    padding: '10px 0',
    fontSize: 14,
    fontWeight: '500',
    color: '#fff',
  },
  cancel: {
    width: '94%',
    textAlign: 'center',
    background: '#2D333AFF',
    padding: '10px 0',
    marginBottom: 10,
    fontSize: 14,
    fontWeight: '500',
    color: "#FF9100FF",
    borderRadius: '10px'
  }
};

export default CauseFooter;
