/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import SimpleFooter from '../../footer/SimpleFooter';
import MenuLayout from '../../menu/MenuLayout';
import QRIconLayout from './QRIconLayout';
import BottomButton from '../../button/BottomButton';
import Dialog from '../../modal/Dialog';
import { useController } from './controller/OfficialFloatViewController';
import { officialPicture } from '../../../utils/functional';

export function OfficialFloatView() {
  const {
    footerVisible,
    cleanScreen,
    showQuitCleanButton,
    viewObj,

    handleDialogConfirm,
    handleFooterStatusChanged,
    handleCleanScreen,
    handleQuitCleanClick,
  } = useController();

  if (cleanScreen && !showQuitCleanButton) return <></>;
  if (showQuitCleanButton)
    return (
      <BottomButton
        title="退出清屏模式"
        image="img/icon/ic-quit.png"
        onClick={handleQuitCleanClick}
      />
    );
  return (
    <div style={styles.container}>
      <Dialog
        show={viewObj.showDialog}
        title={viewObj.errorMessage}
        onConfirm={handleDialogConfirm}
      />
      <MenuLayout
        type="official"
        menuHeight={{ top: 20 }}
        onCleanScreen={handleCleanScreen}
        onFooterStatusChanged={handleFooterStatusChanged}
      />
      <QRIconLayout data={viewObj.scenicInfo} />
      {
        officialPicture() ?
          <SimpleFooter
            visible={footerVisible}
            location={viewObj.location}
            dateTime={viewObj.dateTime}
          /> : <></>
      }
    </div>
  );
}

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
};

export default OfficialFloatView;
