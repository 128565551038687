/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 无策略
 */
import { SelectedTab } from '../../data/dataPicture';
import { Strategy } from '../Strategy';

export class DefaultStrategy extends Strategy<SelectedTab> {
  protected selectedTab: SelectedTab = SelectedTab.OTHERS;

  public async fetchData(params: any): Promise<any> {}
  public async fetchDataWithGroup(params: any): Promise<any> {}
}
