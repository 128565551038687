/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export abstract class Strategy<T> {
  protected abstract selectedTab: T;

  public abstract fetchData(params: any): Promise<any>;
  public abstract fetchDataWithGroup(params: any): Promise<any>;
}
