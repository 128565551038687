/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 配乐策略
 */
import { Base64 } from 'js-base64';
import { SelectedVideoTab, SelectedType } from '../../data/dataVideo';
import { Strategy } from '../Strategy';
import { fetchTemplateTypeList, fetchTemplateList } from '../../../../api/NetworkApi';
import { getPanoData } from '../../../panoramic/data';
import { combineDataList, combineDataListWithDataList } from '../data';
import { getQueryString } from '../../../../utils/functional';
import { Destination, Wormhole } from '../../../../vendor/wormhole/index';
import { processURL } from '../../../../utils/data';
import { AnchorHTMLAttributes } from 'react';

export class TemplateStrategy extends Strategy<SelectedVideoTab> {
  protected selectedTab: SelectedVideoTab = SelectedVideoTab.配乐;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    // tipControl.showLoading();
    // 获取类目内容
    // const [error, data] = await fetchTemplateTypeList();
    // tipControl.dismissLoading();
    // if (error) return tipControl.showToast(error);
    const data = [{id: "1", name: "推荐模板"}]
    const template = getPanoData().template;
    const category = data[0].id; // 获取初始化分类内容id
    tipControl.showLoading();
    const [itemError, itemData] = await fetchTemplateList('0', '04');
    tipControl.dismissLoading();
    if (itemError) return tipControl.showToast(itemError);

    const itemResultData = itemData.result_list;
    // 组装数据, 更新 dataTree
    const newList = combineDataList(data, itemResultData, category, template.value);
    // await delay(2000);
    return newList;
  }

  // 点击分组
  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl, } = params;
    console.log(params)
    const someOne = list.find((item: any) => item.id === group.id);
    if (someOne.list.length > 1) return;

    // 更新模版
    tipControl.showLoading();
    const [error, result] = await fetchTemplateList(group.id, '04');
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    const itemDataList = result.result_list;

     // 组装数据, 更新 dataTree
     return combineDataListWithDataList(list, itemDataList, group.id);
  }
}
