import { useState, useEffect } from 'react';
import Modal from '../modal/Modal';

export function Tips(props: any) {
  
  function handleClick() {
    props.onClick && props.onClick();
  };

  return (
    <Modal>
      <div style={styles.container}>
        <div style={styles.contentContainer}>
          <img src="img/tips/tips.png" alt="" style={styles.image1} />
          <img src={props.image} alt="" style={styles.image2} />
          <p style={styles.text}>{props.title}</p>
        </div>
        <div style={styles.button} onClick={handleClick}>知道了</div>
      </div>
    </Modal>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  },
  contentContainer: {
    position: 'relative',
    width: 300,
    padding: '40px 0',
    textAlign: 'center',
    color: 'white',
    fontWeight: 500,
    backgroundColor: "#22272DFF",
    borderRadius: '15px 15px 0 0',
  },
  button: {
    width: 300,
    color: 'white',
    fontWeight: 600,
    borderRadius: '0 0 15px 15px',
    backgroundColor: '#ff9d1e',
    textAlign: 'center',
    padding: '15px 0'
  },
  image1: {
    width: 65,
    height: 65,
    marginBottom: 10,
  },
  image2: {
    position: 'absolute',
    top: 63,
    left: 141,
    width: 20,
    height: 20,
  }
};

export default Tips;