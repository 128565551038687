/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 贴纸策略
 */
import { SelectedTab, SelectedType } from '../../data/dataPicture';
import { Strategy } from '../Strategy';
import { fetchMaterialList } from '../../../../api/NetworkApi';
import { processURL } from '../../../../utils/data';

export class StickerStrategy extends Strategy<SelectedTab> {
  protected selectedTab: SelectedTab = SelectedTab.贴纸;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    tipControl.showLoading();
    // 获取类目内容
    const [error, data] = await fetchMaterialList({type: '04', file_type: '01'});
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    const stickerData = data.map((item: any, index: number) => {
      const list = item.material_sorts.map((element: any) => (
        {
          id: element.id,
          title: element.title,
          image: processURL(element.thumbnail_url),
          selected: false,
          value: processURL(element.file_url),
        }
      ));
      return {
        title: item.material_group_name,
        selected: index === 0,
        selectedType: SelectedType.border,
        list,
      };
    });
    return stickerData;
    // return [{
    //   title: '除硕鼠',
    //   image: 'img/sticker/sticker-icon-22.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-22.png',
    // }, {
    //   title: '转昌年',
    //   image: 'img/sticker/sticker-icon-21.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-21.png',
    // }, {
    //   title: '好运飞腾',
    //   image: 'img/sticker/sticker-icon-20.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-20.png',
    // }, {
    //   title: '平安贺岁',
    //   image: 'img/sticker/sticker-icon-19.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-19.png',
    // }, {
    //   title: '瑞岁祥年',
    //   image: 'img/sticker/sticker-icon-18.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-18.png',
    // }, {
    //   title: '好运牛年',
    //   image: 'img/sticker/sticker-icon-17.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-17.png',
    // }, {
    //   title: '爱心烟花',
    //   image: 'img/sticker/sticker-icon-16.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-16.png',
    // }, {
    //   title: '蝴蝶',
    //   image: 'img/sticker/sticker-icon-1.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-1.png',
    // }, {
    //   title: '火焰翅膀',
    //   image: 'img/sticker/sticker-icon-2.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-2.png',
    // }, {
    //   title: '流星',
    //   image: 'img/sticker/sticker-icon-3.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-3.png',
    // }, {
    //   title: '流星雨',
    //   image: 'img/sticker/sticker-icon-4.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-4.png',
    // }, {
    //   title: '星光',
    //   image: 'img/sticker/sticker-icon-5.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-5.png',
    // }, {
    //   title: '烟花1',
    //   image: 'img/sticker/sticker-icon-6.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-6.png',
    // }, {
    //   title: '烟花2',
    //   image: 'img/sticker/sticker-icon-7.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-7.png',
    // }, {
    //   title: '魔法阵',
    //   image: 'img/sticker/sticker-icon-8.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-8.png',
    // }, {
    //   title: '瀑布',
    //   image: 'img/sticker/sticker-icon-9.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-9.png',
    // }, {
    //   title: 'disco石头',
    //   image: 'img/sticker/sticker-icon-10.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-10.png',
    // }, {
    //   title: '摇头晃脑',
    //   image: 'img/sticker/sticker-icon-11.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-11.png',
    // }, {
    //   title: '魔性',
    //   image: 'img/sticker/sticker-icon-12.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-12.png',
    // }, {
    //   title: '爱心',
    //   image: 'img/sticker/sticker-icon-13.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-13.png',
    // }, {
    //   title: '标签',
    //   image: 'img/sticker/sticker-icon-14.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-14.png',
    // }, {
    //   title: '对不起',
    //   image: 'img/sticker/sticker-icon-15.gif',
    //   selected: false,
    //   value: 'img/sticker/sticker-15.png',
    // }];
  }

  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl } = params;
    console.log(`== fetchDataWithGroup ===>>>> ${JSON.stringify(group)}`);
  }
}
