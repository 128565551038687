/**
 * https://github.com/maybewaityou
 *
 * description:
 * 模板展示
 */
 import { Base64 } from 'js-base64';
 import { dynamicLoadPanoXML, reloadPanoXML, executePanoAction } from '../../../../panoramic/PanoramicView';
import { stopBGM } from '../../../../panoramic/actions';
 import { setPanoData, transformToPanoData, addPanoData, getPanoData, setResetData } from '../../../../panoramic/data';
 import {
   delay,
   getQueryString,
   shouldChangeSky,
   isSameTemplate,
   panoRenderType,
 } from '../../../../../utils/functional';
 import { fetchBackgroundMusicURL, fetchContentDetail, changeSkyTask, getOriginImagePath, fetchDataConfigById } from '../../../../../api/NetworkApi';
 import { TemplateType } from '../../../../panoramic/template';
 import { processURL } from '../../../../../utils/data';

/**
 * 视频模板加载
 */
 export function templatePictureLoad(templateId: any, params?: any) {
  const { tipControl } = params;
  const id = getQueryString('orderId') || getQueryString('userWorksId');
  // 快速模板默认选中
  getPanoData().template = {
    groupId: getQueryString('groupId'),
    value: getQueryString('templateId'),
  }
  // 模板玩图true，精选内容模板详情false
  if (id) {   
    tipControl.showLoading();
    fetchDataConfigById(id).then(async ([error, result]) => {
      tipControl.dismissLoading();
      if (error) return tipControl.showToast(error);
      // 作品保留data
      if (result.data) {
        const data = JSON.parse(Base64.decode(result.data));
        setResetData(data);
      } else {
        setResetData(getPanoData());
      }
      templateAction(templateId, params);
    }).catch((error) => {
      console.log(error);
    });
  } else {
    templateAction(templateId, params);
  }
 }

/**
 * 照片模板加载
 */
export function templateAction(templateId: any, params?: any) {
  const { tipControl, hooks: { setBGMItem } } = params;
  tipControl.showLoading();
    fetchContentDetail(templateId).then(async ([error, result]) => {
      tipControl.dismissLoading();
      if (error) return tipControl.showToast(error);
  
      const userWorkData = result.resource_list[0].user_work_data;
      if (userWorkData && userWorkData !== '0') {
        const config = JSON.parse(Base64.decode(userWorkData));
        addPanoData(config); // 补充默认玩图数据
        const data = config.scene ? config : transformToPanoData(config);
        if (config.scene) {
          data.scene[0].url = getQueryString('initvars.mypath');
        }

        console.log(data);

        //快速模板，当前模板分组和模板。点击确定后选中状态
        data.template = {
          groupId: getQueryString('groupId'),
          value: getQueryString('templateId')
        };
        
        // 设置 PanoData, PanoXML 加载时会读取 PanoData, 并还原设置
        setPanoData(data);
        // setPanoConfig(data);

        // 模板不存在换天
        if (!shouldChangeSky()) {
          // 判断作品是否存在换天, 存在则恢复原图
          if (panoRenderType() === TemplateType.sphere) {
            // 取消换天
            await unChangeSky(data, params);
          }
        }
  
        // 玩同款，并存在换天，防止首先加载模板一闪在加载换天后的作品
        if (isSameTemplate() && shouldChangeSky()) {
          data.scene[0].sphereurl = "";
        }
  
        // 不存在音乐, 直接加载 XML
        if (!data.bgmItem.value) {
          dynamicLoadPanoXML();
        } else {
          musicLoad(data, params);
        }
        // 玩同款时, 手动换天
        if (isSameTemplate() && shouldChangeSky()) {
          changeSky(data, params);
        }
      }
    });
}

// 点击模版加载
export function templateLoad(item: any, params?: any) {
  const { tipControl } = params;
  tipControl.showLoading();
    fetchContentDetail(item.value).then(async ([error, result]) => {
      tipControl.dismissLoading();
      if (error) return tipControl.showToast(error);
  
      const userWorkData = result.resource_list[0].user_work_data;
      if (userWorkData && userWorkData !== '0') {
        const config = JSON.parse(Base64.decode(userWorkData));
        addPanoData(config); // 补充默认玩图数据
        const data = config.scene ? config : transformToPanoData(config);
        if (config.scene) {
          data.scene[0].url = getQueryString('initvars.mypath');
        }
        
        
        // 记录玩图点击模板，保存模板数据
        data.template = {
          groupId: item.groupId,
          value: item.value,
        };
        
        console.log(data);
        // 设置 PanoData, PanoXML 加载时会读取 PanoData, 并还原设置
        setPanoData(data);
        // setPanoConfig(data);

        // 模板不存在换天
        if (!shouldChangeSky()) {
          // 判断作品是否存在换天, 存在则恢复原图
          if (panoRenderType() === TemplateType.sphere) {
            // 取消换天
            await unChangeSky(data, params);
          }
        }
  
        // 存在换天，防止首先加载模板一闪在加载换天后的作品
        if (shouldChangeSky()) {
          data.scene[0].sphereurl = "";
        }
  
        // 不存在音乐, 直接加载 XML
        if (!data.bgmItem.value) {
          executePanoAction(stopBGM());
          reloadPanoXML();
        } else {
          musicLoad(data, params, 'playTemplate');
        }

        // 手动换天
        if (shouldChangeSky()) {
          changeSky(data, params);
        }
      }
    });
}

// 换天
async function changeSky(data: any, params: any) {
  const { tipControl } = params;
  const skyCode = data.scene[0].sky;
  tipControl.showLoading();
  // tipControl.showTextLoading('天空加载中，请稍后', 'img/tips/wait.png');
  const [error, result] = await changeSkyTask({
    id: getQueryString('orderId'),
    skyCode,
  });
  tipControl.dismissLoading();
  if (error) return tipControl.showToast(error);
  data.scene[0].sphereurl = processURL(result);
  data.scene[0].sky = skyCode;
  setPanoData(data);
  // setPanoConfig(data);
  await delay(200);
  reloadPanoXML();
}

// 取消换天
export async function unChangeSky(data: any, params: any) {
  const { tipControl } = params;
  tipControl.showLoading();
  const [error, result] = await getOriginImagePath();
  tipControl.dismissLoading();
  if (error) return tipControl.showToast(error);

  data.scene[0].url = processURL(result);
  setPanoData(data);
}

// 音乐
async function musicLoad(data: any, params:any, loadTyle?: any) {
    const { tipControl, hooks: { setBGMItem } } = params;
    // 获取音乐 URL
    const [bgmError, bgmResult] = await fetchBackgroundMusicURL(data.bgmItem.value)
    if (bgmError) return tipControl.showToast(bgmError);

    let resultData;
    try {
      resultData = JSON.parse(Base64.decode(bgmResult.data));
    } catch (error) {
      console.log(error);
      // return;
    }
    if (!resultData || !resultData.data) {
      // tipControl.showToast(`${resultData.code} - ${resultData.msg}`);
      loadTyle ? reloadPanoXML() : dynamicLoadPanoXML();
    } else {
      const { url } = resultData.data;
      if (url) {
        data.music = url;
        const extraItem = { ...data.bgmItem, url, shouldPlay: false, };
        setPanoData(data);
        loadTyle ? reloadPanoXML() : dynamicLoadPanoXML();
        // setPanoConfig(data);
        setBGMItem(extraItem);

        // handleBGMItemClick(extraItem);
      }
    }
}