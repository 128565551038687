import React from 'react';
import { getQueryString } from '../../utils/functional'
export function Payment(props: any) {
    const price = getQueryString('paymentPrice')
    const uni = (window as any).uni;
    const miniProgram = (window as any).wx.miniProgram;
    const paymentTime = getQueryString('paymenyTime')
    const { aplus_queue }: any = window;
    const immediatePayment = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const device_code = getQueryString("deviceCode");
        const product_id = getQueryString("productId");
        const order_no = getQueryString("orderNo");
        const order_id = getQueryString("orderId");
        aplus_queue.push({
            action: "aplus.record",
            arguments: ['player_payment', 'CLK', {
                'device_code': device_code
            }],
        });
        // device_code
        // product_id
        // order_no
        miniProgram.postMessage({
            data: [{
                type: "payment", miniProgramPay: {
                    device_code,
                    product_id,
                    order_no,
                    order_id
                }
            }],
        });
        uni.reLaunch({ url: "/pages/index/index?page=page2" });
    }

    const timeRemaining = (time: string) => {
        // 给定时间字符串
        const givenTime = time;
        // 将给定时间字符串转换为日期对象
        const givenDate = new Date(givenTime);
        // 获取给定时间的时间戳（毫秒级）
        const givenTimestamp = givenDate.getTime();
        // 计算未来5天后的时间戳
        const futureTimestamp = givenTimestamp + (5 * 24 * 60 * 60 * 1000);
        // 获取当前时间的时间戳（毫秒级）
        const currentTimestamp = Date.now();
        // 计算时间戳差值
        const timestampDiff = futureTimestamp - currentTimestamp;
        // 将时间戳差值转换为天数
        const daysDiff = Math.ceil(timestampDiff / (24 * 60 * 60 * 1000));
        console.log(daysDiff); // 输出时间差（天数）
        return daysDiff
    }
    // type_code：01全景照片 03全景视频 04延时全景视频
    return (
        <div style={styles.payment}>
            <div style={styles.model}>
                <img style={styles.img} src="/img/lock.jpg" alt="" />
                <span style={styles.span}>付费解锁高清{props.type_code == '01' ? '全景照片' : props.type_code == '03' ? '全景视频' : '延时3D'}</span>
                <span style={styles.span1}>不满意可重拍、退款</span>
                <span style={styles.span2}>{price}</span>
                <button style={styles.button} onClick={immediatePayment}>立即解锁</button>
                <span style={styles.span3}>未解锁影像将被删除，解锁后可永久保存</span>
                <span style={styles.span4}>距离照片删除还剩：{timeRemaining(paymentTime)}天</span>
            </div>
        </div>
    )
}
const styles: any = {
    payment: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        // backgroundColor: '#000',
        zIndex: '111111111',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    model: {
        width: '90%',
        height: '300px',
        backgroundColor: 'rgba(255,255,255,0.8)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-125px',
        marginLeft: '-45%',
        borderRadius: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '10px 0'
    },
    img: {
        width: '80px',
        height: '80px',
        margin: '10px 0',

    },
    span: {
        color: '#000',
        fontSize: '16px',
    },
    span1: {
        color: '#fff',
        backgroundColor: '#000',
        borderRadius: '20px',
        padding: '5px 20px',
        fontSize: '18px'
    },
    span2: {
        color: '#000',
        fontSize: '20px',
        margin: '5px 0',
    },
    button: {
        width: '50%',
        height: '60px',
        borderRadius: '30px',
        backgroundColor: '#F29F40',
        border: '0',
        fontSize: '20px',
        fontWeight: '450',
        margin: '5px 0',
    },
    span3: {
        color: '#000',
        fontSize: '16px',
    },
    span4: {
        color: 'red',
        fontSize: '16px',
        margin: '3px 0',
    },
    backBroundImg: {
        width: '100%',
        height: '100%'
    }
}
export default Payment;