import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { Color } from '../../constant/Color';
const DialogImage = (props: any) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };


  return (
    <>
      {
        props.show && (
          <Modal>
            <div style={styles.container}>
              <div style={styles.contentContainer}>
                <div style={styles.content}>
                  <img
                    style={expanded ? styles.imgExpanded : styles.img}
                    src={props.image}
                    onClick={toggleExpanded}
                  />
                </div>
                <div style={styles.line} />
                <div style={styles.buttonContainer}>
                  {props.onCancel && (
                    <div style={styles.button} onClick={props.onCancel}>
                      {props.negativeTitle || '取消'}
                    </div>
                  )}
                  <div style={styles.positiveButton} onClick={props.onConfirm}>
                    {props.positiveTitle || '确定'}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1009,
  },
  img: {
    width: '100%',
    height: 'auto',
    maxHeight: '80vh',
    borderRadius: 10,
    cursor: 'pointer',
  },
  imgExpanded: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    objectFit: 'contain',
    cursor: 'pointer',
    zIndex: 1109,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Color.moduleBgColor,
    paddingTop: 30,
    width: '80%',
    maxWidth: 600,
    borderRadius: 10,
    overflow: 'hidden',
  },
  content: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    color: '#D5D5D5FF',
    fontWeight: '600',
    textAlign: 'center',
  },
  line: {
    height: 1,
    marginTop: 20,
    backgroundColor: Color.line,
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#D5D5D5FF',
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
    cursor: 'pointer',
  },
  positiveButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Color.orange,
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
    cursor: 'pointer',
  },
};

export default DialogImage;
