/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

/**
 * 将 base64 转换成图片文件
 */
export async function convertBase64UrlToBlob(urlData: string, filetype: string) {
  const bytes = window.atob(urlData.split(',')[1]);
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i += 1) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type: filetype });
};

/**
 * blob 转换成 File 全景图
 */
export async function getPanoCroppedFile(blob: Blob, quality = 1) {
  return new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
}
