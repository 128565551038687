/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 小程序 "详情" Footer
 */

import { useState, useEffect } from 'react';
import { isNotEmpty } from '../../utils/functional';
import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { panoSize } from '../panoramic/data'


enum ButtonStatus {
  open = 1,
  close,
  nothing,
}

export function TextFooter(props: any) {
  const { data } = props;
  const { title, text } = data;
  const [buttonStatus, setButtonStatus] = useState(ButtonStatus.nothing);
  const [textOverflow, setTextOverflow] = useState(false);

  useEffect(() => {
    const currentButtonStatus = isNotEmpty(title) && isNotEmpty(text) ? ButtonStatus.close : ButtonStatus.nothing;
    setButtonStatus(currentButtonStatus);
    funTextOverflow();
  }, [data]);

  Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.detail);

  function handleClick() {
    if (buttonStatus === ButtonStatus.close) {
      setButtonStatus(ButtonStatus.open);
    } else if (buttonStatus === ButtonStatus.open) {
      setButtonStatus(ButtonStatus.close);
    }
  }

  function funTextOverflow() {
    const clientWidth = document.getElementById("textId")?.clientWidth || 0;
    const scrollWidth = document.getElementById("textId")?.scrollWidth || 0;
    setTextOverflow(scrollWidth > clientWidth)
  }

  const contentStyle = {
    paddingRight: textOverflow ? 0 : 10,
  };
  
  return (
    <div onClick={handleClick} style={{...buttonStatus === ButtonStatus.open ? styles.containerOpen : styles.container}}>
      <div style={{...buttonStatus === ButtonStatus.open ? styles.contentOpen : styles.content, ...contentStyle}}>
        <div style={styles.title}>{title}</div>
        <div id="textId" style={buttonStatus === ButtonStatus.open ? styles.textOpen : styles.text } dangerouslySetInnerHTML={{__html:text}}></div>
      </div>
      {
        !textOverflow ? <></> :
        <div style={buttonStatus === ButtonStatus.open ? styles.buttonOpen :styles.button}>{buttonStatus === ButtonStatus.nothing ? '' : buttonStatus === ButtonStatus.close ? '展开' : '收起'}</div>
      }
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    // maxHeight: 80,
    height: 45,
    width: '100%',
    backgroundColor: '#000',
    // opacity: '1',
    color: 'white',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 20,
    position: 'fixed',
    bottom: 0,
    zIndex: 1009,
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    // alignSelf: 'flex-end',
    paddingLeft: 10,
  },
  title: {
    fontSize: 16,
    color: 'white',
    fontWeight: 800,
  },
  text: {
    fontSize: 14,
    color: 'white',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginTop: 5,
  },
  button: {
    alignSelf: 'flex-end',
    color: 'rgb(177 172 172)',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 400,
    marginRight: 10,
    // borderBottomWidth: 2,
    // borderBottomColor: 'white',
    // borderBottomStyle: 'solid',
  },

  /* 展开样式 */
  containerOpen: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: '#000',
    opacity: '0.75',
    color: 'white',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 40,
    paddingRight: 10,
    position: 'fixed',
    bottom: 0,
    zIndex: 1009,
    overflow: 'hidden',
  },
  contentOpen: {
    display: 'flex',
    flex: 1,
    lineHeight: '30px',
    flexDirection: 'column',
    overflow: 'auto',
    alignSelf: 'flex-end',
    marginBottom: 25,
    padding: 15,
  },
  textOpen: {
    fontSize: 14,
    color: 'white',
    marginTop: 5,
  },
  buttonOpen: {
    position: 'absolute',
    right: 30,
    alignSelf: 'flex-end',
    color: 'rgb(177 172 172)',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 400,
  },
};

export default TextFooter;
