/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description: 文字提示3秒关闭，没有按钮。或带icon的提示语。
 *
 */
import { useState, useEffect } from 'react';
import Modal from '../modal/Modal';
import { Color } from '../../constant/Color';

export function Toast(props: any) {
  const [showToast, setShowToast] = useState(false);
  useEffect(() => {
    if (!props.title) return setShowToast(false);

    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }, [props.title]);
  const message =
    props.title instanceof Error ? 
      props.title.message :
      typeof props.title === 'object' ?
        JSON.stringify(props.title) :
        props.title;
  return (
    showToast ?
    <Modal>
      {
        !props.image ?
        <div style={styles.container}>
          <div style={styles.contentContainer}>
            {message}
          </div>
        </div> : 
        <div style={styles.container}>
          <div style={styles.contentContainer}>
            <img src="img/tips/tips.png" alt="" style={styles.image1}/>
            <img src={props.image} alt="" style={styles.image2}/>
            <p style={styles.text}>{props.title}</p>
          </div>
        </div>
      }
    </Modal>
    :
    <></>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  },
  contentContainer: {
    position: 'relative',
    width: 300,
    padding: '40px 10px',
    textAlign: 'center',
    color: 'white',
    fontWeight: 500,
    backgroundColor: "#22272DFF",
    borderRadius: 15,
  },
  image1: {
    width: 65,
    height: 65,
    marginBottom: 10,
  },
  image2: {
    position: 'absolute',
    top: 63,
    left: 141,
    width: 20,
    height: 20,
  }
};

export default Toast;
