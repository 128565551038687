/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import Button from './Button';
import { Color } from '../../constant/Color';

export function BottomButton(props: any) {
  return (
    <div style={styles.container}>
      <Button
        title={props.title}
        image={props.image}
        style={props.style || styles.button}
        styleImage={props.styleImage}
        onClick={props.onClick}
      />
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 60,
    zIndex: 1000,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.orange,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 25,
  },
};

export default BottomButton;
