/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import Square from '../../square/Square';
import { Color } from '../../../constant/Color';
import { SelectedTab, SelectedType } from '../data/dataPicture';

export function SimpleContent(props: any) {
  const { selectedTab, data, ContentHeader, onConfirm, onCancel } = props;
  const [selectedItem, setSelectedItem] = useState({});
  function handleItemClick(item: any) {
    return () => {
      if (item.selected === true) return;

      const newGroupDataList = data.list.map((item: any) => ({...item, selected: false}));
      const targetItem = newGroupDataList.find((it: any) => it.title === item.title);
      targetItem.selected = true;
      data.list = newGroupDataList;

      setSelectedItem(targetItem);
      props.onItemClick && props.onItemClick(targetItem, selectedTab);
      props.updateData && props.updateData(data);
    }
  }

  function handleConfirm() {
    props.onConfirm && props.onConfirm(selectedItem, selectedTab);
  }
  function handleCancel() {
    props.onCancel && props.onCancel(selectedTab);

    setSelectedItem({});
  }
  return (
    <div style={styles.container}>
      <ContentHeader
        selectedItem={selectedItem}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div style={styles.contentContainer}>
        {data.list && data.list.map((item: any) => {
          // 选中效果: 添加边框
          if (data.selectedType === SelectedType.border) {
            const combinedStyle = {...styles.image, ...item.selected ? styles.selectedItem : {}};
            return (
              <Square
                key={item.id || item.title}
                hideTitle={selectedTab === SelectedTab.裁剪}
                imageStyle={combinedStyle}
                image={item.image}
                title={item.title}
                // style={styles.margin}
                onClick={handleItemClick(item)}
              />
            );
          } else if (data.selectedType === SelectedType.changeColor) {
            const combinedStyle = {...styles.image, ...styles.defaultItemColor, ...item.selected ? styles.selectedColor : {}};
            return (
              <Square
                key={item.id || item.title}
                imageStyle={combinedStyle}
                image={item.image}
                title={item.title}
                // style={styles.margin}
                onClick={handleItemClick(item)}
              />
            );
          }

          // 无选中效果
          return (
            <Square
              key={item.id || item.title}
              image={item.image}
              title={item.title}
              // style={styles.margin}
              onClick={handleItemClick(item)}
            />
          );
        })}
      </div>
    </div>
  );
}

const styles: any = {
  container: {

  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    margin: 10,
  },
  selectedItem: {
    border: `3px solid ${Color.orange}`,
  },
  selectedColor: {
    backgroundColor: Color.orange,
  },
  defaultItemColor: {
    backgroundColor: Color.itemBackgroundColor,
  },
  image: {
    width: 50,
    height: 50,
  },
  // margin: {
  //   marginRight: 10,
  // },
};

export default SimpleContent;
