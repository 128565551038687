/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import Modal from './Modal';
import { Color } from '../../constant/Color';

export function Dialog(props: any) {
  return (
    props.show &&
    <Modal>
      <div style={styles.container}>
        <div style={styles.contentContainer}>
          <div style={styles.content}>{props.title}</div>
          <div style={styles.line} />
          <div style={styles.buttonContainer}>
            {props.onCancel && <div style={styles.button} onClick={props.onCancel}>{props.negativeTitle || '取消'}</div>}
            <div style={styles.positiveButton} onClick={props.onConfirm}>{props.positiveTitle || '确定'}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(2px)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1009,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Color.moduleBgColor,
    paddingTop: 30,
    width: '80%',
    borderRadius: 10,
  },
  content: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    color: "#D5D5D5FF",
    fontWeight: '600',
    textAlign: 'center',
  },
  line: {
    height: 1,
    marginTop: 20,
    backgroundColor: Color.line,
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: '#D5D5D5FF',
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
  },
  positiveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: Color.orange,
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
  },
};

export default Dialog;
