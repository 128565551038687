/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export enum Channel {
  official = 'official', // 公众号
  miniProgram = 'miniProgram', // 小程序
  device = 'device', // 设备-景区宣传

  miniProgramType0 = 'miniProgramType0', // 小程序详情 show
  miniProgramType1 = 'miniProgramType1', // 小程序 edit
  miniProgramType2 = 'miniProgramType2', // 小程序 detail
}
