/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { SelectedVideoTab } from '../../data/dataVideo';
import { Strategy } from '../Strategy';
import { DefaultStrategy } from './DefaultStrategy';
import { BGMStrategy } from './BGMStrategy';
import { DynamicStrategy } from './DynamicStrategy';
import { TemplateStrategy } from './TemplateStrategy';

export { Strategy } from '../Strategy';

const strategies: any = {
  [SelectedVideoTab.配乐]: new BGMStrategy(),
  [SelectedVideoTab.动效]: new DynamicStrategy(),
  [SelectedVideoTab.模板]: new TemplateStrategy(),
};

const defaultStrategy = new DefaultStrategy();

/* 通过网络请求获取素材的谓词 */
export const remoteResourcePredicate = [
  SelectedVideoTab.配乐,
  SelectedVideoTab.动效,
  SelectedVideoTab.模板,
];

export function createStrategy(selectedTab: SelectedVideoTab): Strategy<SelectedVideoTab> {
  return strategies[selectedTab] || defaultStrategy;
}
