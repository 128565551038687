/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import Square from '../../square/Square';
import { Color } from '../../../constant/Color';
import { SelectedType, SelectedTab } from '../data/dataPicture';
import Dialog from '../../modal/Dialog';
import { getPanoData, defaultSquareTitles } from '../../panoramic/data';

export function GroupContent(props: any) {
  const { lazySelect, selectedTab, dataList, groupFlag } = props;
  const [selectedItem, setSelectedItem] = useState({} as any);
  const [previousSelectedItem, setPreviousSelectedItem] = useState({} as any);
  const [showDialog, setShowDialog] = useState(false);
  let selectedGroup = dataList.find((item: any) => item.selected === true) || {};

  function handleGroupTitleClick(group: any) {
    return () => {
      if (group.selected === true) return;

      const newDataList = dataList.map((group: any) => ({...group, selected: false}));
      const targetGroup = newDataList.find((g: any) => g.title === group.title);
      targetGroup.selected = true;

      if (selectedTab === SelectedTab.换天) {
        const targetItem = targetGroup.list.find((item: any) => item.value === getPanoData().scene[0].sky);
        if (targetItem) {
          targetItem.selected = true;
          setSelectedItem(targetItem);
        }
      }

      props.onGroupTitleClick && props.onGroupTitleClick(selectedTab, targetGroup);
      props.updateData && props.updateData(newDataList);
    };
  }
  function handleItemClick(item: any) {
    return () => {
      if (item.selected === true) return;

      const flag = item.title !== '原图' && lazySelect;
      if (flag) {
        const prevItem = selectedGroup.list.find((it: any) => it.selected === true);
        // 如果不存在, 说明切换了 Tab
        if (prevItem) setPreviousSelectedItem(prevItem);
        setSelectedItem(item);
        setShowDialog(true);
      }
      const { targetItem, newDataList } = transformDataList(dataList, selectedGroup, item);
      (!flag && props.onItemClick) && props.onItemClick(targetItem, selectedTab);
      props.updateData && props.updateData(newDataList);
    };
  }
  function handleConfirm() {
    const { targetItem, newDataList } = transformDataList(dataList, selectedGroup, selectedItem);
    setShowDialog(false);
    props.onItemClick && props.onItemClick(targetItem, selectedTab);
    props.updateData && props.updateData(newDataList);
  }
  function handleCancel() {
    const { newDataList } = transformDataList(dataList, selectedGroup, previousSelectedItem);
    // console.log(selectedItem);
    // setPreviousSelectedItem(selectedItem);
    // setSelectedItem({});
    setSelectedItem(previousSelectedItem);
    setShowDialog(false);
    props.updateData && props.updateData(newDataList);
  }
  return (
    <div style={styles.container}>
      <Dialog
        show={showDialog}
        title={`确认将当前天空替换为模板${selectedItem.title}吗?`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <div style={!groupFlag ? styles.titleGroup : styles.titleNone}>
        {dataList.map((data: any, index: number) => {
          const combinedStyle = { ...styles.title, ...data.selected ? {} : styles.unSelectedTitle };
          const style = index === 0 ? { ...styles.titleContainer, ...styles.titleMargin, marginLeft: 5,} : { ...styles.titleContainer, ...styles.titleMargin, };
          return (
            <div key={data.title} style={style}>
              <div style={combinedStyle} onClick={handleGroupTitleClick(data)}>{data.title}</div>
            </div>
          );
        })}
      </div>
      <div style={styles.content}>
        {selectedGroup.list && selectedGroup.list.map((item: any) => {
          // 选中效果: 添加边框
          if (selectedGroup.selectedType === SelectedType.border) {
            const combinedStyle = {...styles.image, ...item.selected ? styles.selectedItem : {}};
            return (
              <Square
                key={item.id || item.title}
                imageStyle={combinedStyle}
                image={item.image}
                title={item.title}
                // style={styles.margin}
                onClick={handleItemClick(item)}
              />
            );
          }

          // 无选中效果
          return (
            <Square
              key={item.id || item.title}
              image={item.image}
              title={item.title}
              // style={styles.margin}
              onClick={handleItemClick(item)}
            />
          );
        })}
      </div>
    </div>
  );
}

/**
 * @param dataList 
 * @param selectedGroup 当前选中GroupList
 * @param selectedItem 当前选中特效item {title: "摄像机", image: "img/filter/group0/clarendon.jpeg", selected: true, value: "set_vcr(true);"}
 * @returns 返回当前设置select状态的GroupList
 */
function transformDataList(dataList: any[], selectedGroup: any, selectedItem: any) {
  // 当前选中的selectedItem selected设置成true
  const newGroupDataList = selectedGroup.list.map((item: any) => ({...item, selected: false}));
  const targetItem = newGroupDataList.find((it: any) => (it.id || it.title) === (selectedItem.id || selectedItem.title));
  if (targetItem) targetItem.selected = true;
  selectedGroup.list = newGroupDataList;
  //-- end --//
  // item 效果互斥, 如果叠加的话, updateData 直接返回 dataList 即可.
  // 除了当前选中分组selectedGroup外，其他分组还原选中状态
  const newDataList = dataList.map((group: any) => {
    if (group.title === selectedGroup.title) return selectedGroup;
    if (defaultSquareTitles.includes(selectedItem.title)) {
      return {
        ...group,
        list: group.list.map((it: any) => {
          const config = defaultSquareTitles.includes(it.title) ? { selected: true } : { selected: false };
          return {...it, ...config};
        }),
      };
    }

    return {
      ...group,
      list: group.list.map((it: any) => ({...it, selected: false})),
    };
  });

  return { targetItem, newDataList };
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 10,
    overflow: 'hidden',
  },
  titleNone: {
    display: "none"
  },
  titleGroup: {
    display: 'flex',
    overflow: 'auto',
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
  },
  title: {
    color: Color.orange,
    fontSize: 15,
    whiteSpace: 'nowrap',
  },
  unSelectedTitle: {
    color: Color.grayFont,
    fontSize: 15,
  },
  content: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    // marginTop: 10,
    marginLeft: 10,
    height: 78,
  },
  selectedItem: {
    border: `3px solid ${Color.orange}`,
  },
  image: {
    width: 50,
    height: 50,
  },
  titleMargin: {
    marginRight: 10,
  }
};

export default GroupContent;
