/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';

export function ConfirmContainer(props: any) {
  const cancelImage = props.cancelable ? 'img/button-cancel-highlight.png' : 'img/button-cancel.png';
  return (
    <div style={styles.container}>
      <img src={cancelImage} alt="" style={styles.button} onClick={props.onCancel} />
      <img src="img/button-confirm-highlight.png" alt="" style={styles.button} onClick={props.onConfirm} />
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    padding: 5,
    width: 90,
    height: 35,
  },
}

export default ConfirmContainer;
