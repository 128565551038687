/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { executePanoAction, dynamicLoadPanoXML, reloadPanoVideoXML } from '../../../panoramic/PanoramicView';
import { cruisingAction } from '../../../panoramic/actions';
import { delay } from '../../../../utils/functional';
import { getPanoData } from "../../../panoramic/data";

export function useController(initialState?: any) {

  useEffect(() => {
    // 默认特效
    getPanoData().autorotate = {
      enabled: "true",
      type: '5',
    };
    // 加载pano
    delay(200).then(dynamicLoadPanoXML);
    // delay(10000).then(()=>executePanoAction(cruisingAction(5)));
  }, []);

  return {
  };
}
