/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export const Color = {
  orange: '#FF9100FF',
  gray: 'rgba(45, 45, 45, 1)',
  line: '#4A4B4DFF',

  lightGrayFont: 'rgba(200, 200, 200, 1)',
  grayFont: 'rgba(91, 91, 91, 1)',
  itemBackgroundColor: '#E8E8E8',
  moduleBgColor: '#22272DFF',
  textColor: '#000',
  fontColor: '#fff',
  darkBackground: 'rgba(0, 0, 0, 0.7)',
};
