/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import SizedBox from '../size-box/SizedBox';
import Checkbox from '../checkbox/Checkbox';
import { executePanoAction } from '../panoramic/PanoramicView';
import { onSound3D } from '../panoramic/actions'
import { getQueryString, delay } from "../../utils/functional";
import {
  ACTION_TOGGLE_GYRO_PERMISSION,
  ACTION_SKIN_VIEW_NORMAL,
  ACTION_SKIN_VIEW_FISHEYE,
  ACTION_SKIN_VIEW_LITTLEPLANET,
  ACTION_SKIN_VIEW_STEREOGRAPHIC,
  ACTION_SOUND_PAUSE,
  ACTION_SOUND_PLAY
} from '../panoramic/actions';
import { Radio } from './Radio';
export function RadioGroupVideo(props: any) {
  const { aplus_queue }: any = window;
  const [radioState, setRadioState] = useState({
    normal: false,
    fisheye: false,
    littleplanet: false,
    stereographic: false,
    toggleGyro: false,
  });
  const [checkFlag, setCheckFlag] = useState(false)
  useEffect(() => {
    setRadioState({
      normal: false,
      fisheye: false,
      littleplanet: props.selected,
      stereographic: false,
      toggleGyro: false,
    });
  }, [props]);

  // checkbox
  function handleCheckedChange(check: boolean) {
    executePanoAction(ACTION_TOGGLE_GYRO_PERMISSION);
    uma_data_report('视角切换', '陀螺仪')
  }

  function handleSelectedChange(tag: string) {
    if ((radioState as any)[tag] === true) return;
    onSelectedChange(tag);

    setRadioState({
      normal: false,
      fisheye: false,
      littleplanet: false,
      stereographic: false,
      toggleGyro: false,
      [tag]: true,
    })
  }

  function onSelectedChange(tag: string) {
    if (tag === 'normal') { // 正常
      executePanoAction(ACTION_SKIN_VIEW_NORMAL);
      uma_data_report('视角切换', '正常')
    } else if (tag === 'fisheye') { // 鱼眼
      executePanoAction(ACTION_SKIN_VIEW_FISHEYE);
      uma_data_report('视角切换', '鱼眼')
    } else if (tag === 'littleplanet') { // 小行星
      executePanoAction(ACTION_SKIN_VIEW_LITTLEPLANET);
      uma_data_report('视角切换', '小行星')
    } else if (tag === 'stereographic') { // 立体
      executePanoAction(ACTION_SKIN_VIEW_STEREOGRAPHIC);
      uma_data_report('视角切换', '立体')
    }
  }

  // 开启/关闭全景声
  function handleSound(check: boolean) {
    uma_data_report('声音开关', '声音')
    if (check) {
      if (checkFlag) executePanoAction(ACTION_SOUND_PLAY);
      else {
        delay(500).then(onSound3D);
        setCheckFlag(true)
      }
    } else {
      console.log('false ---');
      executePanoAction(ACTION_SOUND_PAUSE);
    }
  }

  // 友盟数据上报
  function uma_data_report(type: string, title?: string) {
    aplus_queue.push({
      action: "aplus.record",
      arguments: ['video_click', 'CLK', {
        'type': type,
        'title': title ? title : ''
      }],
    });
  }

  return (
    <div style={styles.container}>
      {/* icon修改之前的正常  */}
      {/* <Radio
        text="正常"
        selected={radioState.normal}
        tag="normal"
        icon="img/miniProgramIcon/ic-normal-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-normal.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} /> */}
      <Checkbox
        text="声音"
        style={styles.soundIcon}
        // 选中
        icon="img/miniProgramIcon/icon_sound_play.png"
        // 未选中
        highlightIcon="img/miniProgramIcon/icon_sound_pause.png"
        onCheckedChange={handleSound}
      />
      <SizedBox style={styles.sizedBox} />
      {/* icon修改之后的icon 图标是鱼眼的 */}
      <Radio
        text="正常"
        selected={radioState.normal}
        tag="normal"
        icon="img/miniProgramIcon/ic-fisheye-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-fisheye.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} />
      {/* <Radio
        text="鱼眼"
        selected={radioState.fisheye}
        tag="fisheye"
        icon="img/miniProgramIcon/ic-fisheye-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-fisheye.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} /> */}
      <Radio
        text="星球"
        tag="littleplanet"
        selected={radioState.littleplanet}
        icon="img/miniProgramIcon/ic-smallplanet-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-smallplanet.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} />
      <Radio
        text="立体"
        tag="stereographic"
        selected={radioState.stereographic}
        icon="img/radio/ic-stereographic-unchecked.png"
        highlightIcon="img/radio/ic-stereographic.png"
        onSelectedChange={handleSelectedChange}
      />
      <SizedBox style={styles.sizedBox} />
      <Checkbox
        text="陀螺仪"
        icon="img/miniProgramIcon/ic-gyro-unchecked.png"
        highlightIcon="img/miniProgramIcon/ic-gyro.png"
        onCheckedChange={handleCheckedChange}
      />
    </div>
  );
}

const styles: any = {
  container: {
    textAlign: "center"
  },
  sizedBox: {
    height: 5,
  },
};

export default RadioGroupVideo;
