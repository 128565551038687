/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { FloatButtonContent, GridContent, GroupContent, SimpleContent, GroupNewContent, GridCheckboxContent } from './content/index';
import ConfirmContainer from '../confirm/ConfirmContainer';
import Empty from '../empty/Empty';
import { SelectedTab } from './data/dataPicture';
import { resetPanoConfigWithSelectedTab, getPanoData } from '../panoramic/data';
import { delay } from '../../utils/functional';
import { createStrategy, remoteResourcePredicate, } from './strategy/panoPicture/index';

export function ContentAdapter(props: any) {
  const { tipControl, panoConfig, selectedTab, dataTree: originDataTree, onChangeText, onConfirm, onCancel, onItemClick } = props;
  const [dataTree, setDataTree] = useState(originDataTree);
  const [hasResetPanoConfig, changeResetPanoConfigFlag] = useState(false);
  // data 可能是对象, 可能是数组
  const data = dataTree[selectedTab];
  const strategy = createStrategy(selectedTab);

  useEffect(() => {
    if ((data && data.length && data.length > 0) || !remoteResourcePredicate.includes(selectedTab)) {
      const { cruisingObj, filterObjList } = resetPanoConfigWithSelectedTab(dataTree, [], selectedTab);
      setDataTree({
        ...dataTree,
        [SelectedTab.巡航]: cruisingObj,
        [SelectedTab.滤镜]: filterObjList,
        // [selectedTab]: target,
      });
      return;
    }

    // 获取远程素材数据
    strategy.fetchData({ tipControl }).then(async (newData: any) => {
      if (!newData) return;

      // // 已经重置过 panoConfig, 直接更新数组
      // if (hasResetPanoConfig) return setDataTree({ ...dataTree, [selectedTab]: newData, });

      // 未重置过 panoConfig, 等待 500ms (更新 panoConfig 后), 在重置数组
      if (!hasResetPanoConfig) {
        tipControl.showLoading();
        await delay();
      }
      const { target, cruisingObj, filterObjList } = resetPanoConfigWithSelectedTab(dataTree, newData, selectedTab);

      setDataTree({
        ...dataTree,
        [SelectedTab.巡航]: cruisingObj,
        [SelectedTab.滤镜]: filterObjList,
        [selectedTab]: target,
      });
      if (!hasResetPanoConfig) {
        changeResetPanoConfigFlag(true);
        tipControl.dismissLoading();
      }
    });
  }, [selectedTab]);

  async function handleGroupTitleClick(tag: SelectedTab, group: any) {
    if (remoteResourcePredicate.includes(selectedTab)) {
      // 组装数据, 更新 dataTree
      const newList = await strategy.fetchDataWithGroup({list: dataTree[selectedTab], group, tipControl, });
      if (!newList) return;

      updateData(newList);
    } else {
      props.onGroupTitleClick && props.onGroupTitleClick(tag, group);
    }
  }
  function updateData(list: any) {
    setDataTree({...dataTree, [selectedTab]: list});
  }
  console.log(dataTree);

  switch (selectedTab) {
    case SelectedTab.边框:
      return (
        <GridCheckboxContent
          dataList={data}
          selectedTab={selectedTab}
          updateData={updateData}
          headerControlBySelf={false}
          ContentHeader={({ selectedItem, onConfirm, onCancel }: any) => (
            <ConfirmContainer
              cancelable={true}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
          groupFlag={data.length === 1 && data[0].title === '未分组'}
          onItemClick={onItemClick}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      );
    case SelectedTab.贴纸:
      return (
        <GridCheckboxContent
          dataList={data}
          selectedTab={selectedTab}
          updateData={updateData}
          ContentHeader={Empty}
          groupFlag={data.length === 1 && data[0].title === '未分组'}
          onItemClick={onItemClick}
        />
      );
    case SelectedTab.标签:
      return (
        <FloatButtonContent
          data={data}
          selectedTab={selectedTab}
          onChangeText={onChangeText}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      );
    case SelectedTab.裁剪:
      return (
        <SimpleContent
          data={data}
          selectedTab={selectedTab}
          ContentHeader={({ selectedItem, onConfirm, onCancel }: any) => (
            <ConfirmContainer
              cancelable={false}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
          updateData={updateData}
          onItemClick={onItemClick}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      );
    case SelectedTab.巡航:
      return (
        <GroupNewContent
          dataList={data}
          selectedTab={selectedTab}
          ContentHeader={Empty}
          updateData={updateData}
          onItemClick={onItemClick}
        />
      );
    case SelectedTab.配乐:
      return (
        <GroupNewContent
          dataList={data}
          selectedTab={selectedTab}
          updateData={updateData}
          onGroupTitleClick={handleGroupTitleClick}
          onItemClick={onItemClick}
        />
      );
    case SelectedTab.换天:
      return (
        <GroupNewContent
          lazySelect={true}
          dataList={data}
          selectedTab={selectedTab}
          updateData={updateData}
          onGroupTitleClick={handleGroupTitleClick}
          onItemClick={onItemClick}
        />
      );
    case SelectedTab.模板:
    case SelectedTab.动效:
    case SelectedTab.滤镜:
    default:
      return (
        <GroupNewContent
          dataList={data}
          selectedTab={selectedTab}
          groupFlag={data.length === 1 && data[0].title === '未分组'}
          updateData={updateData}
          onGroupTitleClick={handleGroupTitleClick}
          onItemClick={onItemClick}
        />
      );
  }
}

export default ContentAdapter;
