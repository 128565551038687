/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import './index.css';
import { useState } from 'react';
import ConfirmContainer from '../../confirm/ConfirmContainer';
import { Color } from '../../../constant/Color';

export function FloatButtonContent(props: any) {
  const [buttonVisible, setButtonVisible] = useState(true);
  const [text, setText] = useState('');

  function handleClick() {
    props.onClick && props.onClick(props.selectedTab);

    setButtonVisible(false);
  }
  function handleTextChange(e: any) {
    const value = e.target.value;
    setText(value);
    props.onChangeText && props.onChangeText(value, props.data.list[0]);
  }
  function handleConfirm() {
    props.onConfirm && props.onConfirm(text, props.selectedTab, props.data.list[0]);

    setButtonVisible(true);
  }
  function handleCancel() {
    props.onCancel && props.onCancel(props.selectedTab);

    setButtonVisible(true);
  }
  return (
    <div style={styles.inputLayout}>
      <input
        className="edit-text-textarea"
        type="text"
        placeholder="点击输入文字"
        style={styles.input}
        onChange={handleTextChange}
      />
    </div>
    // <>
    // {
    //   buttonVisible ?
    //   <div style={styles.floatContainer} onClick={handleClick}>
    //     <img src="img/ic-text-sticker.png" alt="" style={styles.textStickerImage} />
    //   </div> : 
    //   <div style={styles.inputLayout}>
    //     <ConfirmContainer
    //        cancelable={true}
    //        onConfirm={handleConfirm}
    //        onCancel={handleCancel}
    //     />
    //     <input
    //       className="edit-text-textarea"
    //       type="text"
    //       placeholder="点击输入文字"
    //       style={styles.input}
    //       onChange={handleTextChange}
    //     />
    //   </div>
    // }
    // </>
  );
}
const styles: any = {
  floatContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 110,
    zIndex: 1000,
  },
  textStickerImage: {
    width: 50,
    height: 50,
  },
  inputLayout: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // backgroundColor: 'white',
    // position: 'absolute',
    // left: 0,
    // bottom: 0,
    // right: 0,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 25,
    zIndex: 1003,
  },
  input: {
    // -webkit-user-select: text !important;
    fontSize: 15,
    padding: 10,
    borderRadius: 10,
    border: '1px solid #24282DFF',
    marginTop: 10,
    background: '#24282DFF',
    color: '#BCBEC4FF',
  }
};

export default FloatButtonContent;
