/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import { Color } from '../../constant/Color';

export function Button(props: any) {
  return (
    <div style={props.style || styles.container} onClick={props.onClick}>
      <img src={props.image} alt="" style={props.styleImage || styles.image} />
      <div style={styles.text}>{props.title}</div>
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.orange,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    position: 'absolute',
    left: '40%',
    right: '40%',
    bottom: 30,
    borderRadius: 25,
    zIndex: 1000,
  },
  image: {
    width: 15,
    height: 15,
  },
  text: {
    marginLeft: 5,
    color: Color.fontColor,
  },
};

export default Button;
