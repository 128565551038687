/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import { Color } from '../../constant/Color';

export function Square(props: any) {
  const containerStyle = props.style ? {...styles.container, ...props.style} : styles.container;
  return (
    <div style={containerStyle} onClick={props.onClick}>
      <img src={props.image} alt="" style={props.imageStyle || styles.image}/>
      {!props.hideTitle && <div className="square-text" style={props.textStyle || styles.text}>{props.title}</div>}
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 5,
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 6,
  },
  text: {
    fontSize: 12,
    color: Color.fontColor,
    marginTop: 5,
    width: 65,
    textAlign: "center",
    // lineHeight: '14px',
  },
};

export default Square;
