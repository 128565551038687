/**
 * Created by zhang.zhongdong
 * https://github.com/maybewaityou
 *
 * description: 满屏遮罩，中间文字提示
 */
import { useState, useEffect } from 'react';
import Modal from '../modal/Modal';

export function FullTips(props: any) {
  return (
    props.title &&
    <Modal>
      <div style={styles.container}>
        <div style={styles.contentContainer}>
          {
            props.title
          }
        </div>
      </div>
    </Modal>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(3px)',
    zIndex: 9999,
  },
  contentContainer: {
    position: 'relative',
    width: 300,
    padding: '40px 0',
    textAlign: 'center',
    color: 'white',
    fontWeight: 500,
    // backgroundColor: "#22272DFF",
    // borderRadius: '15px 15px 0 0',
  },
};

export default FullTips;