/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';

export function Checkbox(props: any) {
  const style = props.style || styles.icon;
  const [icon, setIcon] = useState(props.icon);
  function handleClick() {
    props.onCheckedChange && props.onCheckedChange(icon === props.icon);

    if (icon === props.icon) setIcon(props.highlightIcon);
    else setIcon(props.icon);
  }
  return (
    <div style={styles.container} onClick={handleClick}>
      <img src={icon} alt="" style={styles.icon} />
      <div style={styles.text}>{props.text}</div>
    </div>
  );
}

const styles: any = {
  container: {
    textAlign: "center",
    justAlgin: 'center',
  },
  icon: {
    width: 38,
    height: 38,
    filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3))',
  },
  text: {
    // margin: "5px 0",
    marginBottom: 5,
    color: "#fff",
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: "center",
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
  },
};

export default Checkbox;
