/**
 * 小程序 Footer
 */
 import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { panoSize } from '../panoramic/data'

 
 export function Footer(props: any) {

  Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, props.footerHeight || panoSize.imageButton);

  return (
    <div style={{...styles.container, ...props.style}}>
      {
        props.children
      }
    </div>
  );
 }

 const styles: any = {
  container: {
    display: 'flex',
    height: 45,
    width: '100%',
    backgroundColor: '#000',
    paddingBottom: 20,
    position: 'fixed',
    bottom: 0,
    zIndex: 1009,
    overflow: 'hidden',
  }
 };
 
 export default Footer;
 