/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import { Base64 } from 'js-base64';
import { SelectedVideoTab } from '../../tab/data/dataVideo';
import { executePanoAction, getDataFromPano, reloadPanoVideoXML } from '../../panoramic/PanoramicView';
import { getPanoData, setPanoData, getResetData, removeTemplateData, setResetData, panoSize } from '../../panoramic/data';
import {
  ACTION_SCREENSHOT,
  startBGM,
  stopBGM,
  getScreenshotURL,
  visualAction,
  filterAction,
  hideVisualButton,
  dynamicVideoAction,
  dynamicVideoCancel,
} from '../../panoramic/actions';
import { fetchBackgroundMusicURL, savePicture, fetchMaterialUsageUpdate, fetchModalUsedUpdate } from '../../../api/NetworkApi';
import {
  delay,
  getQueryString,
  getTitle,
  replaceUrl,
} from '../../../utils/functional';
import { convertBase64UrlToBlob, getPanoCroppedFile } from '../../../utils/snapshot';
import { Wormhole, Destination } from '../../../vendor/wormhole/index';
import { templateLoad } from '../../float/mini-program/strategy/panovideo/templateVideoLoad';

const tabModel = {
  selectedTab: '',
};
export function useController(params?: any, initialState?: any) {
  const { panoConfig, onBGMItemClick, tipControl } = params;
  const { initSelectedTab } = initialState;
  const [selectedTab, setSelectedTab] = useState(tabModel.selectedTab || initSelectedTab);
  function handleTabClick(tag: SelectedVideoTab) {
    setSelectedTab(tag);
  }

  function handleClick(tag: SelectedVideoTab) {
    console.log(tag);
  }

  async function handleGroupTitleClick(tag: SelectedVideoTab, group: any) {
    console.log(group);
  }

  // 拍照推送视频，使用模板点击方法
  async function handleVideoPushTemplate(item: any = {}) {
    if (item.value) {
      templateLoad(item, params);
    } else {
      setPanoData(removeTemplateData('video'));
      executePanoAction(stopBGM());
      reloadPanoVideoXML();
    }
  }

  async function handleItemClick(item: any, tag: SelectedVideoTab) {
    // TODO: 保存选中信息
    // debugger
    console.log(item, 'item.value');
    switch (tag) {
      case SelectedVideoTab.模板:
        if (item.value) {
          templateLoad(item, params);

        } else {
          setPanoData(removeTemplateData('video'));
          executePanoAction(stopBGM());
          reloadPanoVideoXML();
        }
        delay(500).then(() => executePanoAction(hideVisualButton()));
        return;
      case SelectedVideoTab.动效:
        if (!item) {
          hide();
        } else if (item.selected) {
          executePanoAction(dynamicVideoAction(item));
        } else {
          executePanoAction(dynamicVideoCancel(item));
        }
        console.log(getResetData(), 'getResetData()----')
        console.log(getPanoData(), 'getPanoData()-----');
        return;
      case SelectedVideoTab.滤镜:
        if (!item) {
          executePanoAction(filterAction(0));
        } else {
          executePanoAction(filterAction(item.value));
        }
        return;
      case SelectedVideoTab.配乐:
        if (!item) {
          onBGMItemClick(item);
          // getPanoData().music = '';
          getPanoData().bgmItem = {};
          return executePanoAction(stopBGM());
        }

        const [error, result] = await fetchBackgroundMusicURL(item.value);
        if (error) return tipControl.showToast(error);

        const resultData = JSON.parse(Base64.decode(result.data));
        const url = resultData.data.url;
        // getPanoData().music = url;
        const extraItem = { ...item, url };
        getPanoData().jsonData.bgmItem = extraItem;
        executePanoAction(startBGM(url));

        onBGMItemClick(extraItem);
        return;
      case SelectedVideoTab.速剪:
        executePanoAction(visualAction(item.value || 0));
        return;
      default:
        return;
    }
  }

  // 特效选中确定
  function onConfirm(tag: SelectedVideoTab) {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.tabButton);
    setSelectedTab('OTHERS');
    switch (tag) {
      case SelectedVideoTab.模板:
        setResetData(getPanoData().jsonData);
        return;
      case SelectedVideoTab.动效:
        setResetData(getPanoData().jsonData);
        console.log(getResetData(), 'getResetData()----')
        console.log(getPanoData(), 'getPanoData()-----');
        return;
      case SelectedVideoTab.滤镜:
        setResetData(getPanoData().jsonData);

        return;
      case SelectedVideoTab.配乐:
        setResetData(getPanoData().jsonData);

        return;
      case SelectedVideoTab.速剪:
        setResetData(getPanoData().jsonData);
        return;
      default:
        return;
    }

  }

  // 特效取消“X”
  function onCancel(tag: SelectedVideoTab) {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.tabButton);
    setSelectedTab('OTHERS');
    console.log(getResetData(), 'getResetData()');
    switch (tag) {
      case SelectedVideoTab.模板:
        setPanoData(getResetData());
        executePanoAction(stopBGM()); // 暂停音乐
        reloadPanoVideoXML();
        executePanoAction(hideVisualButton());
        return;
      case SelectedVideoTab.动效:
        // setPanoData(getResetData());
        // reloadPanoVideoXML();

        // topimg
        const panoData: any = getResetData();
        const topimgItem = {
          type: 'topimg',
          value: {
            id: panoData.topimg.id,
            imageurl: panoData.topimg.url,
            framerate: panoData.topimg.framerate || 0,
          }
        }
        executePanoAction(dynamicVideoAction(topimgItem));

        // crop-start
        const cropStartItem = {
          type: 'crop',
          value: {
            imageurl: panoData.crop_intro.start.url,
            framerate: panoData.crop_intro.start.framerate,
            startOrEnd: 'start',
          }
        }
        executePanoAction(dynamicVideoAction(cropStartItem));

        // crop-end
        const cropEndItem = {
          type: 'crop',
          value: {
            imageurl: panoData.crop_intro.end.url,
            framerate: panoData.crop_intro.end.framerate,
            startOrEnd: 'end',
          }
        }
        executePanoAction(dynamicVideoAction(cropEndItem));

        // intro
        executePanoAction(`set_intro(${panoData.intro.v}, ${panoData.intro.h}, ${panoData.intro.ve}, ${panoData.intro.he});`);

        // effect
        Object.keys(panoData.effect).forEach((key: any) => {
          const obj = panoData.effect;
          if (obj[key]) {
            if (key === 'vcr') {
              executePanoAction('set_vcr(true);');
            }
            if (key === 'random') {
              executePanoAction('effect_random_action(true);');
            }
            if (key === 'fblur') {
              executePanoAction('set_fblur(true);');
            }
            if (key === 'fsplit') {
              executePanoAction('fsplit(true);');
            }
            if (key === 'stereo') {
              executePanoAction('effect_stereo_action(true);');
            }
          } else {
            if (key === 'vcr') {
              executePanoAction('set_vcr(false);');
            }
            if (key === 'random') {
              executePanoAction('effect_random_action(false);');
            }
            if (key === 'fblur') {
              executePanoAction('set_fblur(false);');
            }
            if (key === 'fsplit') {
              executePanoAction('fsplit(false);');
            }
            if (key === 'stereo') {
              executePanoAction('effect_stereo_action(false);');
            }
          }
        })
        // hide();
        return;
      case SelectedVideoTab.滤镜:
        executePanoAction(filterAction(getResetData().zfilter.filter));
        return;
      case SelectedVideoTab.配乐:
        // onBGMItemClick(item);
        // getPanoData().jsonData.music = '';
        const bgmItem = getResetData().bgmItem;
        getPanoData().jsonData.bgmItem = { ...bgmItem };
        if (bgmItem.url) {
          executePanoAction(startBGM(bgmItem.url));
        } else {
          executePanoAction(stopBGM());
        }
        onBGMItemClick(bgmItem);
        return
      case SelectedVideoTab.速剪:
        executePanoAction(visualAction(getResetData().motion));
        // setPanoData(getResetData());
        return;
      default:
        return;
    }
  }

  function hide() {
    // 随机动画
    executePanoAction('effect_random_action(false)');
    // 摄像机VCR
    executePanoAction('set_vcr(false)');
    // 分屏
    executePanoAction('effect_stereo_action(false)');
    // 快速模糊
    executePanoAction('set_fblur(false)');
    // 色调分离
    executePanoAction('set_fsplit(false)');
    // 横开屏/竖开屏幕
    executePanoAction('set_intro(false, false, false, false)');
    // corp动画
    executePanoAction('set_crop_intro("", "", start)');
    executePanoAction('set_crop_intro("", "", end)');
    // 自然、光影
    executePanoAction('hide_topimg()');
  }

  // async function handleSave(reLaunchType: String = "") {
  //   // console.log(getResetData(), 'getResetData()----')
  //   // console.log(getPanoData(), 'getPanoData()-----');
  //   const id = getQueryString('orderId');
  //   const typeCode = getQueryString('typeCode');
  //   const userWorksId = getQueryString('userWorksId');
  //   const title = getTitle();
  //   // return;
  //   executePanoAction(ACTION_SCREENSHOT);
  //   await delay();
  //   const imageBase64Data = getDataFromPano(getScreenshotURL());
  //   const blob = await convertBase64UrlToBlob(imageBase64Data, 'image/jpeg');
  //   const thumbnailFile = await getPanoCroppedFile(blob);
  //   const params = {
  //     userProductId: id,
  //     userWorksName: title,
  //     typeCode: typeCode || '03', // 01 全景图，02 普通图，03 视频
  //     userWorksId,
  //     data: Base64.encode(JSON.stringify(getPanoData().jsonData)),
  //     thumbnailFile,
  //   };
  //   // console.log(params);
  //   // console.log(getPanoData());
  //   // console.log(Base64.encode(JSON.stringify(getPanoData())));

  //   tipControl.showLoading();
  //   const [error, result] = await savePicture(params);
  //   tipControl.dismissLoading();
  //   if (error) {
  //     // 推送照片转发给其他用户，不报错直接进入小七全景
  //     if (result && result.ret_code === 'B105000001004' && reLaunchType === 'save') {
  //       // 跳转到原片页面
  //       (window as any).wx.miniProgram.reLaunch({
  //         url: `/pages/index/index?page=page2`,
  //       });
  //       return;
  //     } else {
  //       return tipControl.showToast(error);
  //     }
  //   } 

  //   const thumbnailUrl = (result && result.thumbnail_url) || '';
  //   const url = replaceUrl('data', encodeURIComponent(JSON.stringify(getPanoData().jsonData)));
  //   const path = getQueryString('initvars.mypath');
  //   const stringifyUrl = JSON.stringify(`${url}&mypath=${path}&thumbnailUrl=${thumbnailUrl}`);

  //   // 模板使用量
  //   const templateId = getPanoData().jsonData.template.value;
  //   if (templateId) {
  //     fetchModalUsedUpdate(templateId);
  //   }

  //   //更新素材使用量
  //   tipControl.showLoading();
  //   await fetchMaterialUsageUpdate(getPanoData().jsonData);
  //   tipControl.dismissLoading();
  //   if (reLaunchType === 'save') {
  //     // 跳转到原片页面
  //     (window as any).wx.miniProgram.reLaunch({
  //       url: `/pages/index/index?page=page2`,
  //     });
  //   } else {
  //     // 跳转到保存成功界面
  //     (window as any).wx.miniProgram.reLaunch({
  //       url: `/pagesA/meitu/save?type=03&url=${stringifyUrl}`,
  //     });
  //   }
  // }

  return {
    panoConfig,
    tipControl,
    selectedTab,
    handleTabClick,
    handleClick,
    // handleConfirm,
    // handleCancel,
    handleGroupTitleClick,
    handleItemClick,
    // handleSave,
    onConfirm,
    onCancel,
    handleVideoPushTemplate,
  };
}
