/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 小程序 "玩图" Tab Footer
 */
import ContentAdapter from '../../tab/ContentAdapter';
import TabContainer from '../../tab/TabContainer';
import { Color } from '../../../constant/Color';
import { dataTree, SelectedTab } from '../../tab/data/dataPicture';
import { getQueryString } from '../../../utils/functional';
import { tabMap } from '../../tab/data/dataPicture';
import { useController } from '../controller/TabFooterPicture';
import { useController as footerViewController } from '../controller/FooterViewController';
import { tabs } from '../../tab/data/dataPicture';
import { Wormhole, Destination } from '../../../vendor/wormhole/index';
import { panoSize } from '../../panoramic/data';
import ConfirmSpecialEffects from '../../confirm/ConfirmSpecialEffects';


export function TabFooter(props: any) {
  const editType = getQueryString('editType');
  const initSelectedTab = tabMap[editType];

  const {
    panoConfig,
    selectedTab,
    hideTabFooter,

    tipControl,

    handleTabClick,
    handleGroupTitleClick,
    handleItemClick,
    // handleSave,
    handleChangeText,
    onCancel,
    onConfirm,
  } = useController(props, { initSelectedTab });

  const { handleSave } = footerViewController(props);

  if (selectedTab === 'OTHERS') {
    // 设置 pano
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.tabButton);
    // props.setMenuHeight(panoSize.tabButton)
  } else if (selectedTab === '边框') {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.effect);
    // props.setMenuHeight(panoSize.effect)
  } else if (selectedTab === '标签') {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, false);
    // props.setMenuHeight(0)
  } else {
    Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.content);
    // props.setMenuHeight(panoSize.content)
  }

  return (
    <div style={styles.container}>
      {
        selectedTab !== 'OTHERS' ?
          <>
            <ConfirmSpecialEffects
              // type={selectedTab === '贴纸'}
              title={selectedTab}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
            <div style={styles.line}></div>
            <ContentAdapter
              dataTree={dataTree}
              tipControl={tipControl}
              panoConfig={panoConfig}
              selectedTab={selectedTab}
              onGroupTitleClick={handleGroupTitleClick}
              onItemClick={handleItemClick}
              onChangeText={handleChangeText}
            />
          </> :
          <>
            <TabContainer
              selectedTab={selectedTab}
              tabs={tabs}
              onTabClick={handleTabClick}
              onSave={handleSave}
            />
          </>
      }
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    backgroundColor: '#0C0D0FFF',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1004,
  },
  line: {
    height: 1,
    backgroundColor: Color.line,
  },
};

export default TabFooter;
