/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

import {
  fetchTitle,
  fetchDescription,
  fetchImagePath,
  fetchSharedBasicInfo,
} from '../api/ShareApi';

class ShareManager {
  public async init(orderNo: string) {

    const [titleError, title] = await fetchTitle();
    if (titleError) return;

    const [descriptionError, description] = await fetchDescription();
    if (descriptionError) return;

    const [imagePathError, imagePath] = await fetchImagePath(orderNo);
    if (imagePathError) return;

    const [dataError, data] = await fetchSharedBasicInfo();
    if (dataError) return;
    const { wx } = (window as any);

    wx.config({
      debug: false,
      appId: data.app_id, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonce_str, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名，见附录1
      jsApiList: [
        'checkJsApi',
        'updateTimelineShareData',
        'updateAppMessageShareData',
      ], //必填，需要使用的JS接口列表，所有JS接口列表见附录2
      openTagList: [
        'wx-open-launch-weapp',
      ] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
    });

    wx.error((res: any) => {
      alert(JSON.stringify(res));
    });

    wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
        success: ({ checkResult }: any) => {
          processShare(checkResult, { title, description, imagePath });
        },
      });
    });
  }
}



export const shareManager = new ShareManager();

function processShare(checkResult: any, { title, description, imagePath }: any) {
  const { wx } = (window as any);
  const shareData = {
    title: description, // 分享标题
    desc: '详情请点开', // 分享描述
    link: window.location.href,
    imgUrl: imagePath, // 分享图标
  };
  if (checkResult.updateTimelineShareData) {
    wx.updateTimelineShareData({
      ...shareData,
      success: () => { },
      cancel: () => { },
    });
  }
  if (checkResult.updateAppMessageShareData) {
    wx.updateAppMessageShareData({
      ...shareData,
      success: () => { },
      cancel: () => { },
    });
  }
}
