/**
 * description:
 * 小程序 "推送" Footer
 */

import { useState, useEffect } from "react";
import { Wormhole, Destination } from "../../vendor/wormhole/index";
import { useController as videoController } from "./controller/TabFooterVideo";
import { useController as pictureController } from "./controller/TabFooterPicture";
import { panoSize } from '../panoramic/data'
import { processURL } from "../../utils/data";
import CauseFooter from "./widgets/CauseFooter";
import { useController as footerViewController } from "./controller/FooterViewController";
import { Color } from "../../constant/Color";
enum ButtonStatus {
  open = 1,
  close,
  nothing,
}

export function PushFooter(props: any) {
  const { data, tipControl, orderData, showShotCause, hideCause, setMenuHeight } = props;
  const { handleVideoPushTemplate } = videoController(props, {});
  const { handlePicPushTemplate } = pictureController(props, {});
  const { onPhotograph, handleSave } = footerViewController(props);
  const [buttonStatus, setButtonStatus] = useState(ButtonStatus.close);
  const [getIndex, setIndex] = useState();
  Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.push);
  setMenuHeight(panoSize.push);
  function handleClick(item: any, index: any) {
    if (getIndex === index) {
      // 点击本身
      if (buttonStatus === ButtonStatus.close) {
        openModal(item);
      } else if (buttonStatus === ButtonStatus.open) {
        closeModal(item);
      }
    } else {
      openModal(item);
    }
    setIndex(index);
  }

  // open 模板
  function openModal(item: any) {
    // value模版id，groupId模版所在分组id
    const template = {
      value: item.id,
      groupId: item.category_list ? item.category_list[0].id : "",
    };
    console.log(template);
    setButtonStatus(ButtonStatus.open);
    if (item.type === '04') { // 04照片模版，05视频模版
      handlePicPushTemplate(template);
    } else {
      handleVideoPushTemplate(template);
    }
  }

  // close 模板
  function closeModal(item: any) {
    setButtonStatus(ButtonStatus.close);
    if (item.type === '04') { // 04照片模版，05视频模版
      handlePicPushTemplate();
    } else {
      handleVideoPushTemplate();
    }
  }

  // 进入小七全景
  function toMiniProgram() {
    if (buttonStatus === ButtonStatus.open) {
      handleSave("save");
    } else {
      // 跳转到原片页面
      (window as any).wx.miniProgram.reLaunch({
        url: `/pages/index/index?page=page2`,
      });
    }
  }

  // 修片后获取图片url
  function templateFunAfter(item: any) {
    if (item.type === "04" || item.type === "05") {
      const list = item.resource_list.filter((it: any) => it.push_url);
      const list2 = item.resource_list.filter((it: any) => it.thumbnail_url);
      return processURL(list[0]
        ? list[0].push_url
        : list2[0]
        ? list2[0].thumbnail_url
        : "");
    }
  };

  return (
    <div>
      <div style={styles.container}>
        <div style={styles.modalContent}>
          {data.map((item: any, index: any) => (
            <div style={styles.imageGroup} key={item.id}>
              <img
                onClick={() => handleClick(item, index)}
                src={templateFunAfter(item)}
                style={
                  getIndex === index
                    ? buttonStatus === ButtonStatus.close
                      ? styles.image
                      : styles.imageSelected
                    : styles.image
                }
                alt=""
              />
              <div style={styles.text}>{item.template_name}</div>
            </div>
          ))}
          {/* <span style={styles.modalText}>{buttonStatus === ButtonStatus.close ? "使用模版" : "取消模版"}</span> */}
        </div>
        <div style={styles.topContent}>
          <div style={styles.topText}>
            <img style={styles.topImg} src="/img/icon/arrow.png" alt=""></img>
            <span>点击试试模版吧</span>
          </div>
          <div style={styles.button} onClick={toMiniProgram}>
            <div style={styles.buttonTextBox}>
              <p style={styles.buttonText}>小7全景</p>
              <img style={styles.arrow} src="/img/icon/arrow-right.png" alt=""/>
            </div>
            <div style={styles.buttonTip}>解锁更多精彩</div>
          </div>
        </div>
      </div>
      {
         showShotCause &&
         <CauseFooter
           title="重拍原因"
           tips="对拍摄效果不满意，10分钟内可选择重拍"
           orderData={orderData}
           tipControl={tipControl}
           cancel={hideCause}
           onClick={onPhotograph}
         />
       }
    </div>
  );
}

const styles: any = {
  container: {
    //  display: 'flex',
    // height: 120,
    width: "100%",
    backgroundColor: "#0C0D0FFF",
    //  justifyContent: 'space-around',
    //  justifyContent: 'space-between',
    //  alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 10,
    position: "fixed",
    bottom: 0,
    zIndex: 1009,
    overflow: "hidden",
  },
  topContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // borderBottom: "1px solid rgba(80, 80, 80, 1)",
    marginTop: 7,
    backgroundColor: "#181B1FFF",
  },
  topText: {
    fontSize: 14,
    fontWeight: '500',
    color: Color.fontColor,
    width: '78%',
    textAlign: 'center',
  },
  topImg: {
    width: 10,
    height: 10,
    verticalAlign: "middle",
    marginRight: 5,
  },
  modalContent: {
    display: "flex",
    // alignItems: "center",
    overflow: "scroll",
    minHeight: 72,
  },
  modalText: {
    fontSize: 14,
    color: "white",
    marginLeft: 10,
  },
  image: {
    width: 50,
    height: 50,
    objectFit: "cover",
    borderRadius: 5,
  },
  imageSelected: {
    width: 50,
    height: 50,
    objectFit: "cover",
    borderRadius: 5,
    border: "3px solid #FF9100FF",
  },
  button: {
    height: 46,
    lineHeight: "5px",
    // paddingLeft: 15,
    // paddingRight: 15,
    backgroundColor: Color.orange,
    minWidth: 93,
    textAlign: 'center',
    // borderRadius: 20,
    // marginRight: 30,
  },
  buttonText: {
    fontSize: 12,
    fontWeight: 500,
    color: Color.fontColor,
  },
  buttonTip: {
    fontSize: 12,
    fontWeight: 500,
    color: Color.fontColor,
  },
  text: {
    fontSize: 12,
    color: Color.fontColor,
    marginTop: 4,
    lineHeight: '14px',
  },
  buttonTextBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrow: {
    width: 10,
    height: 10,
  },
  imageGroup: {
    width: 65,
    textAlign: 'center',
    marginLeft: 10,
  }
};

export default PushFooter;
