/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 模板展示
 */
import { RenderStrategy, RenderType } from './RenderStrategy';
import {
  getQueryString,
  panoPicture,
  panoVideo,
} from '../../../../utils/functional';
import { templatePictureLoad } from './panoPicture/templatePictureLoad';
import { templateVideoLoad } from './panovideo/templateVideoLoad';

export class TemplateRenderStrategy extends RenderStrategy {
  protected renderType: RenderType = RenderType.TEMPLATE;

  public apply(params?: any) {
    // 模板
    const templateId = getQueryString('templateId');

    if (panoPicture()) {
      templatePictureLoad(templateId, params);
    }

    if (panoVideo()) {
      templateVideoLoad(templateId, params);
    }
  }
}
