/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 公众号 Footer
 */
import React from 'react';
import { FaLocationArrow } from 'react-icons/fa';

interface SimpleFooterProps {
  location: string;
  dateTime: string;
  visible?: boolean;
}

const defaultProps = {
  location: '',
  dateTime: '',
  visible: true,
}

export function SimpleFooter(props: SimpleFooterProps = defaultProps) {
  const { location, dateTime, visible } = props;
  return (
    visible ? 
    <div style={styles.container}>
      <FaLocationArrow style={styles.icon}  />
      <div style={styles.location}>
        {location}
      </div>
      <div style={styles.dateTime}>
        {dateTime}
      </div>
    </div>
    : <></>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    background: 'rgba(51, 51, 51, 1)',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.4,
    color: '#fff',
    zIndex: 1000,
  },
  icon: {
    marginLeft: 10,
  },
  location: {
    color: 'white',
    textAlign: 'center',
    marginLeft: 10,
  },
  dateTime: {
    color: 'white',
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
  },
};

export default SimpleFooter;
