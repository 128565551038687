/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

const DEBUG = true; // 打版时改为false 

export const Constant = {
  DEBUG,
};
