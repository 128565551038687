/**
 * Created by zhang.zhongdong
 * description:
 */
import { getQueryString } from '../../utils/functional';
import { processURL } from '../../utils/data';
// ${type === 'picture'}
//  playsound_at_hv(name, url, h, v, depth, loop, volume, oncomplete)

export function sound(hlookat: any, type: any) {
  return `
    <action name="sound1click">
      playsound_at_hv(s1, ${soundPath(0, type)}, ${hlookat + 0}, '0', '1000', true, 1.0);
     
    </action>
    <action name="sound2click">
      playsound_at_hv(s2, ${soundPath(1, type)}, ${hlookat + 90}, '0', '1000', true, 1.0);
    </action>
      
    <action name="sound3click">
      playsound_at_hv(s3, ${soundPath(2, type)}, ${hlookat + 270}, '0', '1000', true, 1.0);
    </action>
      
    <action name="sound4click">
      playsound_at_hv(s4, ${soundPath(3, type)}, ${hlookat + 180}, '0', '1000', true, 1.0);
    </action>

    <action name="soundplay">
      sound[s1].play(); 
      sound[s2].play();
      sound[s3].play();
      sound[s4].play();
    </action>

    <action name="soundpause">
      sound[s1].pause();
      sound[s2].pause();
      sound[s3].pause();
      sound[s4].pause();
    </action>
   
  `;
};


export function soundPath(index: any, type: any) {
  const path = processURL(getQueryString('initvars.mypath'));
  let url;
  if (type === 'video') {
    url = path.substring(0, path.indexOf("/video.mp4"));
  } else {
    url = path.substring(0, path.indexOf("/tiles"));
  }
  return `${url}/audio-${index}.mp3`;
}