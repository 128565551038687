/**
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import { Color } from '../../constant/Color';

function ConfirmSpecialEffects(props: any) {
  const { title, onConfirm, onCancel } = props;
  return (
    <div style={styles.container}>
      <div style={styles.buttonBox} onClick={() => onCancel(title)}>
        {
          !props.type ?
            <>
              <img src="img/tab/tab-close.png" alt="" style={styles.cancel} />
              <div style={styles.cancelText}></div>
            </> :
            <>
              <img src="img/tab/tab-close.png" alt="" style={styles.cancel} />
              <div style={styles.cancelText}></div>
            </>
        }
      </div>
      <div style={styles.title}>{title}</div>
      {
        !props.type ?
        <div style={styles.buttonBox} onClick={() => onConfirm(title)}>
          <div style={styles.confirmText}></div>
          <img src="img/tab/tab-confirm.png" alt="" style={styles.confirm} />
        </div> : <div style={styles.width}></div>
      }
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  title: {
    fontSize: 15,
    color: Color.fontColor,
    fontWeight: 500,
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
  },
  confirm: {
    padding: '3px 15px 0 8px',
    width: 21,
    height: 21,
  },
  cancel: {
    padding: '4px 8px 1px 15px',
    width: 19,
    height: 19,
  },
  confirmText: {
    fontSize: 15,
    color: '#FF9100FF',
    fontWeight: 500,
  },
  cancelText: {
    fontSize: 15,
    color: '#fff',
    fontWeight: 500,
  },
  width: {
    width: 45,
  }
}

export default ConfirmSpecialEffects;
