/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';

export function Empty(props: any) {
  return (<></>);
}

export default Empty;
