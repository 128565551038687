/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export enum SelectedVideoTab {
  模板 = '模板',
  速剪 = '速剪',
  动效 = '动效',
  配乐 = '配乐',
  滤镜 = '滤镜',
  OTHERS = 'OTHERS',
}

export const tabMapVideo: any = {
  '000': SelectedVideoTab.OTHERS, 
  '001': SelectedVideoTab.模板, // 模板
  '002': SelectedVideoTab.速剪, // 速剪
  '003': SelectedVideoTab.动效, // 动效
  '004': SelectedVideoTab.滤镜, // 滤镜
  '005': SelectedVideoTab.配乐, // 配乐
};

export const videoTabs = [{
  title: SelectedVideoTab.模板,
  image: 'img/tab/tab-template.png',
  selected: false,
}, {
  title: SelectedVideoTab.速剪,
  image: 'img/tab/tab-visual.png',
  selected: false,
}, {
  title: SelectedVideoTab.动效,
  image: 'img/tab/tab-dynamic.png',
  selected: false,
}, {
  title: SelectedVideoTab.配乐,
  image: 'img/tab/tab-audio.png',
  selected: false,
}, {
  title: SelectedVideoTab.滤镜,
  image: 'img/tab/tab-filter.png',
  selected: false,
}];

// 选中效果
export enum SelectedType {
  border = 0, // 添加边框
  changeImage, // 更换图片
  changeColor, // 更换图片
  none, // 无选中效果
}

// 动效crop动画选中效果互斥
export const cropStartArr = ['001', '002', '003', '004', '005', '006', '007'];
export const cropEndArr = ['100', '101'];

export const dataTreeVideo: any = {
  [SelectedVideoTab.模板]: [],
  [SelectedVideoTab.速剪]: [{
    title: '推荐',
    selected: true,
    selectedType: SelectedType.changeImage,
    list: [{
      title: '变换视角',
      image: 'img/visual/visual_1.png',
      selected: false,
      value: 12,
    }, {
      title: '拥抱风景',
      image: 'img/visual/visual_2.png',
      selected: false,
      value: 13,
    }, {
      title: '星球走秀',
      image: 'img/visual/visual_3.png',
      selected: false,
      value: 14,
    }, {
      title: '齐步走',
      image: 'img/visual/visual_4.png',
      selected: false,
      value: 16,
    }, {
      title: '天地互换',
      image: 'img/visual/visual_5.png',
      selected: false,
      value: 3,
    }, {
      title: '牵手奔跑',
      image: 'img/visual/visual_6.png',
      selected: false,
      value: 15,
    }, {
      title: '空间缩放',
      image: 'img/visual/visual_7.png',
      selected: false,
      value: 7,
    }, {
      title: '穿梭宇宙',
      image: 'img/visual/visual_8.png',
      selected: false,
      value: 9,
    }, {
      title: '对面是TA',
      image: 'img/visual/visual_9.png',
      selected: false,
      value: 17,
    }, {
      title: '全景闺蜜',
      image: 'img/visual/visual_10.png',
      selected: false,
      value: 18,
    }, {
      title: '行星内外',
      image: 'img/visual/visual_11.png',
      selected: false,
      value: 6,
    }, {
      title: '时间变速',
      image: 'img/visual/visual_12.png',
      selected: false,
      value: 5,
    }, {
      title: '蒙太奇转场',
      image: 'img/visual/visual_13.png',
      selected: false,
      value: 10,
    }, {
      title: '蒙太奇变速',
      image: 'img/visual/visual_14.png',
      selected: false,
      value: 11,
    }]
  }],
  [SelectedVideoTab.动效]: [
    {
      title: '基础',
      selected: true,
      selectedType: SelectedType.border,
      list: [{
        id: '001',
        title: '穿越小七',
        type: 'crop',
        image: 'img/intro/intro_4.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_4.png', framerate: 20, startOrEnd: 'start'},
        cancel: 'start',
      }, {
        id: '004',
        title: '地球穿越',
        type: 'crop',
        image: 'img/intro/intro_6.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_6.png', framerate: 16, startOrEnd: 'start'},
        cancel: 'start',
      }, {
        id: '005',
        title: '电脑穿越',
        type: 'crop',
        image: 'img/intro/intro_7.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_7.png', framerate: 15, startOrEnd: 'start'},
        cancel: 'start',
      }, {
        id: '006',
        title: '蒙板开场',
        type: 'crop',
        image: 'img/intro/intro_8.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_8.png', framerate: 22, startOrEnd: 'start'},
        cancel: 'start',
      }, {
        id: '007',
        title: '充电开场',
        type: 'crop',
        image: 'img/intro/intro_9.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_9.png', framerate: 8, startOrEnd: 'start'},
        cancel: 'start',
      }, {
        id: '002',
        title: '旋转开',
        type: 'crop',
        image: 'img/intro/intro_1.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_1.png', framerate: 7, startOrEnd: 'start'},
        cancel: 'start',
      }, {
        id: '100',
        title: '旋转关',
        type: 'crop',
        image: 'img/intro/intro_2.gif',
        selected: false,
        value: {imageurl: 'img/intro/intro_2.png', framerate: 7, startOrEnd: 'end'},
        cancel: 'end',
      }, {
        id: '5',
        title: '摄像机',
        type: 'effect',
        name: 'vcr',
        image: 'img/effect/effect-6.gif',
        selected: false,
        value: 'set_vcr(true);',
        cancel: 'set_vcr(false)',
      }, {
        id: '6',
        title: '横开屏',
        type: 'intro',
        image: 'img/effect/effect-4.gif',
        selected: false,
        value: 'h',
      }, {
        id: '7',
        title: '横关屏',
        type: 'intro',
        image: 'img/effect/effect-3.gif',
        selected: false,
        value: 'he',
      }, {
        id: '8',
        title: '竖开平',
        type: 'intro',
        image: 'img/effect/effect-8.gif',
        selected: false,
        value: 'v',
      }, {
        id: '9',
        title: '竖关屏',
        type: 'intro',
        image: 'img/effect/effect-7.gif',
        selected: false,
        value: 've',
      }, {
      //   id: '10',
      //   title: '随机动画',
      //   type: 'effect',
      //   name: 'random',
      //   image: 'img/effect/effect-9.gif',
      //   selected: false,
      //   value: 'effect_random_action(true)',
      //   cancel: 'effect_random_action(false)',
      // }, {
        id: '11',
        title: '快速模糊',
        type: 'effect',
        name: 'fblur',
        image: 'img/effect/effect-2.gif',
        selected: false,
        value: 'set_fblur(true)',
        cancel: 'set_fblur(false)',
      }, {
        id: '12',
        title: '调色分离',
        type: 'effect',
        name: 'fsplit',
        image: 'img/effect/effect-5.gif',
        selected: false,
        value: 'set_fsplit(true)',
        cancel: 'set_fsplit(false)',
      },]
    }, 
    // {
    //   title: '分屏',
    //   selected: false,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     id: '100',
    //     title: '左右分',
    //     type: 'effect',
    //     name: 'stereo',
    //     image: 'img/effect/splitview-1.png',
    //     selected: false,
    //     value: 'effect_stereo_action(true)',
    //     cancel: 'effect_stereo_action(false)',
    //   },]
    // }
  ],
  [SelectedVideoTab.配乐]: [],
  [SelectedVideoTab.滤镜]: [{
    title: '推荐',
    selected: true,
    selectedType: SelectedType.border,
    list: [{
      id: '1',
      title: '鲜艳的',
      image: 'img/filterVideo/filter_9.png',
      selected: false,
      value: '1',
    }, {
      id: '2',
      title: '增强色',
      image: 'img/filterVideo/filter_12.png',
      selected: false,
      value: '2',
    }, {
      id: '3',
      title: '超对比',
      image: 'img/filterVideo/filter_1.png',
      selected: false,
      value: '3',
    }, {
      id: '4',
      title: '旧照片',
      image: 'img/filterVideo/filter_4.png',
      selected: false,
      value: '4',
    }, {
      id: '5',
      title: '黑白',
      image: 'img/filterVideo/filter_3.png',
      selected: false,
      value: '5',
    }, {
      id: '6',
      title: '高级灰',
      image: 'img/filterVideo/filter_2.png',
      selected: false,
      value: '6',
    }, {
      id: '7',
      title: '蜡笔画',
      image: 'img/filterVideo/filter_5.png',
      selected: false,
      value: '7',
    }, {
      id: '8',
      title: '铅笔画',
      image: 'img/filterVideo/filter_6.png',
      selected: false,
      value: '8',
    }, {
      id: '9',
      title: '水彩画',
      image: 'img/filterVideo/filter_8.png',
      selected: false,
      value: '9',
    }, {
      id: '10',
      title: '星空紫',
      image: 'img/filterVideo/filter_11.png',
      selected: false,
      value: '10',
    }, {
      id: '11',
      title: '日式蓝',
      image: 'img/filterVideo/filter_7.png',
      selected: false,
      value: '11',
    }, {
      id: '12',
      title: '小美好',
      image: 'img/filterVideo/filter_10.png',
      selected: false,
      value: '12',
    }, {
    //   id: '13',
    //   title: '晚霞紫',
    //   image: 'img/filterVideo/filter_13.png',
    //   selected: false,
    //   value: '13',
    // }, {
      id: '14',
      title: '柯达胶卷',
      image: 'img/filterVideo/filter_14.png',
      selected: false,
      value: '14',
    }, {
    //   id: '15',
    //   title: '极地蓝',
    //   image: 'img/filterVideo/filter_15.png',
    //   selected: false,
    //   value: '15',
    // }, {
      id: '16',
      title: '午后阳光',
      image: 'img/filterVideo/filter_16.png',
      selected: false,
      value: '16',
    }, {
      id: '17',
      title: '高原蓝',
      image: 'img/filterVideo/filter_17.png',
      selected: false,
      value: '17',
    }, {
    //   id: '18',
    //   title: '远峰蓝',
    //   image: 'img/filterVideo/filter_18.png',
    //   selected: false,
    //   value: '18',
    // }, {
    //   id: '19',
    //   title: '韩式清新',
    //   image: 'img/filterVideo/filter_19.png',
    //   selected: false,
    //   value: '19',
    // }, {
      id: '20',
      title: '日照金山',
      image: 'img/filterVideo/filter_20.png',
      selected: false,
      value: '20',
    }, {
      id: '21',
      title: '正午时分',
      image: 'img/filterVideo/filter_21.png',
      selected: false,
      value: '21',
    }, {
      id: '22',
      title: '深秋晚霞',
      image: 'img/filterVideo/filter_22.png',
      selected: false,
      value: '22',
    }, {
    //   id: '23',
    //   title: '褪色照片',
    //   image: 'img/filterVideo/filter_23.png',
    //   selected: false,
    //   value: '23',
    // }, {
      id: '24',
      title: '老胶片',
      image: 'img/filterVideo/filter_24.png',
      selected: false,
      value: '24',
    }, {
    //   id: '25',
    //   title: '夜色浓',
    //   image: 'img/filterVideo/filter_25.png',
    //   selected: false,
    //   value: '25',
    // }, {
      id: '26',
      title: '金秋傍晚',
      image: 'img/filterVideo/filter_26.png',
      selected: false,
      value: '26',
    }, {
    //   id: '27',
    //   title: '美式蓝',
    //   image: 'img/filterVideo/filter_27.png',
    //   selected: false,
    //   value: '27',
    // }, {
      id: '28',
      title: '乐高拼图',
      image: 'img/filterVideo/filter_28.png',
      selected: false,
      value: '28',
    }, {
    //   id: '29',
    //   title: '油画',
    //   image: 'img/filterVideo/filter_29.png',
    //   selected: false,
    //   value: '29',
    // }, {
      id: '30',
      title: '印象派',
      image: 'img/filterVideo/filter_30.png',
      selected: false,
      value: '30',
    },]
  }],
};