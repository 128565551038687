/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { SelectedType } from '../components/tab/data/dataPicture';
import { Constant } from '../constant/Constant';

export function processURL(url: string): string {
  if (!url) return '';
  if (Constant.DEBUG) return url.replace('https', 'http').split('?')[0];

  return url.split('?')[0];
}

(window as any).processURL = processURL;

/**
 * 组合配乐数组
 */
export function combineDataList(dataList: any[], itemDataList: any[], categoryID: string, itemID: string): any[] {
  return dataList.map((item: any, index: number) => ({
    ID: item.categoryID,
    title: item.name.split('_')[1],
    selected: item.categoryID === categoryID,
    selectedType: SelectedType.border,
    list: item.categoryID === categoryID ? [
      // {
      //   title: '无配乐',
      //   image: 'img/ic-no-bgm.png',
      //   selected: itemID ? false : true,
      //   value: 'no-bgm',
      // },
      ...itemDataList.map((it: any) => ({
        groupID: item.categoryID,
        title: it.datainfo.name,
        image: it.album.imagePathMap[0].value,
        selected: it.itemID === itemID,
        value: it.itemID,
      }))
    ] : [],
  }));
}

/**
 * 组合配乐数组
 */
export function combineDataListWithDataList(dataList: any[], itemDataList: any[], categoryID: string) {
  const target = dataList.find((item: any) => item.ID === categoryID);
  let noBGMFlag;
  dataList.forEach((item: any) => {
    const tmp = item.list.find((it: any) => it.title === '无配乐' && it.selected === true);
    if (tmp) noBGMFlag = tmp;
  });
  target.list = [
    // {
    //   title: '无配乐',
    //   image: 'img/ic-no-bgm.png',
    //   selected: noBGMFlag !== undefined,
    //   value: 'no-bgm',
    // },
    ...itemDataList.map((item: any) => ({
      id: item.itemID,
      groupID: categoryID,
      title: item.datainfo.name,
      image: item.album.imagePathMap[0].value,
      selected: false,
      value: item.itemID,
    }))
  ];

  return dataList.map((item: any) => ({
    ...item,
    selected: item.ID === categoryID,
  }));
}