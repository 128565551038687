import React, { useState } from 'react';
import { QRIcon } from './QRIconLayout';

export default function MiniLayout() {
  return (
    <div style={{ position: 'relative' }}>
      <QRIcon tag="mini-program" title="点我去修图" icon="img/ic-mini-program.png"/>
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_f2ad1008bf50"
        path="pages/see/index"
        style={{ position: 'absolute', top: 0, left: 0, width: 42, height: 52 }}
      >
        <script type="text/wxtag-template">
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
        </script>
      </wx-open-launch-weapp>
    </div>
  )
}
