/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export enum SelectedTab {
  模板 = '模板',
  动效 = '动效',
  滤镜 = '滤镜',
  配乐 = '配乐',
  换天 = '换天',
  巡航 = '巡航',
  边框 = '边框',
  贴纸 = '贴纸',
  标签 = '标签',
  裁剪 = '裁剪',
  OTHERS = 'OTHERS',
}

// 模板、边框、滤镜、动效、贴纸、标签


export const tabMap: any = {
  '': SelectedTab.OTHERS,
  '00': SelectedTab.OTHERS, // OTHERS
  '13': SelectedTab.模板, // 模板

  '12': SelectedTab.换天, // 换天空
  '01': SelectedTab.滤镜, // 滤镜
  '02': SelectedTab.配乐, // 配乐
  '03': SelectedTab.标签, // 标签/文字
  '04': SelectedTab.贴纸, // 贴纸
  '05': SelectedTab.裁剪, // 裁剪
  // '06': SelectedTab., // 美颜
  // '07': SelectedTab., // 移除
  // '08': SelectedTab., // 截屏
  '09': SelectedTab.动效, // 动效
  '10': SelectedTab.巡航, // 巡游
  '11': SelectedTab.边框, // 边框
};

// 模板、边框、滤镜、动效、贴纸、标签

export const tabs = [{
  title: SelectedTab.模板,
  image: 'img/tab/tab-template.png',
  selected: true,
}, {
  title: SelectedTab.边框,
  image: 'img/tab/tab-frame.png',
  selected: false,
}, {
  title: SelectedTab.滤镜,
  image: 'img/tab/tab-filter.png',
  selected: false,
}, {
  title: SelectedTab.动效,
  image: 'img/tab/tab-dynamic.png',
  selected: false,
}, {
  title: SelectedTab.贴纸,
  image: 'img/tab/tab-sticker.png',
  selected: false,
}, {
  title: SelectedTab.标签,
  image: 'img/tab/tab-text.png',
  selected: false,
}
  //   , {
  //   title: SelectedTab.换天,
  //   image: 'img/tab/tab-sky.png',
  //   selected: true,
  // }, {
  //   title: SelectedTab.配乐,
  //   image: 'img/tab/tab-audio.png',
  //   selected: false,
  // }, {
  //   title: SelectedTab.巡航,
  //   image: 'img/tab/tab-cruising.png',
  //   selected: false,
  // }
  // , {
  //   title: SelectedTab.裁剪,
  //   image: 'img/tab/tab-crop.png',
  //   selected: false,
  // }
];

// 选中效果
export enum SelectedType {
  border = 0, // 添加边框
  changeImage, // 更换图片
  changeColor, // 更换颜色
  none, // 无选中效果
}

export const dataTree: any = {
  [SelectedTab.模板]: [],
  [SelectedTab.动效]: [],
  [SelectedTab.滤镜]: [{
    title: '艺术',
    selected: true,
    selectedType: SelectedType.border,
    list: [{
      //   title: '原图',
      //   image: 'img/filter/group0/reset.png',
      //   selected: true,
      //   value: 'resetFilter();',
      // }, {
      title: '小美好',
      image: 'img/filter/group0/clarendon.png',
      selected: false,
      value: 'clarendon();',
    }, {
      title: '复古',
      image: 'img/filter/group0/earlybird.png',
      selected: false,
      value: 'earlybird()',
    }, {
      title: '质感',
      image: 'img/filter/group0/vignette.png',
      selected: false,
      value: 'vignette()',
    }, {
      title: '蜡笔',
      image: 'img/filter/group0/crayon.png',
      selected: false,
      value: 'crayon()',
    }, {
      title: '素描',
      image: 'img/filter/group0/sketch.png',
      selected: false,
      value: 'sketch()',
    }, {
      title: '黑白片',
      image: 'img/filter/group0/inkwell.png',
      selected: false,
      value: 'inkwell()',
    }, {
      title: '寒冷的',
      image: 'img/filter/group0/cold.png',
      selected: false,
      value: 'cold();',
    }, {
      title: '黑暗的',
      image: 'img/filter/group0/dark.png',
      selected: false,
      value: 'dark();',
    }, {
      title: '旧时代',
      image: 'img/filter/group0/old-times.png',
      selected: false,
      value: 'oldtimes();',
    }, {
      title: '温暖的',
      image: 'img/filter/group0/warm.png',
      selected: false,
      value: 'warm();',
    }, {
      title: '水彩画',
      image: 'img/filter/group0/kuwahara.png',
      selected: false,
      value: 'kuwahara();',
    }]
  }, {
    title: '冷艳',
    selected: false,
    selectedType: SelectedType.border,
    list: [{
      title: '原图',
      image: 'img/filter/reset.png',
      selected: true,
      value: 'resetFilter();',
    }, {
      title: 'cc-1',
      image: 'img/filter/group1/pp_nashville(cc-1).png',
      selected: false,
      value: 'nashville();',
    }, {
      title: 'cc-2',
      image: 'img/filter/group1/pp_hudson(cc-2).png',
      selected: false,
      value: 'hudson();',
    }, {
      title: 'cc-3',
      image: 'img/filter/group1/pp_brannan(cc-3).png',
      selected: false,
      value: 'brannan();',
    }, {
      title: 'cc-4',
      image: 'img/filter/group1/pp_charmes(cc-4).png',
      selected: false,
      value: 'charmes();',
    }, {
      title: 'cc-5',
      image: 'img/filter/group1/pp_hudson_v(cc-5).png',
      selected: false,
      value: 'hudson_v();',
    }, {
      title: 'cc-6',
      image: 'img/filter/group1/pp_brannan_v(cc-6).png',
      selected: false,
      value: 'brannan_v();',
    }],
  }, {
    title: '浓烈',
    selected: false,
    selectedType: SelectedType.border,
    list: [{
      title: '原图',
      image: 'img/filter/reset.png',
      selected: true,
      value: 'resetFilter();',
    }, {
      title: 'br-1',
      image: 'img/filter/group2/pp_ginza(br-1).png',
      selected: false,
      value: 'ginza();',
    }, {
      title: 'br-2',
      image: 'img/filter/group2/pp_skyline(br-2).png',
      selected: false,
      value: 'skyline();',
    }, {
      title: 'br-3',
      image: 'img/filter/group2/pp_dogpatch(br-3).png',
      selected: false,
      value: 'dogpatch();',
    }, {
      title: 'br-4',
      image: 'img/filter/group2/pp_hefe(br-4).png',
      selected: false,
      value: 'hefe();',
    }, {
      title: 'br-5',
      image: 'img/filter/group2/pp_sierra(br-5).png',
      selected: false,
      value: 'sierra();',
    }, {
      title: 'br-6',
      image: 'img/filter/group2/pp_mayfair(br-6).png',
      selected: false,
      value: 'mayfair();',
    }, {
      title: 'br-7',
      image: 'img/filter/group2/pp_lofi(br-7).png',
      selected: false,
      value: 'lofi();',
    }],
  }, {
    title: '暖阳',
    selected: false,
    selectedType: SelectedType.border,
    list: [{
      title: '原图',
      image: 'img/filter/reset.png',
      selected: true,
      value: 'resetFilter();',
    }, {
      title: 'wf-1',
      image: 'img/filter/group3/pp_perpetua(wf-1).png',
      selected: false,
      value: 'perpetua();',
    }, {
      title: 'wf-2',
      image: 'img/filter/group3/pp_vesper(wf-2).png',
      selected: false,
      value: 'vesper();',
    }, {
      title: 'wf-3',
      image: 'img/filter/group3/pp_juno(wf-3).png',
      selected: false,
      value: 'juno();',
    }, {
      title: 'wf-4',
      image: 'img/filter/group3/pp_maven(wf-4).png',
      selected: false,
      value: 'maven();',
    }, {
      title: 'wf-5',
      image: 'img/filter/group3/pp_helena(wf-5).png',
      selected: false,
      value: 'helena();',
    }, {
      title: 'wf-6',
      image: 'img/filter/group3/pp_xpro2(wf-6).png',
      selected: false,
      value: 'xpro2();',
    }, {
      title: 'wf-7',
      image: 'img/filter/group3/pp_rise(wf-7).png',
      selected: false,
      value: 'rise();',
    }, {
      title: 'wf-8',
      image: 'img/filter/group3/pp_valencia(wf-8).png',
      selected: false,
      value: 'valencia();',
    }],
  }, {
    title: '复古',
    selected: false,
    selectedType: SelectedType.border,
    list: [{
      title: '原图',
      image: 'img/filter/reset.png',
      selected: true,
      value: 'resetFilter();',
    }, {
      title: 're-1',
      image: 'img/filter/group4/pp_walden(re-1).png',
      selected: false,
      value: 'walden();',
    }, {
      title: 're-2',
      image: 'img/filter/group4/pp_ashby(re-2).png',
      selected: false,
      value: 'ashby();',
    }, {
      title: 're-3',
      image: 'img/filter/group4/pp_toaster(re-3).png',
      selected: false,
      value: 'toaster();',
    }, {
      title: 're-4',
      image: 'img/filter/group4/pp_kelvin(re-4).png',
      selected: false,
      value: 'kelvin();',
    }, {
      title: 're-5',
      image: 'img/filter/group4/pp_1977(re-5).png',
      selected: false,
      value: '_1977();',
    }, {
      title: 're-6',
      image: 'img/filter/group4/pp_rise_v(re-6).png',
      selected: false,
      value: 'rise_v();',
    }, {
      title: 're-7',
      image: 'img/filter/group4/pp_toaster_v(re-7).png',
      selected: false,
      value: 'toaster_v();',
    }],
  }, {
    title: '前卫',
    selected: false,
    selectedType: SelectedType.border,
    list: [{
      title: '原图',
      image: 'img/filter/reset.png',
      selected: true,
      value: 'resetFilter();',
    }, {
      title: 'dk-1',
      image: 'img/filter/group5/pp_sutro(dk-1).png',
      selected: false,
      value: 'sutro();',
    }, {
      title: 'dk-2',
      image: 'img/filter/group5/pp_xpro2_v(dk-2).png',
      selected: false,
      value: 'xpro2_v();',
    }, {
      title: 'dk-3',
      image: 'img/filter/group5/pp_mayfair_v(dk-3).png',
      selected: false,
      value: 'mayfair_v();',
    }, {
      title: 'dk-4',
      image: 'img/filter/group5/pp_sutro_v(dk-4).png',
      selected: false,
      value: 'sutro_v();',
    }],
  }, {
    title: '青涩',
    selected: false,
    selectedType: SelectedType.border,
    list: [{
      title: '原图',
      image: 'img/filter/reset.png',
      selected: true,
      value: 'resetFilter();',
    }, {
      title: 'lc-1',
      image: 'img/filter/group6/pp_moon(lc-1).png',
      selected: false,
      value: 'moon();',
    }, {
      title: 'lc-2',
      image: 'img/filter/group6/pp_willow(lc-2).png',
      selected: false,
      value: 'willow();',
    }, {
      title: 'lc-3',
      image: 'img/filter/group6/pp_slumber(lc-3).png',
      selected: false,
      value: 'slumber();',
    }, {
      title: 'lc-4',
      image: 'img/filter/group6/pp_lark(lc-4).png',
      selected: false,
      value: 'lark();',
    }, {
      title: 'lc-5',
      image: 'img/filter/group6/pp_reyes(lc-5).png',
      selected: false,
      value: 'reyes();',
    }, {
      title: 'lc-6',
      image: 'img/filter/group6/pp_stinson(lc-6).png',
      selected: false,
      value: 'stinson();',
    }, {
      title: 'lc-7',
      image: 'img/filter/group6/pp_brooklyn(lc-7).png',
      selected: false,
      value: 'brooklyn();',
    }, {
      title: 'lc-8',
      image: 'img/filter/group6/pp_gingham(lc-8).png',
      selected: false,
      value: 'gingham();',
    }, {
      title: 'lc-9',
      image: 'img/filter/group6/pp_aden(lc-9).png',
      selected: false,
      value: 'aden();',
    }],
  }],
  [SelectedTab.配乐]: [],
  [SelectedTab.换天]: [],
  [SelectedTab.巡航]: [{
    title: '推荐',
    selected: true,
    selectedType: SelectedType.changeImage,
    list: [{
      //   title: '原图',
      //   image: 'img/cruising/no-cruise.png',
      //   selected: true,
      //   value: 0,
      // }, {
      title: '水平滑行',
      image: 'img/cruising/cruising_1.png',
      selected: false,
      value: 1,
    }, {
      title: '目视盘旋',
      image: 'img/cruising/cruising_2.png',
      selected: false,
      value: 2,
    }, {
      title: '穿越黑洞',
      image: 'img/cruising/cruising_3.png',
      selected: false,
      value: 3,
    }, {
      title: '宇宙大爆炸',
      image: 'img/cruising/cruising_4.png',
      selected: false,
      value: 4,
    }, {
      title: '星际着陆',
      image: 'img/cruising/cruising_5.png',
      selected: false,
      value: 5,
    }]
  }],
  [SelectedTab.边框]: [],
  [SelectedTab.贴纸]: [],
  [SelectedTab.标签]: {
    title: SelectedTab.标签,
    list: [{
      title: 'text-1',
      image: 'img/textsticker/text1.png',
      value: { 'x': 20, 'y': 130, 'width': 260, 'height': 107 },
    }, {
      title: 'text-2',
      image: 'img/textsticker/text2.png',
      value: { 'x': 20, 'y': 130, 'width': 260, 'height': 107 },
    }],
  },
  [SelectedTab.裁剪]: {
    title: SelectedTab.裁剪,
    selected: true,
    selectedType: SelectedType.border,
    list: [{
      title: '1-1',
      image: 'img/crop/1-1.png',
      selected: true,
      value: 1,
      flag: 1,
    }, {
      title: '3-2',
      image: 'img/crop/3-2.png',
      selected: false,
      value: 2 / 3,
      flag: 2,
    }, {
      title: '2-3',
      image: 'img/crop/2-3.png',
      selected: false,
      value: 3 / 2,
      flag: 3,
    }, {
      title: '16-9',
      image: 'img/crop/16-9.png',
      selected: false,
      value: 9 / 16,
      flag: 4,
    }, {
      title: '9-16',
      image: 'img/crop/9-16.png',
      selected: false,
      value: 1.7,
      flag: 5,
    }],
  },
};