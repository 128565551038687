/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { executePanoAction, dynamicLoadPanoXML, reloadPanoVideoXML } from '../../../panoramic/PanoramicView';
import {
  ACTION_HIDE_ALL,
  ACTION_SHOW_ALL,
} from '../../../panoramic/actions';
import {
  getQueryString,
  getSerialNumber,
  delay,
  officialVideo,
  officialPicture,
} from '../../../../utils/functional';
import { fetchOrderDetail } from '../../../../api/NetworkApi';
import { shareManager } from '../../../../manager/share';
import { getPanoData } from '../../../panoramic/data';
import { processURL } from '../../../../utils/data';

export function useController(initialState?: any) {
  const [footerVisible, setFooterVisible] = useState(true);
  const [cleanScreen, setCleanScreen] = useState(false);
  const [showQuitCleanButton, setShowQuitCleanButton] = useState(false);
  const [viewObj, setViewObj] = useState({ dateTime: '', location: '', showDialog: false, errorMessage: '', scenicInfo: [] });

  useEffect(() => {
    const txnNo = getQueryString('initvars.mypath');

    const serialNumber = getSerialNumber(txnNo);

    const panoAdmin = getQueryString('admin');
    // 初始化分享功能

    shareManager.init(serialNumber);

    fetchOrderDetail(serialNumber)
      .then(([error, result]) => {
        if (error) {
          // 回调失败
          if (officialVideo()) reloadPanoVideoXML();
          if (officialPicture()) dynamicLoadPanoXML();
          return;
        }
        // 获取视频封面
        if (officialVideo()) {
          getPanoData().jsonData.scene[0].poster = processURL(result.poster_url || "");
          reloadPanoVideoXML();
        }
        // 后管平台 admin, 退款用户允许打开.
        if (result.status_code !== "00" && !panoAdmin) {
          return setViewObj({
            dateTime: '',
            location: '',
            scenicInfo: [],
            showDialog: true,
            errorMessage: '您的全景照片已被退订删除，请重新拍摄，谢谢！',
          });
        }

        setViewObj({
          dateTime: result.payment_time || '',
          location: result.detail || '',
          scenicInfo: result.scenic_area_qrcode_info_vos || [],
          showDialog: false,
          errorMessage: '',
        });
        if (officialPicture()) {
          // 存设备类型，三代设备 - app_code_3
          getPanoData().scene[0].deviceAppCode = result.device_app_code;
          delay(200).then(dynamicLoadPanoXML);
        }
      });
  }, []);

  function handleDialogConfirm() {
    setViewObj({
      dateTime: '',
      location: '',
      scenicInfo: [],
      showDialog: false,
      errorMessage: '您的全景照片已被退订删除，请重新拍摄，谢谢！',
    });
  }
  function handleFooterStatusChanged(show: boolean) {
    setFooterVisible(!show);
  }
  function handleCleanScreen() {
    executePanoAction(ACTION_HIDE_ALL);

    setCleanScreen(true);
  }
  async function handlePanoClick() {
    setShowQuitCleanButton(true);
    await delay(2000);
    setShowQuitCleanButton(false);
  }
  function handleQuitCleanClick() {
    executePanoAction(ACTION_SHOW_ALL);

    setShowQuitCleanButton(false);
    setCleanScreen(false);
  }
  (window as any).funPanoClick = handlePanoClick;

  return {
    footerVisible,
    cleanScreen,
    showQuitCleanButton,
    viewObj,

    handleDialogConfirm,
    handleFooterStatusChanged,
    handleCleanScreen,
    handleQuitCleanClick,
  };
}
