/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { getPanoData } from "./data";
import { sound } from './functional';

const hlookat = 45.0;
export function templateVideo() {
  const scenes = getPanoData().jsonData.scene.map((item: any) => {
    if (item.video) {
      return `<scene name="scene_${item.id}" onstart="start_up();" lat="" lng="" heading="">
            <view hlookat="${hlookat}" vlookat="0.0" fovtype="MFOV" fov="100" fovmin="70" fovmax="140" limitview="auto" />
            <plugin name="video" loop="true" url="%ROOT%/plugins/videoplayer.js" pausedonstart="false" onloaded="add_video_sources();"/>
            <image><sphere url="plugin:video"/></image>
            ${sound(hlookat, 'video')}
          </scene>`;
    }
  })
    .join("");
  console.log(`
    <krpano>
      <include url="%ROOT%/xml/videoPlayer/main.xml" />
      ${scenes}
    </krpano>
  `);
  return `<krpano><include url="%ROOT%/xml/videoPlayer/main.xml" />${scenes}</krpano>`;
}


