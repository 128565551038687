/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 动效策略
 */
import { SelectedTab, SelectedType } from '../../data/dataPicture';
import { Strategy } from '../Strategy';
import { fetchMaterialList } from '../../../../api/NetworkApi';
import { dynamicAction, noneDynamicAction } from '../../../panoramic/actions';
import { processURL } from '../../../../utils/data';

export class DynamicStrategy extends Strategy<SelectedTab> {
  protected selectedTab: SelectedTab = SelectedTab.动效;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    tipControl.showLoading();
    // 获取类目内容
    const [error, data] = await fetchMaterialList({type: '01', file_type: '01'});
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    const snowData =  data.map((item: any, index: number) => {
      // const reset = {
      //   title: '无动效',
      //   image: 'img/ic-default.png',
      //   selected: true,
      //   value: noneDynamicAction(),
      // };
      const list = item.material_sorts.map((element: any) => {
        // 新增动效
        let actionItem: any = {
          id: element.id,
          imageurl: processURL(element.file_url),
          invert: element.data === 'up',
        }
        // 保留存量数据动效
        if (Number(element.id) > 1000 && Number(element.id) < 1015) {
          actionItem = {
            id: Number(element.id),
          }
        } 
        return {
          id: element.id,
          title: element.title,
          image: processURL(element.thumbnail_url),
          selected: false,
          value: dynamicAction(actionItem),
        }
      });
      // list.unshift(reset);
      return {
        title: item.material_group_name,
        selected: index === 0,
        selectedType: SelectedType.border,
        list,
      };
    });
    return snowData;
    // return [{
    //   title: SelectedTab.动效,
    //   selected: true,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     title: '无动效',
    //     image: 'img/ic-default.png',
    //     selected: true,
    //     value: 'nonesnow()',
    //   }, {
    //     id: 1,
    //     title: '樱花',
    //     image: 'img/dynamic/dynamic-icon-1.jpg',
    //     selected: false,
    //     value: 'dynamic-1()',
    //   }, {
    //     id: 2,
    //     title: '雪花',
    //     image: 'img/dynamic/dynamic-icon-2.jpg',
    //     selected: false,
    //     value: 'dynamic-2()',
    //   }, {
    //     id: 3,
    //     title: '枫叶',
    //     image: 'img/dynamic/dynamic-icon-3.jpg',
    //     selected: false,
    //     value: 'dynamic-3()',
    //   }, {
    //     id: 4,
    //     title: '孔明灯',
    //     image: 'img/dynamic/dynamic-icon-4.jpg',
    //     selected: false,
    //     value: 'dynamic-4()',
    //   }, {
    //     id: 5,
    //     title: '花瓣',
    //     image: 'img/dynamic/dynamic-icon-5.jpg',
    //     selected: false,
    //     value: 'dynamic-5()',
    //   }, {
    //     id: 6,
    //     title: '红包',
    //     image: 'img/dynamic/dynamic-icon-6.jpg',
    //     selected: false,
    //     value: 'dynamic-6()',
    //   }, {
    //     id: 7,
    //     title: '泡泡',
    //     image: 'img/dynamic/dynamic-icon-7.jpg',
    //     selected: false,
    //     value: 'dynamic-7()',
    //   }, {
    //     id: 8,
    //     title: '白泡泡',
    //     image: 'img/dynamic/dynamic-icon-8.jpg',
    //     selected: false,
    //     value: 'dynamic-8()',
    //   }, {
    //     id: 9,
    //     title: '星星',
    //     image: 'img/dynamic/dynamic-icon-9.jpg',
    //     selected: false,
    //     value: 'dynamic-9()',
    //   }, {
    //     id: 10,
    //     title: '繁星',
    //     image: 'img/dynamic/dynamic-icon-10.jpg',
    //     selected: false,
    //     value: 'dynamic-10()',
    //   }, {
    //     id: 11,
    //     title: '礼物',
    //     image: 'img/dynamic/dynamic-icon-12.jpg',
    //     selected: false,
    //     value: 'dynamic-12()',
    //   }, {
    //     id: 12,
    //     title: '风铃',
    //     image: 'img/dynamic/dynamic-icon-14.jpg',
    //     selected: false,
    //     value: 'dynamic-14()',
    //   }],
    // }];
  }

  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl } = params;
    console.log(`== fetchDataWithGroup ===>>>> ${JSON.stringify(group)}`);
  }
}
