/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import Modal from '../modal/Modal';

export function Loading(props: any) {
  return (
    props.show &&
    <Modal>
      <div style={styles.container}>
        <img src="img/loading.gif" alt="" style={styles.image} />
      </div>
    </Modal>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
  },
  image: {
    width: 140,
    height: 140,
  },
};

export default Loading;
