/**
 * https://github.com/maybewaityou
 *
 * description:
 * 模板展示
 */
import { Base64 } from 'js-base64';
import { reloadPanoVideoXML, executePanoAction } from '../../../../panoramic/PanoramicView';
import { stopBGM } from '../../../../panoramic/actions';
import { setPanoData, getPanoData, getResetData, setResetData } from '../../../../panoramic/data';
import { fetchBackgroundMusicURL, fetchContentDetail, fetchDataConfigById } from '../../../../../api/NetworkApi';
import { getQueryString } from '../../../../../utils/functional';
import { processURL } from '../../../../../utils/data';

/**
 * 视频模板加载
 */
export function templateVideoLoad(templateId: any, params?: any) {
  const { tipControl } = params;
  const id = getQueryString('orderId') || getQueryString('userWorksId');
  // 快速模板默认选中
  getPanoData().jsonData.template = {
    groupId: getQueryString('groupId'),
    value: getQueryString('templateId'),
  }
  // 模板玩图true，精选内容模板不玩图时候false
  if (id) {
    tipControl.showLoading();
    fetchDataConfigById(id).then(async ([error, result]) => {
      tipControl.dismissLoading();
      if (error) return tipControl.showToast(error);
      // 作品或原片保留data
      if (result.data) {
        const data = JSON.parse(Base64.decode(result.data));
        setResetData(data);
      } else {
        getResetData().scene[0].poster = processURL(result.poster_url || result.thumbnail_url);
      }
      templateAction(templateId, params);
    }).catch((error) => {
      console.log(error);
    });
  } else {
    templateAction(templateId, params);
  }
}

function templateAction(templateId: any, params?: any) {
  const { tipControl, hooks: { setPanoConfig, setBGMItem } } = params;
  const id = getQueryString('orderId') || getQueryString('userWorksId');
  tipControl.showLoading();
  fetchContentDetail(templateId).then(async ([error, result]: any) => {
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);

    const userWorkData = result.resource_list[0].user_work_data;
    if (userWorkData && userWorkData !== '0') {
      const data = JSON.parse(Base64.decode(userWorkData));
      //快速模板，当前模板分组和模板。点击确定后选中状态
      data.template.groupId = getQueryString('groupId');
      data.template.value = getQueryString('templateId');

      // 快速模板/玩同款加载
      if (id) {
        data.scene[0].poster = processURL(getResetData().scene[0].poster);
      } else {
        // 防止作品删除后无法获取封面，重新获取模板封面
        data.scene[0].poster = processURL(result.resource_list[0].poster_url || result.resource_list[0].thumbnail_url || '');
      }
      console.log(data);
      setResetData(data);
      setPanoData(data);
      // setPanoData({
      //   ...data,
      //   topimg: {
      //     ...data.topimg,
      //     url: '',
      //   }
      // });

      // 不存在音乐, 直接加载 XML
      if (!data.bgmItem.value) {
        reloadPanoVideoXML();
      } else {
        // 获取音乐 URL
        const [bgmError, bgmResult] = await fetchBackgroundMusicURL(data.bgmItem.value)
        if (bgmError) return tipControl.showToast(bgmError);

        let resultData;
        try {
          resultData = JSON.parse(Base64.decode(bgmResult.data));
        } catch (error) {
          console.log(error);
          // return;
        }
        if (!resultData || !resultData.data) {
          // tipControl.showToast(`${resultData.code} - ${resultData.msg}`);
          reloadPanoVideoXML();
        } else {
          const { url } = resultData.data;
          if (url) {
            data.music = url;
            const extraItem = { ...data.bgmItem, url, shouldPlay: false, };
            setPanoData(data);
            reloadPanoVideoXML();
            setPanoConfig(data);
            setBGMItem(extraItem);

          }
        }
      }
    }
  });
}

/**
 * 视频模板加载
 */
export function templateLoad(item: any, params?: any) {
  const { tipControl, setBGMItem } = params;
  // tipControl.showLoading();
  fetchContentDetail(item.value).then(async ([error, result]: any) => {
    // tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);

    const userWorkData = result.resource_list[0].user_work_data;
    if (userWorkData && userWorkData !== '0') {
      const config = JSON.parse(Base64.decode(userWorkData));

      const data = config;
      console.log(data);

      // 设置 PanoData, PanoXML 加载时会读取 PanoData, 并还原设置
      data.scene[0].poster = processURL(getResetData().scene[0].poster);
      data.template.value = item.value;
      data.template.groupId = item.groupId;
      setPanoData(data);
      // setPanoConfig(data);
      // 不存在音乐, 直接加载 XML
      if (!data.bgmItem.value) {
        executePanoAction(stopBGM());
        reloadPanoVideoXML();
      } else {
        // 获取音乐 URL
        const [bgmError, bgmResult] = await fetchBackgroundMusicURL(data.bgmItem.value)
        if (bgmError) return tipControl.showToast(bgmError);

        let resultData;
        try {
          resultData = JSON.parse(Base64.decode(bgmResult.data));
        } catch (error) {
          console.log(error);
          // return;
        }
        if (!resultData || !resultData.data) {
          // tipControl.showToast(`${resultData.code} - ${resultData.msg}`);
        } else {
          const { url } = resultData.data;
          if (url) {
            data.music = url;
            const extraItem = { ...data.bgmItem, url, shouldPlay: false, };
            setPanoData(data);
            reloadPanoVideoXML();
            // setPanoConfig(data);
            setBGMItem(extraItem);

          }
        }
      }
    }
  });
}