/**
 * Create by zzd
 */
import { useController } from './controller/DeviceViewController';
export function DeviceView(props: any) {

  const {} = useController(props);

  return (
    <div></div>
  );

};
export default DeviceView;