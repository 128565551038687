/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 边框策略
 */
import { SelectedTab, SelectedType } from '../../data/dataPicture';
import { Strategy } from '../Strategy';
import { fetchMaterialList } from '../../../../api/NetworkApi';
import { processURL } from '../../../../utils/data';

export class FrameStrategy extends Strategy<SelectedTab> {
  protected selectedTab: SelectedTab = SelectedTab.边框;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    tipControl.showLoading();
    // 获取类目内容
    const [error, data] = await fetchMaterialList({type: '03', file_type: '01'});
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    const frameData =  data.map((item: any, index: number) => {
      const list = item.material_sorts.map((element: any) => (
        {
          id: element.id,
          title: element.title,
          image: processURL(element.thumbnail_url),
          selected: false,
          value: processURL(element.file_url),
        }
      ));
      return {
        title: item.material_group_name,
        selected: index === 0,
        selectedType: SelectedType.border,
        list,
      };
    });
    return frameData;
    // return [{
    //   title: '就是这么牛111',
    //   image: 'img/frame/frame-icon-31.gif',
    //   selected: false,
    //   value: 'img/frame/frame-31.png',
    // }, {
    //   title: '恭贺新年 ',
    //   image: 'img/frame/frame-icon-30.gif',
    //   selected: false,
    //   value: 'img/frame/frame-30.png',
    // }, {
    //   title: '福运满满',
    //   image: 'img/frame/frame-icon-29.gif',
    //   selected: false,
    //   value: 'img/frame/frame-29.png',
    // }, {
    //   title: '恭喜发财',
    //   image: 'img/frame/frame-icon-28.gif',
    //   selected: false,
    //   value: 'img/frame/frame-28.png',
    // }, {
    //   title: '红包拿来',
    //   image: 'img/frame/frame-icon-27.gif',
    //   selected: false,
    //   value: 'img/frame/frame-27.png',
    // }, {
    //   title: '炫彩冬日',
    //   image: 'img/frame/frame-icon-26.gif',
    //   selected: false,
    //   value: 'img/frame/frame-26.png',
    // }, {
    //   title: '牛转乾坤',
    //   image: 'img/frame/frame-icon-23.gif',
    //   selected: false,
    //   value: 'img/frame/frame-23.png',
    // }, {
    //   title: '回家过年',
    //   image: 'img/frame/frame-icon-24.gif',
    //   selected: false,
    //   value: 'img/frame/frame-24.png',
    // }, {
    //   title: '元旦安康',
    //   image: 'img/frame/frame-icon-25.gif',
    //   selected: false,
    //   value: 'img/frame/frame-25.png',
    // },{
    //   title: '恶搞圣诞',
    //   image: 'img/frame/frame-icon-1.gif',
    //   selected: false,
    //   value: 'img/frame/frame-1.png',
    // }, {
    //   title: '穿越星际',
    //   image: 'img/frame/frame-icon-2.gif',
    //   selected: false,
    //   value: 'img/frame/frame-2.png',
    // }, {
    //   title: '胶片',
    //   image: 'img/frame/frame-icon-3.gif',
    //   selected: false,
    //   value: 'img/frame/frame-3.png',
    // }, {
    //   title: '小清新',
    //   image: 'img/frame/frame-icon-4.gif',
    //   selected: false,
    //   value: 'img/frame/frame-4.png',
    // }, {
    //   title: '仙鹤',
    //   image: 'img/frame/frame-icon-5.gif',
    //   selected: false,
    //   value: 'img/frame/frame-5.png',
    // }, {
    //   title: '小兔子',
    //   image: 'img/frame/frame-icon-6.gif',
    //   selected: false,
    //   value: 'img/frame/frame-6.png',
    // }, {
    //   title: '水晶球',
    //   image: 'img/frame/frame-icon-7.gif',
    //   selected: false,
    //   value: 'img/frame/frame-7.png',
    // }, {
    //   title: '冬日快乐',
    //   image: 'img/frame/frame-icon-8.gif',
    //   selected: false,
    //   value: 'img/frame/frame-8.png',
    // }, {
    //   title: '杂志',
    //   image: 'img/frame/frame-icon-9.gif',
    //   selected: false,
    //   value: 'img/frame/frame-9.png',
    // }, {
    //   title: 'fashion',
    //   image: 'img/frame/frame-icon-10.gif',
    //   selected: false,
    //   value: 'img/frame/frame-10.png',
    // }, {
    //   title: '我很红',
    //   image: 'img/frame/frame-icon-11.gif',
    //   selected: false,
    //   value: 'img/frame/frame-11.png',
    // }, {
    //   title: '梵高',
    //   image: 'img/frame/frame-icon-12.jpg',
    //   selected: false,
    //   value: 'img/frame/frame-12.png',
    // }, {
    //   title: '云朵',
    //   image: 'img/frame/frame-icon-13.gif',
    //   selected: false,
    //   value: 'img/frame/frame-13.png',
    // }, {
    //   title: '科技',
    //   image: 'img/frame/frame-icon-14.gif',
    //   selected: false,
    //   value: 'img/frame/frame-14.png',
    // }, {
    //   title: '星云',
    //   image: 'img/frame/frame-icon-15.gif',
    //   selected: false,
    //   value: 'img/frame/frame-15.png',
    // }, {
    //   title: '星云炫光',
    //   image: 'img/frame/frame-icon-16.gif',
    //   selected: false,
    //   value: 'img/frame/frame-16.png',
    // }, {
    //   title: '最亮的崽',
    //   image: 'img/frame/frame-icon-17.gif',
    //   selected: false,
    //   value: 'img/frame/frame-17.png',
    // }, {
    //   title: '青花瓷',
    //   image: 'img/frame/frame-icon-18.gif',
    //   selected: false,
    //   value: 'img/frame/frame-18.png',
    // }, {
    //   title: '荷花',
    //   image: 'img/frame/frame-icon-19.gif',
    //   selected: false,
    //   value: 'img/frame/frame-19.png',
    // }, {
    //   title: 'lv',
    //   image: 'img/frame/frame-icon-20.gif',
    //   selected: false,
    //   value: 'img/frame/frame-20.png',
    // }, {
    //   title: '梵高光效',
    //   image: 'img/frame/frame-icon-21.gif',
    //   selected: false,
    //   value: 'img/frame/frame-21.png',
    // }, {
    //   title: '星光',
    //   image: 'img/frame/frame-icon-22.gif',
    //   selected: false,
    //   value: 'img/frame/frame-22.png',
    // }];
  }

  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl } = params;
    console.log(`== fetchDataWithGroup ===>>>> ${JSON.stringify(group)}`);
  }
}
