/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 小程序详情展示
 */
import { RenderStrategy, RenderType } from './RenderStrategy';
import { dynamicLoadPanoXML, reloadPanoVideoXML } from '../../../panoramic/PanoramicView';
import { getPanoData } from '../../../panoramic/data';
import { delay, getQueryString, panoPicture, panoVideo } from '../../../../utils/functional';
import { processURL } from '../../../../utils/data';
import { fetchContentDetail } from '../../../../api/NetworkApi';

export class DetailsRenderStrategy extends RenderStrategy {
  protected renderType: RenderType = RenderType.DETAILS;

  public apply(params?: any) {
    const { tipControl, hooks: { setDetails } } = params;

    if (panoPicture()) delay(200).then(dynamicLoadPanoXML);
    if (panoVideo()) delay(200).then(reloadPanoVideoXML);;
    // 详情
    const contentId = getQueryString('id');

    tipControl.showLoading();
    fetchContentDetail(contentId).then(([error, result]) => {

      tipControl.dismissLoading();
      if (error) return tipControl.showToast(error);
      if (panoVideo()) {
        getPanoData().jsonData.scene[0].poster = processURL(result.resource_list[0].poster_url || result.resource_list[0].thumbnail_url);
      }
      // 设置详情界面数据
      setDetails(result);
    });
  }
}
