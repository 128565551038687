/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export enum MPType {
  show = 'show',
  edit = 'edit',
  default = 'default',
  detail = 'detail',
  share = 'share',
  panoPush = "panoPush",
}

export enum FooterType {
  button = 0,
  tab,
  text,
  nothing,
  push,
}




export interface FloatViewConfig {
  footerType: FooterType;
  setRefresh?: any;
};
