/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';

export function SizedBox(props: any) {
  const style = props.style || styles.container;
  return (
    <div style={style.container}></div>
  );
}

const styles: any = {
  container: {
    height: 15,
  },
};

export default SizedBox;
