/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 渲染策略
 */

export enum RenderType {
  DEFAULT = 'DEFAULT',
  DETAILS = 'DETAILS',
  TEMPLATE = 'TEMPLATE',
  USER_WORKS = 'USER_WORKS',
}

export abstract class RenderStrategy {
  protected abstract renderType: RenderType;

  public abstract apply(params?: any): any;
}
