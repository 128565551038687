/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { get, post, postFormData } from '../utils/network';
import { getQueryString, panoRenderType } from '../utils/functional';
import { TemplateType } from '../components/panoramic/template';

/**
 * 获取配乐类目
 */
export async function fetchStationList(params?: any) {
  return post('/wechat/service-market', {
    channel_type_code: '05',
    service: 'wxe22270cb76412267',
    api: 'authorizedMusicExpress',
    data: {
      action: 'DescribeStations',
      data: {
        length: 17,
        offset: 0,
      },
    },
  });
}

/**
 * 获取类目下的配乐列表
 */
export async function fetchStationItemList(categoryID: string) {
  return post('/wechat/service-market', {
    channel_type_code: '05',
    service: 'wxe22270cb76412267',
    api: 'authorizedMusicExpress',
    data: {
      action: 'DescribeItems',
      data: {
        categoryID,
        length: 30,
        offset: 0,
      },
    },
  });
}
/**
 * 根据 itemI D获取配乐 URL
 */
export async function fetchBackgroundMusicURL(itemID: string) {
  return post('/wechat/service-market', {
    channel_type_code: '05',
    service: 'wxe22270cb76412267',
    api: 'authorizedMusicExpress',
    data: {
      action: 'DescribeMusic',
      data: {
        itemID,
        identityId: 'udasw-21e12d',
      },
    },
  });
}

/**
 * 获取订单详情接口
 */
export async function fetchOrderDetail(id: string) {
  return get(`/order/detail/${id}`);
}

/**
 * 获取详情接口
 */
export async function fetchContentDetail(contentId: string) {
  return get(`/featured-content/mini-program/detail/${contentId}`);
}

/**
 * 点赞数 + 1
 */
export async function addLikeNumber({ contentId, userCode }: any) {
  return get(`/featured-content/update/likes/${contentId}/${userCode}`)
}

/**
 * 分享数 + 1
 */
export async function addShareNumber(contentId: string) {
  return get(`/featured-content/update/share/${contentId}`);
}

/**
 * 保存图片
 */
export async function savePicture(params: any) {
  if (params.userWorksId) {
    return postFormData('/user/works/update', params);
  }
  return postFormData('/user/works/create', params);
}

/**
 * 美颜
 */
export async function beauty(params: any) {
  let options: any = {
    order_id: params.id,
    beauty_alpha: parseInt(params.alpha),
  }
  if (params.userWorksId) {
    options = {
      user_works_id: params.userWorksId,
      beauty_alpha: parseInt(params.alpha),
    };
  }
  return post('/user/beauty', options);
}

/**
 * 换天空
 */
export async function changeSkyTask(params: any) {
  return post('/photo/change-sky', {
    user_product_id: params.id,
    material_id: params.skyCode,
  });
}

/**
 * 根据荐景区 ID 查询配置
 */
export async function fetchDataRecommendById(id: string) {
  return get(`/scenic-area/recommend/material/${id}`);
}

/**
 * 根据作品 ID 查询配置
 */
export async function fetchDataConfigById(id: string) {
  return get(`/user/photo-works/${id}`);
}

/**
 * 根据订单 ID 查询订单详情
 */
export async function fetchOrderDetailsById(id: string) {
  return get(`/user/photo-works/${id}`);
}

/**
 * 获取转赠照片 Key 值
 */
export async function fetchGivenKey(params: any) {
  return post('/user/photo-share/generate', {
    user_product_id: params.id,
  });
}


/**
 * 获取原图路径
 */
export async function getOriginImagePath() {
  const path = getQueryString('initvars.mypath');
  const id = getQueryString('orderId');
  if (panoRenderType() === TemplateType.sphere && id) {
    // 去掉后缀, 重新组装获取原图路径
    const [error, result] = await fetchOrderDetailsById(id);
    if (error) return [error, undefined];

    if (!result.photo_url) return [null, path];
    if (result.photo_url.split('?initvars.mypath=').length === 1) return [null, path];

    const newPath = result.photo_url.split('?initvars.mypath=')[1];
    return [null, newPath];
  }
  return [null, path];
}


/**
 * 玩图素材列表
 */
export async function fetchMaterialList(params: any) {
  return post('/material/mini-program/list', {
    ...params,
  });
}

/**
 * 更新玩图素材使用量
 */
export async function fetchMaterialUsageUpdate(params: any) {
  let materialIds = [];
  if (params.scene[0].video) {
    materialIds = [
      params.topimg.id,
    ].filter((item: any) => item);
  } else {
    const stickerIds = params.scene[0].sticker.map((item: any) => item.id);
    materialIds = [
      params.scene[0].sky,
      params.snow.id,
      params.topimg.id,
      ...stickerIds,
    ].filter((item: any) => item);
  }
  return post('/material/update/used', {
    material_ids: materialIds,
  });
}

/**
 * 模板分类
 */
export async function fetchTemplateTypeList() {
  return get('/featured-content/category/all');
}

/**
 * 模板
 */
export async function fetchTemplateList(category: any = '', type: any = '05') {
  const params = {
    is_mini_pro: "mp",
    page_no: 1,
    page_size: 0,
    category,
    type,
  }
  return post('/featured-content/list', {
    ...params,
  });
}

/**
 * 模板 返回模板所在分组
 */
export async function fetchTemplateGroup(typeCode: any) {
  const params = {
    page_no: 1,
    page_size: 0,
    type: typeCode === '01' ? '04' : '05',
    category: '0', // 查询以分组的所有模版
  }
  return post('/featured-content/list', {
    ...params,
  });
}

/**
 * 模板使用量
 */
export async function fetchModalUsedUpdate(id: string) {
  return get(`/featured-content/update/used/${id}`);
}

/**
 * 退款原因
 */
export async function fetchOrderRefundReason() {
  return get('/common/constant/order.refund.reason');
}
/**
 * 是否可以退款
 */
export async function fetchCheckRefund(params: any) {
  return post('/user/refund-time/check', {
    ...params,
  });
}
/**
 * 重拍
 */
export async function fetchRepeat(params: any) {
  return post('/order/repeat', {
    ...params,
  });
}
/**
 * 投稿
 */
export async function fetchContribution(params: any) {
  return post('/user/contribute', {
    contribute_list: params,
  });
}

/*
 *  保存图片 不可退费
*/

export async function saveTheImage(id: any) {
  return post('/order/user/store/flag', {
    orderId: id
  })
}

/*
 *  清空用户累计拍摄次数
*/
export async function EmptyUserRewards(id: any | string) {
  return post('/order/user/rewards/reset', {
    orderId: id
  })
}

/*
 *  FFmpeg视频转码
*/
export async function transcode(params: any) {
  return post('/photo/transcode', params)
}

export async function miniVideo(params: any) {
  return post('/photo/ai/photo/render', params)
}