import React from 'react'
import Modal from '../../modal/Modal';

function Cruise(props: any) {
    const { screenshotData, show } = props;
    const handleSelectedChange = (item: any) => {
        props.onSelectedChange && props.onSelectedChange(item)
    }

    return (
        show &&
        <Modal>
            <div style={styles.container}>
                {
                    screenshotData.map((item: any) => {
                        return (
                            <div style={styles.divBox} onClick={() => { handleSelectedChange(item.tag) }}>
                                <img style={styles.image} src={item.icon} alt="" />
                                <span>{item.title}</span>
                            </div>
                        )
                    })
                }
            </div>
        </Modal>
    )
}
const styles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        height: 120,
        bottom: 90,
        right: 55,
        zIndex: 1001,
        // backgroundColor: 'rgba(0,0,0,0.3)',
        justifyContent: 'space-around'
    },
    divBox: {
        // flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        color: 'white',
        width: 66,
        height: 66
    },
    image: {
        width: 30,
        height: 30
    }
};
export default Cruise