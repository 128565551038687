/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { RenderStrategy, RenderType } from './RenderStrategy';
import { DefaultRenderStrategy } from './DefaultRenderStrategy';
import { DetailsRenderStrategy } from './DetailsRenderStrategy';
import { TemplateRenderStrategy } from './TemplateRenderStrategy';
import { UserWorksRenderStrategy } from './UserWorksRenderStrategy';
import {
  shouldShowDetail,
  isUserWorks,
  isTemplate,
  isSameTemplate,
} from '../../../../utils/functional';

export { RenderStrategy, RenderType } from './RenderStrategy';

const defaultStrategy = new DefaultRenderStrategy();

const strategies: any = {
  [RenderType.DEFAULT]: defaultStrategy,
  [RenderType.DETAILS]: new DetailsRenderStrategy(),
  [RenderType.TEMPLATE]: new TemplateRenderStrategy(),
  [RenderType.USER_WORKS]: new UserWorksRenderStrategy(),
};

export function createStrategy(renderType: RenderType = renderTypePredicate()): RenderStrategy {
  return strategies[renderType] || defaultStrategy;
}

export function renderTypePredicate() {
  if (!isUserWorks() && !shouldShowDetail() && !isTemplate()) {
    return RenderType.DEFAULT;
  } else if (isTemplate() || isSameTemplate()) { // 模板 || 玩同款
    return RenderType.TEMPLATE;
  } else if (shouldShowDetail()) { // 小程序详情
    return RenderType.DETAILS;
  } else { // 小程序作品
    return RenderType.USER_WORKS;
  }
}

















