/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import Square from '../../square/Square';
import { Color } from '../../../constant/Color';
import { SelectedType, SelectedTab } from '../data/dataPicture';

const headerModel = {
  headerVisible: false,
  selectedItem: {}
};

export function GridContent(props: any) {
  const { selectedTab, dataList, ContentHeader, headerControlBySelf, groupFlag } = props;
  const [selectedItem, setSelectedItem] = useState(headerModel.selectedItem || {});
  const [headerVisible, setHeaderVisible] = useState(headerModel.headerVisible || false);
  let selectedGroup = dataList.find((item: any) => item.selected === true) || {};
  function handleGroupTitleClick(group: any) {
    return () => {
      if (group.selected === true) return;

      const newDataList = dataList.map((group: any) => ({...group, selected: false}));
      const targetGroup = newDataList.find((g: any) => g.title === group.title);
      targetGroup.selected = true;

      props.onGroupTitleClick && props.onGroupTitleClick(selectedTab, targetGroup);
      props.updateData && props.updateData(newDataList);
    };
  }

  function handleItemClick(item: any) {
    return () => {
      const newGroupDataList = selectedGroup.list.map((item: any) => ({...item, selected: false}));
      const targetItem = newGroupDataList.find((it: any) => (it.id || it.title) === (item.id || item.title));
      targetItem.selected = true;
      selectedGroup.list = newGroupDataList;

      headerControlBySelf && (headerModel.headerVisible = true);
      headerControlBySelf && setHeaderVisible(true);
      headerModel.selectedItem = targetItem;
      setSelectedItem(targetItem);
      props.onItemClick && props.onItemClick(targetItem, selectedTab);
      const newDataList = dataList.map((it: any) => it.title === selectedGroup.title ? selectedGroup : it);      
      console.log(newDataList);
      props.updateData && props.updateData(newDataList);
    }
  }
  function handleConfirm() {
    props.onConfirm && props.onConfirm(selectedItem, selectedTab);

    headerControlBySelf && (headerModel.headerVisible = false);
    headerControlBySelf && setHeaderVisible(false);
  }
  function handleCancel() {
    props.onCancel && props.onCancel(selectedTab);
    headerModel.selectedItem = {};
    setSelectedItem({});
    headerControlBySelf && (headerModel.headerVisible = false);
    headerControlBySelf && setHeaderVisible(false);
  }
  return (
    <div style={styles.container}>
      {
        headerControlBySelf && headerVisible ?
        <ContentHeader
          selectedItem={selectedItem}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
        :
        <>
          <div style={!groupFlag ? styles.titleGroup : styles.titleNone}>
            {dataList.map((data: any, index: number) => {
              const combinedStyle = { ...styles.title, ...data.selected ? {} : styles.unSelectedTitle };
              const style = index === 0 ? { ...styles.titleContainer, ...styles.titleMargin, marginLeft: 15,} : { ...styles.titleContainer, ...styles.titleMargin, };
              return (
                <div key={data.title} style={style}>
                  <div style={combinedStyle} onClick={handleGroupTitleClick(data)}>{data.title}</div>
                </div>
              );
            })}
          </div>
          <div style={styles.contentContainer}>
            {
              selectedGroup.list && selectedGroup.list.map((item: any) => {
                // 选中效果: 添加边框
                const combinedStyle = {...styles.image, ...item.selected ? styles.selectedItem : {}};
                return (
                  <Square
                    key={item.id || item.title}
                    image={item.image}
                    title={item.title}
                    style={styles.margin}
                    imageStyle={combinedStyle}
                    onClick={handleItemClick(item)}
                  />
                );
              })
            }
          </div>
        </>
      }
    </div>
  );
}

const styles: any = {
  container: {
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexWrap: 'wrap',
    marginBottom: 15,
    paddingLeft: 20,
    maxHeight: 150,
  },
  titleNone: {
    display: "none"
  },
  titleGroup: {
    display: 'flex',
    overflow: 'auto',
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
  },
  title: {
    color: Color.orange,
    fontSize: 15,
    whiteSpace: 'nowrap',
  },
  unSelectedTitle: {
    color: Color.grayFont,
    fontSize: 15,
  },
  titleMargin: {
    marginRight: 10,
  },
  margin: {
    marginTop: 10,
  },
  selectedItem: {
    border: `3px solid ${Color.orange}`,
  },
  image: {
    width: 50,
    height: 50,
  },
};

export default GridContent;
