/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState } from 'react';
import ImageModal from '../../modal/ImageModal';
import MiniLayout from './MiniLayout';
import { getQueryString } from '../../../utils/functional';

const initialState = {
  visible: false,
  title: '',
  image: '',
};

export function QRIconLayout(props: any) {
  const panoAdmin = getQueryString('admin');
  const [modalData, setModalData] = useState(initialState);
  function handleClick(prop: any) {
    if (prop.tag === 'mini-program') {
      setModalData({
        visible: true,
        title: '长按识别二维码，解锁更多玩法',
        image: 'img/qr-mini-program.png',
      });
    } else if (prop.tag === 'official') {
      setModalData({
        visible: true,
        title: '长按识别二维码，发现更多精彩',
        image: 'img/qr-official.jpeg',
      });
    } else if (prop.tag === 'scenic') {
      setModalData({
        visible: true,
        title: prop.item.desc,
        image: prop.item.image_url,
      });
    }
  }
  function handleClose() {
    setModalData(initialState);
  }
  function toOfficial() {
    // 生产环境
    window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI2MjM4MDE2MQ==&scene=124#wechat_redirect';

    // 测试环境
    // window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU5MzcwODM4OQ==&scene=124#wechat_redirect';
  }
  return (
    <>
      <ImageModal
        visible={modalData.visible}
        title={modalData.title}
        image={modalData.image}
        onClose={handleClose}
      />
      <div style={styles.container}>
        {
          props.data.map((item: any) => {
            return <QRIcon item={item} tag="scenic" title={item.name} icon={item.icon_url} onClick={handleClick} />
          })
        }
        {
          !panoAdmin &&
          <>
            <MiniLayout />
            {/* <QRIcon tag="mini-program" title="点我去修图" icon="img/ic-mini-program.png" onClick={handleClick} /> */}
            <QRIcon tag="official" title="进入公众号" icon="img/ic-official.png" onClick={toOfficial} />
          </>
        }
      </div>
    </>
  );
}

export function QRIcon(props: any) {
  function handleClick() {
    props.onClick && props.onClick(props);
  }
  return (
    <div style={styles.item} onClick={handleClick}>
      <img src={props.icon} alt="" style={styles.itemIcon} />
      <p style={styles.itemText}>{props.title}</p>
    </div>
  );
}

const styles: any = {
  container: {
    position: 'absolute',
    bottom: 65,
    right: 5,
    width: 42,
    zIndex: 1000,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  itemIcon: {
    width: 30,
    height: 30,
  },
  itemText: {
    marginTop: 3,
    fontSize: 8,
    color: 'white',
  },
};

export default QRIconLayout;
