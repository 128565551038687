/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React, { useRef, useEffect } from 'react';
import Modal from './Modal';
import { Color } from '../../constant/Color';



const VideoComponent = (props: any) => {
  const videoRef = useRef(null);
  // 获取视频文件的后缀名
  const getFileExtension = (filename: any) => {
    return filename.split('.').pop().toLowerCase();
  };

  // 根据视频文件的后缀名确定 MIME 类型
  const getMimeType = (extension: any) => {
    console.log('extension :', extension);

    switch (extension) {
      case 'mp4':
        return 'video/mp4';
      case 'avi':
        return 'video/avi';
      case 'webm':
        return 'vidoe/webm';
      // 可以根据需要添加其他视频格式的支持
      default:
        return 'video/mp4';
    }
  };

  const videoSrc = props.src;
  const fileExtension = getFileExtension(videoSrc);
  const mimeType = getMimeType(fileExtension);

  useEffect(() => {
    const videoElement: any = videoRef.current;
    // 当视频加载完成后，获取第一帧并设置为封面
    videoElement.addEventListener('loadedmetadata', () => {
      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();
      console.log('dataURL :', dataURL);

      videoElement.poster = dataURL;
    });
    // 清理副作用
    return () => {
      videoElement.removeEventListener('loadedmetadata', () => { });
    };
  }, [props.src]);
  console.log('mimeType :', mimeType);

  return (
    <video
      ref={videoRef}
      controls
      autoPlay
      loop
      style={styles.video}
    >
      <source src={props.src} type={mimeType} />
      Your browser does not support the video tag.
    </video>
  );
};


export function Dialog(props: any) {
  return (
    props.show &&
    <Modal>
      <div style={styles.container}>
        <div style={styles.contentContainer}>
          <div style={styles.content}>
            {/* <video
              controls
              autoPlay
              loop
              style={styles.video}
            >
              <source src={props.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            {/* https://file-test.runbo360field.com/photos/temp/20240605172703429.avi?Expires=1717581455&OSSAccessKeyId=LTAI5tNJgLjcDGGmxu7n5LmT&Signature=vgTQITVZIKnZAIWtBC4fABKYVf0%3D */}
            {/* src={props.src} */}
            <VideoComponent src={props.src} />
          </div>
          <div style={styles.line} />
          <div style={styles.buttonContainer}>
            {props.onCancel && <div style={styles.button} onClick={props.onCancel}>{props.negativeTitle || '取消'}</div>}
            <div style={styles.positiveButton} onClick={props.onConfirm}>{props.positiveTitle || '确定'}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(2px)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1009,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Color.moduleBgColor,
    paddingTop: 30,
    width: '80%',
    borderRadius: 10,
  },
  content: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    color: "#D5D5D5FF",
    fontWeight: '600',
    textAlign: 'center',
  },
  line: {
    height: 1,
    marginTop: 20,
    backgroundColor: Color.line,
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: '#D5D5D5FF',
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
  },
  positiveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: Color.orange,
    fontWeight: '600',
    paddingTop: 12,
    paddingBottom: 12,
  },
  video: {
    width: '80%',
    height: '80%'
  }
};

export default Dialog;
