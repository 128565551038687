/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
 import { SelectedType } from '../data/dataVideo';
 import { processURL } from '../../../utils/data';
 
 /**
  * 组合模板数组
  */
 export function combineDataList(dataList: any[], itemDataList: any[], categoryID: string, itemID: string): any[] {
   return dataList.map((item: any, index: number) => {
    // const reset = {
    //   // title: '',
    //   id: '0',
    //   image: 'img/origin-image.png',
    //   selected: itemID ? false : true,
    //   value: '',
    // };
    const list = itemDataList.map((element: any, index: any) => (
      {
        title: element.template_name,
        id: element.resource.content_id,
        groupId: categoryID,
        image: templateFunAfter(element),
        selected: itemID === element.resource.content_id,
        value: element.resource.content_id,
      }
    ));
    // list.unshift(reset);
    return {
      title: item.name,
      id: item.id,
      groupId: categoryID,
      selected: item.id === categoryID,
      selectedType: SelectedType.border,
      list: item.id === categoryID ? list : []
      // list,
    };
  });
 }
 
 /**
  * 模板数组
  */
 export function combineDataListWithDataList(dataList: any[], itemDataList: any[], categoryID: string) {
   const target = dataList.find((item: any) => item.id === categoryID);
   let noBGMFlag;
   dataList.forEach((item: any) => {
     const tmp = item.list.find((it: any) => it.id === '0' && it.selected === true);
     if (tmp) noBGMFlag = tmp;
   });
   target.list = [
    //  {
    //     id: '0',
    //     image: 'img/origin-image.png',
    //     selected: noBGMFlag !== undefined,
    //     value: '',
    //  },
     ...itemDataList.map((item: any, index: any) => ({
        title: item.template_name,
        id: item.resource.content_id,
        groupId: categoryID,
        image: templateFunAfter(item),
        selected: false,
        value: item.resource.content_id,
     }))
   ];
 
   return dataList.map((item: any) => ({
     ...item,
     selected: item.id === categoryID,
   }));
 }


 /**
 * 组合配乐数组
 */
export function BGMDataList(dataList: any[], itemDataList: any[], categoryID: string, itemID: string): any[] {
  return dataList.map((item: any, index: number) => ({
    ID: item.categoryID,
    title: item.name.split('_')[1],
    selected: item.categoryID === categoryID,
    selectedType: SelectedType.border,
    list: item.categoryID === categoryID ? [
      // {
      //   title: '无配乐',
      //   image: 'img/ic-no-bgm.png',
      //   selected: itemID ? false : true,
      //   value: 'no-bgm',
      // },
      ...itemDataList.map((it: any) => ({
        groupID: item.categoryID,
        title: it.datainfo.name,
        image: it.album.imagePathMap[0].value,
        selected: it.itemID === itemID,
        value: it.itemID,
      }))
    ] : [],
  }));
}

/**
 * 组合配乐数组
 */
export function BGMDataListWithDataList(dataList: any[], itemDataList: any[], categoryID: string) {
  const target = dataList.find((item: any) => item.ID === categoryID);
  let noBGMFlag;
  dataList.forEach((item: any) => {
    const tmp = item.list.find((it: any) => it.title === '无配乐' && it.selected === true);
    if (tmp) noBGMFlag = tmp;
  });
  target.list = [
    // {
    //   title: '无配乐',
    //   image: 'img/ic-no-bgm.png',
    //   selected: noBGMFlag !== undefined,
    //   value: 'no-bgm',
    // },
    ...itemDataList.map((item: any) => ({
      id: item.itemID,
      groupID: categoryID,
      title: item.datainfo.name,
      image: item.album.imagePathMap[0].value,
      selected: false,
      value: item.itemID,
    }))
  ];

  return dataList.map((item: any) => ({
    ...item,
    selected: item.ID === categoryID,
  }));
}

// 修片后获取图片url
function templateFunAfter(item: any) {
  if (item.type === "04" || item.type === "05") {
    const list = item.template_cover_vo.filter((it: any) => it.push_url);
    const list2 = item.template_cover_vo.filter((it: any) => it.thumbnail_url);
    return processURL(list[0]
      ? list[0].push_url
      : list2[0]
      ? list2[0].thumbnail_url
      : "");
  }
};