/**
 * 没有footer，页面内容特殊处理
 */
import { useState, useEffect } from 'react';
import { getQueryString } from "../../utils/functional";
export function NothingFooter(props: any) {
  const { setMenuHeight } = props;
  
  useEffect(() => {
    menuHeight();
  },[]);

  // menu按钮高度
  function menuHeight() {
    const page = getQueryString("page");
    // 套餐样片
    if (page === 'mealSampleFilm') {
      setMenuHeight(130);
    }
  }
  return (
    <></>
  );
}

export default NothingFooter;
