/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import Tab from './Tab';
// import { tabs } from './data';
import { Color } from '../../constant/Color';

export function TabContainer(props: any) {
  const { selectedTab, tabs, style } = props;
  const [tabsState, setTabsState] = useState(tabs);

  useEffect(() => {
    const newTabs = tabsState.map((tab: any) => ({...tab, selected: false}));
    const tabIndex = newTabs.findIndex((tab: any) => tab.title === selectedTab);
    const tab = newTabs[tabIndex];
    if (tab) {
      tab.selected = true;
    }
    setTabsState(newTabs);
  }, [selectedTab]);

  function handleTabClick(tag: string) {
    const t = tabsState.find((tab: any) => tab.title === tag);
    if (t?.selected) return;

    props.onTabClick && props.onTabClick(tag);
  }
  return (
    <div style={styles.container}>
      <div style={(style && style.tabContainer) || styles.tabContainer}>
        {tabsState.map((tab: any) => (
          <Tab
            key={tab.title}
            title={tab.title}
            image={tab.image}
            selected={tab.selected}
            onClick={handleTabClick}
            style={style}
          />
        ))}
      </div>
      <div style={styles.saveLayout} onClick={props.onSave}>
        {/* <img src="img/ic-save.png" alt="" style={styles.saveImage}/> */}
        <div style={styles.save}>保存作品</div>
      </div>
    </div>
  );
}

const styles: any = {
  container: {
    // display: 'flex',
    paddingLeft: 10,
    marginBottom: 10,
    // justifyContent: 'space-between',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    // marginRight: 10,
  },
  saveLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
  saveImage: {
    width: 15,
    height: 15,
  },
  save: {
    display: 'flex',
    color: 'white',
    fontSize: 14,
    backgroundColor: Color.orange,
    borderRadius: 20,
    padding: '5px 30px',
  },
};

export default TabContainer;
