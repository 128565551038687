/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { getPanoData } from './data';
import { getQueryString } from '../../utils/functional';
import { processURL } from '../../utils/data';
import { sound } from './functional';

export enum TemplateType {
  cube = 'cube',
  sphere = 'sphere',
}
// <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="500,2000" />
// <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="720,2880,1440,720" />
//  <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="960,3840" /> // 三代设备

/*
  <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" />
  %s 占位符展示图片 f是前 b是后 l是左 r是右 u是上 d是下
*/

/**
 * 二代设备：
 * preview striporder="LFRBUD" preview.jpg拼接顺序为“左前右后上下”
 * 正前方角度hlookat为 135.0
 * 三代代设备:
 * preview striporder="FLBRUD" preview.jpg拼接顺序为“前左后右上下”
 * 正前方角度hlookat为 45.0
 * @returns 
 */
export function targetTemplate() {
  const skyPreview = processURL(getPanoData().skyPreview.sphereurl); // 换天预览模式
  const scenes = getPanoData().scene.map((item: any) => {
    const code = getQueryString("deviceAppCode") || item.deviceAppCode || "";
    const deviceAppCode = code.indexOf('app_code_3');
    const hlookat = deviceAppCode > -1 ? 45.0 : 135.0; // 二代设备/三代设备视角
    const jpg = item.url.indexOf('jpg');
    const png = item.url.indexOf('png');
    item.url = item.url.replace('file.test', 'file-test');
    item.sphereurl = processURL(item.sphereurl).replace('file.test', 'file-test');
    const imageTemplate = skyPreview || item.sphereurl || jpg > -1 || png > -1 ?
      `
     <image>
       <sphere url="${skyPreview || item.sphereurl || item.url}"/>
     </image>
     ` :
      `
    <preview url="${item.url}/preview.jpg" striporder="${deviceAppCode > -1 ? "FLBRUD" : "LFRBUD"}" />
    <image stereo="true" stereolabels="l|r" stereooffset="2.05" prealign="100">
      <cube url="${item.url}/%s/l%l/%v/l%l_%s_%v_%h.jpg" multires="${deviceAppCode > -1 ? '960,3840' : '500,2000'}" />
    </image>
     `;
    return `
     <scene name="scene_${item.id}" title="${item.title}" onstart="" thumburl="${item.url}tiny_planet.jpg" lat="" lng="" heading="">
       <view hlookat="${hlookat}"  vlookat="0.0" fovtype="MFOV" fov="90" fovmin="20" fovmax="180" limitview="auto" />
       ${imageTemplate}
       ${sound(hlookat, 'picture')}
     </scene>
     `;
  }).join('');
  console.log(`
     <krpano>
       <include url="pano.xml" />
       ${scenes}
     </krpano>
   `);
  return `
     <krpano>
       <include url="pano.xml" />
       ${scenes}
     </krpano>
   `;
}
