/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */

export enum Destination {
  /* 隐藏 MusicControl */
  UPDATE_BGM_ITEM = 'UPDATE_BGM_ITEM',
  /* 更新 Pano 样式 */
  UPDATE_PANO_STYLE = 'UPDATE_PANO_STYLE',
}

export class Wormhole {
  private events: any;

  private static INSTANCE: Wormhole;
  public static getInstance() {
    if (!this.INSTANCE) {
      this.INSTANCE = new Wormhole();
    }
    return this.INSTANCE;
  }

  private constructor() {
    this.events = this.events || new Object();
  }

  /**
   * 进入虫洞
   */
  public enter(type: any, ...args: any) {
    const e = this.events[type];
    // 查看这个 type 的 event 有多少个回调函数，如果有多个需要依次调用。
    if (Array.isArray(e)) {
      for (let i = 0; i < e.length; i++) {
        e[i].apply(this, args);
      }
    } else if (e) {
      e.apply(this, args);
    } else {
      console.log(`${type} has not been registered.`);
    }
  }

  /**
   * 目的地(进入虫洞后到达目的地)
   */
  public destination(type: any, fun: any) {
    const e = this.events[type];
    let currentIndex = -1;
    if (!e) {
      // 如果从未注册过监听函数，则将函数放入数组存入对应的键名下
      this.events[type] = [fun];
      currentIndex = 0;
    } else {
      // 如果注册过，则直接放入
      e.push(fun);
      // 获取当前组件监听函数，在观察函数数组中的索引，移除监听时使用
      currentIndex = this.events[type].length - 1;
    }
    return { type, index: currentIndex };
  }

  /**
   * 关闭虫洞
   */
  public close(subscribe: any) {
    const { type, index } = subscribe;
    let e = this.events[type];
    // 查看这个 type 的 event 有多少个回调函数，如果有多个需要依次调用。
    if (Array.isArray(e)) {
      // 监听的函数为空，则空处理
      if (e.length === 0) {
        return;
      } else if (e.length === 1) {
        // 只有一个监听的函数，则直接移除监听
        e.splice(0, 1);
      } else {
        // 如果同一个 key 存在多个监听函数，只移除当前组件监听函数
        for (let i = 0; i < e.length; i++) {
          if (index > 0 && i === index) {
            e.splice(index, 1);
          }
        }
      }
    } else {
      e = [];
    }
  }

  /**
   * 关闭所有虫洞
   */
  public closeAll() {
    // 移除所有监听函数
    if (this.events.length > 0) {
      this.events.length = 0;
    }
  }
}
