/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 小程序 "详情" Footer
 */
import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { panoSize } from '../panoramic/data'
import Button from '../button/Button';
import { Color } from '../../constant/Color';


export function FooterButton(props: any) {

  return (
    <div style={styles.container}>
      <div style={styles.button} onClick={props.onClick}>
        <img src={props.image} alt="" style={styles.image} />
        <div style={styles.text}>{props.title}</div>
      </div>
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.orange,
    paddingLeft: 10,
    paddingRight: 10,
  },
  image: {
    width: 20,
    height: 20,
  },
  text: {
    marginLeft: 5,
    color: Color.fontColor,
  },
};

export default FooterButton;
