/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import React from 'react';
import { Color } from '../../constant/Color';

export function Tab(props: any) {
  const selected = props.selected;
  const style = props.style || {};
  function handleClick() {
    props.onClick && props.onClick(props.title);
  }

  // const image = selected ? props.image.replace(/-unchecked/, '') : `${props.image.replace(/\.png/, '')}-unchecked.png`;
  const textStyle = selected ? style.text || styles.text : style.unSelectedText || styles.unSelectedText;
  return (
    <div style={styles.container} onClick={handleClick}>
      <img src={props.image} alt="" style={styles.image} />
      <div style={textStyle}>{props.title}</div>
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    minWidth: 30,
  },
  image: {
    width: 40,
    height: 40,
  },
  text: {
    fontSize: 10,
    marginTop: 5,
  },
  unSelectedText: {
    fontSize: 10,
    marginTop: 5,
    color: Color.fontColor,
  },
};

export default Tab;
