/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 配乐策略
 */
import { Base64 } from 'js-base64';
import { SelectedTab } from '../../data/dataPicture';
import { Strategy } from '../Strategy';
import { fetchStationList, fetchStationItemList } from '../../../../api/NetworkApi';
import { getPanoData } from '../../../panoramic/data';
import { combineDataList, combineDataListWithDataList } from '../../../../utils/data';
import { delay } from '../../../../utils/functional';
import { Destination, Wormhole } from '../../../../vendor/wormhole/index';

export class BGMStrategy extends Strategy<SelectedTab> {
  protected selectedTab: SelectedTab = SelectedTab.配乐;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    tipControl.showLoading();
    // 获取类目内容
    const [error, data] = await fetchStationList();
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    let resultData;
    try {
      resultData = JSON.parse(Base64.decode(data.data));
    } catch (error) {
      console.log(error)
      return;
    }
    const dataList = resultData.data.stations;

    const bgmItem = getPanoData().bgmItem;
    const initCategoryID = bgmItem.groupID || dataList[0].categoryID; // 获取初始化分类内容id
    tipControl.showLoading();
    const [itemError, itemData] = await fetchStationItemList(initCategoryID);
    tipControl.dismissLoading();
    if (itemError) return tipControl.showToast(itemError);
    
    const itemResultData = JSON.parse(Base64.decode(itemData.data));
    // 请求失败后, 重新再次请求
    if (!itemResultData.data) {
      // 获取背景音乐失败后, 置空背景音乐, 重新获取列表
      getPanoData().bgmItem = {};
      Wormhole.getInstance().enter(Destination.UPDATE_BGM_ITEM, getPanoData().bgmItem);

      tipControl.showLoading();
      const [itemError, itemData] = await fetchStationItemList(dataList[0].categoryID);
      tipControl.dismissLoading();
      if (itemError) return tipControl.showToast(itemError);
      // 获取背景音乐失败后, 置空背景音乐, 重新获取列表
      const itemResultData = JSON.parse(Base64.decode(itemData.data));
      if (!itemResultData.data) {
        return tipControl.showToast(`${itemResultData.code} - ${itemResultData.msg}`);
      }
      const itemDataList = itemResultData.data.stationItems;
      // 组装数据, 更新 dataTree
      const newList = combineDataList(dataList, itemDataList, dataList[0].categoryID, bgmItem.value);
      
      await delay(200);
      return newList;
    }
    
    const itemDataList = itemResultData.data.stationItems;
    // 组装数据, 更新 dataTree
    const newList = combineDataList(dataList, itemDataList, initCategoryID, bgmItem.value);
    await delay(200);
    return newList;
  }

  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl, } = params;
    const someOne = list.find((item: any) => item.ID === group.ID);
    if (someOne.list.length > 1) return;

    // 更新配乐
    tipControl.showLoading();
    const [error, result] = await fetchStationItemList(group.ID);
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    if (!result.data) return tipControl.showToast("此分组下无配乐！");
    const itemResultData = JSON.parse(Base64.decode(result.data));
    const itemDataList = itemResultData.data.stationItems;
    // 组装数据, 更新 dataTree
    return combineDataListWithDataList(list, itemDataList, group.ID);
  }
}
