/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 换天策略
 */
import { SelectedTab, SelectedType } from '../../data/dataPicture';
import { Strategy } from '../Strategy';
import { fetchMaterialList } from '../../../../api/NetworkApi';
import { processURL } from '../../../../utils/data';

export class SkyStrategy extends Strategy<SelectedTab> {
  protected selectedTab: SelectedTab = SelectedTab.换天;

  public async fetchData(params: any): Promise<any> {
    const { tipControl } = params;
    // 显示 Loading
    tipControl.showLoading();
    // 获取类目内容
    const [error, data] = await fetchMaterialList({type: '02', file_type: '01'});
    tipControl.dismissLoading();
    if (error) return tipControl.showToast(error);
    const skyData = data.map((item: any, index: number) => {
      // const reset = {
      //   title: '原图',
      //   image: 'img/origin-image.png',
      //   selected: true,
      //   value: 'sky-reset',
      // };
      const list = item.material_sorts.map((element: any) => (
        {
          title: element.title,
          image: processURL(element.thumbnail_url),
          selected: false,
          value: element.id,
        }
      ));
      // list.unshift(reset);
      return {
        title: item.material_group_name,
        selected: index === 0,
        selectedType: SelectedType.border,
        list,
      };
    });
    return skyData;
    // return [{
    //   title: '天空的云',
    //   selected: true,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     title: '原图',
    //     image: 'img/origin-image.png',
    //     selected: true,
    //     value: 'sky-reset',
    //   }, {
    //     title: '云朵1',
    //     image: 'img/sky/sky-23.jpg',
    //     selected: false,
    //     value: '23',
    //   }, {
    //     title: '云朵2',
    //     image: 'img/sky/sky-24.jpg',
    //     selected: false,
    //     value: '24',
    //   }, {
    //     title: '云朵3',
    //     image: 'img/sky/sky-25.jpg',
    //     selected: false,
    //     value: '25',
    //   }, {
    //     title: '云朵4',
    //     image: 'img/sky/sky-26.jpg',
    //     selected: false,
    //     value: '26',
    //   }, {
    //     title: '云朵5',
    //     image: 'img/sky/sky-27.jpg',
    //     selected: false,
    //     value: '27',
    //   }, {
    //     title: '云朵6',
    //     image: 'img/sky/sky-28.jpg',
    //     selected: false,
    //     value: '28',
    //   }, {
    //     title: '云朵7',
    //     image: 'img/sky/sky-29.jpg',
    //     selected: false,
    //     value: '29',
    //   }, {
    //     title: '云朵8',
    //     image: 'img/sky/sky-30.jpg',
    //     selected: false,
    //     value: '30',
    //   }, {
    //     title: '云朵9',
    //     image: 'img/sky/sky-47.jpg',
    //     selected: false,
    //     value: '47',
    //   }, {
    //     title: '云朵10',
    //     image: 'img/sky/sky-48.jpg',
    //     selected: false,
    //     value: '48',
    //   }],
    // }, {
    //   title: '创意天空',
    //   selected: false,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     title: '原图',
    //     image: 'img/origin-image.png',
    //     selected: true,
    //     value: 'sky-reset',
    //   }, {
    //     title: '创意1',
    //     image: 'img/sky/sky-1.jpg',
    //     selected: false,
    //     value: '1',
    //   // }, {
    //   //   title: '创意2',
    //   //   image: 'img/sky/sky-2.jpg',
    //   //   selected: false,
    //   //   value: '2',
    //   }, {
    //     title: '创意2',
    //     image: 'img/sky/sky-3.jpg',
    //     selected: false,
    //     value: '3',
    //   }, {
    //     title: '创意3',
    //     image: 'img/sky/sky-4.jpg',
    //     selected: false,
    //     value: '4',
    //   }, {
    //     title: '创意4',
    //     image: 'img/sky/sky-5.jpg',
    //     selected: false,
    //     value: '5',
    //   }, {
    //     title: '创意5',
    //     image: 'img/sky/sky-6.jpg',
    //     selected: false,
    //     value: '6',
    //   }, {
    //     title: '创意6',
    //     image: 'img/sky/sky-7.jpg',
    //     selected: false,
    //     value: '7',
    //   }, {
    //     title: '创意7',
    //     image: 'img/sky/sky-31.jpg',
    //     selected: false,
    //     value: '31',
    //   }, {
    //     title: '创意8',
    //     image: 'img/sky/sky-32.jpg',
    //     selected: false,
    //     value: '32',
    //   }, {
    //     title: '创意9',
    //     image: 'img/sky/sky-33.jpg',
    //     selected: false,
    //     value: '33',
    //   }, {
    //     title: '创意10',
    //     image: 'img/sky/sky-34.jpg',
    //     selected: false,
    //     value: '34',
    //   }, {
    //     title: '创意11',
    //     image: 'img/sky/sky-55.jpg',
    //     selected: false,
    //     value: '55',
    //   }],
    // }, {
    //   title: '梦幻星空',
    //   selected: false,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     title: '原图',
    //     image: 'img/origin-image.png',
    //     selected: true,
    //     value: 'sky-reset',
    //   }, {
    //     title: '星空1',
    //     image: 'img/sky/sky-8.jpg',
    //     selected: false,
    //     value: '8',
    //   }, {
    //     title: '星空2',
    //     image: 'img/sky/sky-9.jpg',
    //     selected: false,
    //     value: '9',
    //   }, {
    //     title: '星空3',
    //     image: 'img/sky/sky-10.jpg',
    //     selected: false,
    //     value: '10',
    //   }, {
    //     title: '星空4',
    //     image: 'img/sky/sky-11.jpg',
    //     selected: false,
    //     value: '11',
    //   }, {
    //     title: '星空5',
    //     image: 'img/sky/sky-12.jpg',
    //     selected: false,
    //     value: '12',
    //   }, {
    //     title: '星空6',
    //     image: 'img/sky/sky-13.jpg',
    //     selected: false,
    //     value: '13',
    //   }, {
    //     title: '星空7',
    //     image: 'img/sky/sky-14.jpg',
    //     selected: false,
    //     value: '14',
    //   }, {
    //     title: '星空8',
    //     image: 'img/sky/sky-15.jpg',
    //     selected: false,
    //     value: '15',
    //   }, {
    //     title: '星空9',
    //     image: 'img/sky/sky-37.jpg',
    //     selected: false,
    //     value: '37',
    //   }, {
    //     title: '星空10',
    //     image: 'img/sky/sky-39.jpg',
    //     selected: false,
    //     value: '39',
    //   }, {
    //     title: '星空11',
    //     image: 'img/sky/sky-40.jpg',
    //     selected: false,
    //     value: '40',
    //   }, {
    //     title: '星空12',
    //     image: 'img/sky/sky-41.jpg',
    //     selected: false,
    //     value: '41',
    //   }, {
    //     title: '星空13',
    //     image: 'img/sky/sky-42.jpg',
    //     selected: false,
    //     value: '42',
    //   }],
    // }, {
    //   title: '神圣雪山',
    //   selected: false,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     title: '原图',
    //     image: 'img/origin-image.png',
    //     selected: true,
    //     value: 'sky-reset',
    //   }, {
    //     title: '雪山1',
    //     image: 'img/sky/sky-16.jpg',
    //     selected: false,
    //     value: '16',
    //   }, {
    //     title: '雪山2',
    //     image: 'img/sky/sky-17.jpg',
    //     selected: false,
    //     value: '17',
    //   }, {
    //     title: '雪山3',
    //     image: 'img/sky/sky-18.jpg',
    //     selected: false,
    //     value: '18',
    //   }, {
    //     title: '雪山4',
    //     image: 'img/sky/sky-19.jpg',
    //     selected: false,
    //     value: '19',
    //   }, {
    //     title: '雪山5',
    //     image: 'img/sky/sky-20.jpg',
    //     selected: false,
    //     value: '20',
    //   }, {
    //     title: '雪山6',
    //     image: 'img/sky/sky-21.jpg',
    //     selected: false,
    //     value: '21',
    //   }, {
    //     title: '雪山7',
    //     image: 'img/sky/sky-22.jpg',
    //     selected: false,
    //     value: '22',
    //   }, {
    //     title: '雪山8',
    //     image: 'img/sky/sky-36.jpg',
    //     selected: false,
    //     value: '36',
    //   }, {
    //     title: '雪山9',
    //     image: 'img/sky/sky-43.jpg',
    //     selected: false,
    //     value: '43',
    //   }, {
    //     title: '雪山10',
    //     image: 'img/sky/sky-44.jpg',
    //     selected: false,
    //     value: '44',
    //   }, {
    //     title: '雪山11',
    //     image: 'img/sky/sky-45.jpg',
    //     selected: false,
    //     value: '45',
    //   }, {
    //     title: '雪山12',
    //     image: 'img/sky/sky-46.jpg',
    //     selected: false,
    //     value: '46',
    //   }],
    // }, {
    //   title: '新春快乐',
    //   selected: false,
    //   selectedType: SelectedType.border,
    //   list: [{
    //     title: '原图',
    //     image: 'img/origin-image.png',
    //     selected: true,
    //     value: 'sky-reset',
    //   }, {
    //     title: '新年1',
    //     image: 'img/sky/sky-49.jpg',
    //     selected: false,
    //     value: '49',
    //   }, {
    //     title: '新年2',
    //     image: 'img/sky/sky-50.jpg',
    //     selected: false,
    //     value: '50',
    //   }, {
    //     title: '新年3',
    //     image: 'img/sky/sky-51.jpg',
    //     selected: false,
    //     value: '51',
    //   }, {
    //     title: '新年4',
    //     image: 'img/sky/sky-52.jpg',
    //     selected: false,
    //     value: '52',
    //   }, {
    //     title: '新年5',
    //     image: 'img/sky/sky-53.jpg',
    //     selected: false,
    //     value: '53',
    //   }, {
    //     title: '新年6',
    //     image: 'img/sky/sky-35.jpg',
    //     selected: false,
    //     value: '35',
    //   }, {
    //     title: '元宵节1',
    //     image: 'img/sky/sky-56.jpg',
    //     selected: false,
    //     value: '56',
    //   }, {
    //     title: '元宵节2',
    //     image: 'img/sky/sky-57.jpg',
    //     selected: false,
    //     value: '57',
    //   }, {
    //     title: '元宵节3',
    //     image: 'img/sky/sky-58.jpg',
    //     selected: false,
    //     value: '58',
    //   }, {
    //     title: '元宵节4',
    //     image: 'img/sky/sky-59.jpg',
    //     selected: false,
    //     value: '59',
    //   }, {
    //     title: '元宵节5',
    //     image: 'img/sky/sky-60.jpg',
    //     selected: false,
    //     value: '60',
    //   }],
    // }];
  }

  public async fetchDataWithGroup(params: any): Promise<any> {
    const { list, group, tipControl } = params;
    console.log(`== fetchDataWithGroup ===>>>> ${JSON.stringify(group)}`);
  }
}
