/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { Color } from '../../constant/Color';
import { getQueryString } from '../../utils/functional';
import { Wormhole, Destination } from '../../vendor/wormhole/index';
import { panoSize } from '../panoramic/data'

export function AcceptPhotoButton(props: any) {
  const givenTime = getQueryString('givenTime');
  const time = (Number(givenTime) - new Date().getTime()) / 1000;

  const [count, setCount] = useState(time > 0 ? time : 0);
  const [countDownDesc, setCountDownDesc] = useState('');

  
  useEffect(() => {
    // 倒计时开始
    const timer = setTimeout(() => {
      if (count === -1) return;
      
      const desc = countDownFunction(count, time);
      setCountDownDesc(desc);
      
      setCount(count - 1);
      clearInterval(timer);
    }, 1000);
    
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  // 底部留黑样式
  // if (props.footerStyle) {
  //   Wormhole.getInstance().enter(Destination.UPDATE_PANO_STYLE, panoSize.detail);
  // }

  // 当倒计时结束时, 将点击事件置空
  function handleClick() {
    console.log('倒计时结束, 无法接收照片/视频');
  }

  const enableFlag = countDownDesc && count > -1;

  const handler = enableFlag ? props.onClick : handleClick;
  const buttonStyle = enableFlag ? styles.button : {...styles.button, backgroundColor: '#F7F7F7',};
  const textStyle = enableFlag ? styles.text : {...styles.text, color: '#BFBFBF',};
  const timeStyle = enableFlag ? styles.timeText : {...styles.timeText, color: '#BFBFBF',};
  const image = enableFlag ? 'img/icon/ic-given-time.png' : 'img/icon/ic-given-time-expire.png';
  const title = enableFlag ? props.title : props.invalidTitle;
  return (
    <div style={styles.container}>
      <div style={buttonStyle} onClick={handler}>
        <div style={textStyle}>{title}</div>
        <img src={image} alt="" style={styles.image} />
        <div style={timeStyle}>{countDownDesc}</div>
      </div>
    </div>
  );
}

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.orange,
    paddingLeft: 10,
    paddingRight: 10,
    // borderRadius: 25,
  },
  image: {
    width: 17,
    height: 17,
    marginLeft: 5,
    marginRight: 5,
  },
  text: {
    color: Color.fontColor,
  },
  timeText: {
    minWidth: 45,
    color: Color.fontColor,
  },
};

function countDownFunction(count: number, time: number) {
  if (count > 0 || count === 0) {
    let desc;
    if (time < 3600) {
      const minutes = Math.floor(count / 60) < 10 ? `0${ Math.floor(count / 60 % 60) }` : Math.floor(count / 60);
      const seconds = Math.floor(count % 60) < 10 ? `0${ Math.floor(count % 60) }` : Math.floor(count % 60);
      desc = `${minutes}:${seconds}`;
    } else {
      const hour = Math.floor(count / 60 / 60);
      const minutes = Math.floor(count / 60 % 60) < 10 ? `0${ Math.floor(count / 60 % 60) }` : Math.floor(count / 60 % 60);
      const seconds = Math.floor(count % 60) < 10 ? `0${ Math.floor(count % 60) }` : Math.floor(count % 60);
      desc = `${hour}:${minutes}:${seconds}`;
    }
    return desc;
  } else {
    return '00:00';
  }
}

export default AcceptPhotoButton;
