/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useRef, useEffect } from 'react';

export function BackgroundMusicService(props: any) {
  const { item, } = props;
  const audioRef = useRef(null);


  const url = item.url;
  useEffect(() => {
    if (!audioRef) return;
    if (!url) return;

    const audioElement = (audioRef.current as any);
    // alert(`${JSON.stringify(audioElement)}------------`)
    // if (!audioElement.src) {
    //   audioElement.load();
    //   audioElement.src = url;
    // } else {
    //   if (audioElement.src.indexOf('player.') > -1) {
    //     audioElement.load();
    //     audioElement.src = url;
    //     return;
    //   }

    //   if (audioElement.src !== url) {
    //     audioElement.load();
    //     audioElement.src = url;
    //   }
    // }

    if (item.shouldPlay) {
      audioElement.load();
      audioElement.src = url;
      audioElement.muted = false;
      audioElement.hidden = false;
      audioElement.play();
    } else {
      audioElement.muted = true;
      audioElement.hidden = true;
      audioElement.pause();
    }
  }, [item]);
  // alert(`${url}======url`)
  if (!url) return <></>;

  return (
    <audio
      src={url}
      ref={audioRef}
      loop={true}
      hidden
      muted
    />
  );
}

export default BackgroundMusicService;
