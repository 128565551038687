/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 */
import { useState, useEffect } from 'react';
import { GroupNewContent, SimpleContent, GridCheckboxContent } from './content/index';
import { SelectedVideoTab } from './data/dataVideo';
import { resetPanoVideoConfigWithSelectedTab } from '../panoramic/data';
import { delay } from '../../utils/functional';
import { createStrategy, remoteResourcePredicate, } from './strategy/panoVideo/index';
import Empty from '../empty/Empty';

export function ContentAdapterVideo(props: any) {
  const { tipControl, selectedTab, dataTree: originDataTree, onItemClick } = props;
  const [dataTree, setDataTree] = useState(originDataTree);
  // const [hasResetPanoConfig, changeResetPanoConfigFlag] = useState(false);
  // data 可能是对象, 可能是数组
  const data = dataTree[selectedTab];
  const strategy = createStrategy(selectedTab);

  useEffect(() => {
    if (!remoteResourcePredicate.includes(selectedTab)) {
      const { visualAngleObj, filterObjList } = resetPanoVideoConfigWithSelectedTab(dataTree, [], selectedTab);
      setDataTree({
        ...dataTree,
        [SelectedVideoTab.速剪]: visualAngleObj,
        [SelectedVideoTab.滤镜]: filterObjList,
        // [SelectedVideoTab.动效]: dynamicObjList,
      });
      return;
    } 
    // 获取远程素材数据
    strategy.fetchData({ tipControl }).then(async (newData: any) => {
      if (!newData) return;
      const { target, visualAngleObj, filterObjList, dynamicObjList } = resetPanoVideoConfigWithSelectedTab(dataTree, newData, selectedTab);
      console.log(target, 'target-----')
      setDataTree({
        ...dataTree,
        [selectedTab]: target,
        [SelectedVideoTab.速剪]: visualAngleObj,
        [SelectedVideoTab.滤镜]: filterObjList,
        [SelectedVideoTab.动效]: dynamicObjList,
      });
      // if (!hasResetPanoConfig) {
      //   changeResetPanoConfigFlag(true);
      //   tipControl.dismissLoading();
      // }
    });
  }, [selectedTab]);

  async function handleGroupTitleClick(tag: SelectedVideoTab, group: any) {
    if (remoteResourcePredicate.includes(selectedTab)) {
      // 组装数据, 更新 dataTree
      const newList = await strategy.fetchDataWithGroup({ list: dataTree[selectedTab], group, tipControl, });
      if (!newList) return;

      updateData(newList);
    } else {
      props.onGroupTitleClick && props.onGroupTitleClick(tag, group);
    }
  }
  function updateData(list: any) {
    setDataTree({ ...dataTree, [selectedTab]: list });
  }
  console.log(dataTree, 'dataTree------')
  switch (selectedTab) {
    case SelectedVideoTab.配乐:
      return (
        <GroupNewContent
          dataList={data}
          selectedTab={selectedTab}
          updateData={updateData}
          onGroupTitleClick={handleGroupTitleClick}
          onItemClick={onItemClick}
        />
      );
    case SelectedVideoTab.动效:
      return (
        <GridCheckboxContent
          dataList={data}
          selectedTab={selectedTab}
          groupFlag={data.length === 1 && data[0].title === '未分组'}
          updateData={updateData}
          onGroupTitleClick={handleGroupTitleClick}
          onItemClick={onItemClick}
          selects={true}
        />
      );
    case SelectedVideoTab.速剪:
    case SelectedVideoTab.模板:
    case SelectedVideoTab.滤镜:
    default:
      return (
        <GroupNewContent
          dataList={data}
          selectedTab={selectedTab}
          groupFlag={data.length === 1 && data[0].title === '未分组'}
          updateData={updateData}
          onGroupTitleClick={handleGroupTitleClick}
          onItemClick={onItemClick}
        />
      );
  }
}

export default ContentAdapterVideo;
